.contacts-page {

  margin-bottom: 2rem;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen {
      @media (max-width: 1024px) {
        flex-wrap: wrap;
      }
    }
  }

  .feedback-col {
    width: calc(25% - 20px);

    @media screen {
      @media (max-width: 1250px) {
        width: calc(35% - 20px);
      }

      @media (max-width: 1024px) {
        width: 100%;
        order: 2;

        margin-top: 1rem;
      }
    }

    .form-container {
      width: 100%;
      background: #ffffff;
      padding: 20px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

      @media screen {

        @media (max-width: 480px) {
          padding: 20px 10px;
        }
      }

      .modal__form {
        @media screen {
          @media (max-width: 1024px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          @media (max-width: 768px) {
            margin-top: 25px;
          }
        }
      }

      .on.select__gap{
        &:after{
            top: calc(50% - 4px);
          }
      }

      .select__gap{
        font-size: 15px;
        line-height: 23px;
        @media (max-width: 1400px){
          font-size: 14px;
        }
        &:after{
          top: calc(50% - 8px);
        }
        @media (max-width: 900px){
            padding: 5px 10px;
          }
      }

      .select__item {
        font-size: 15px;
        @media (max-width: 1400px){
          font-size: 14px;
        }
        span{
          padding: 10px 22px;
          line-height: 23px;
          @media (max-width: 900px){
            padding: 5px 10px;
          }
        }
      }


      input {

        @media screen {
          @media (max-width: 1024px) {
            width: 32%;

            margin-top: 0;

            display: flex;
            align-items: stretch;
          }

          @media (max-width: 900px) {
            padding: 5px 10px;
          }

          @media (max-width: 680px) {
            width: 49%;
            &[type="tel"]{
              margin-top: 10px;
            }
          }

          @media (max-width: 480px) {
            width: 100%;

            margin-top: 1rem;
          }

        }
      }

      .modal__terms {
        @media screen {
          @media (max-width: 1024px) {
            order: 5;
          }
        }

        label {
          @media (max-width: 480px) {
            line-height: normal;
          }
        }
      }

      textarea {
        resize: none;
        font-size: 14px;
        @media screen {
          @media (max-width: 1024px) {
            margin-bottom: 10px;
          }
          @media (max-width: 900px) {
            padding: 5px 10px;
          }
          @media (max-width: 680px) {
            margin-bottom: 0;
          }
        }
      }

      @media screen {
        @media (max-width: 1000px) {
          line-height: normal;
        }

        @media (max-width: 600px) {
          font: {
            size: rem(12px);
          }

          line-height: rem(20);
        }
      }

      .btn {
        all: unset;
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        margin-top: 23px;
        padding: 5px 8px;
        text-align: center;

        @media screen {
          @media (max-width: 1024px) {
            width: 32%;

            margin-top: 0;
          }

          @media (max-width: 680px) {
            width: 49%;

            margin-top: 1rem;
          }

          @media (max-width: 480px) {
            width: 100%;
          }

        }

        span {
          @media screen {
            @media (max-width: 900px) {
              font-size: rem(12);
              letter-spacing: 1px;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .socials {
    padding: 40px;

    .modal__header {
      margin-bottom: 5.8rem;
      width: 100%;
    }

    .icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    a {
      width: 48px;
      height: 48px;
      border: 2px solid #AAAAC1;
      box-sizing: border-box;
      @include transition();
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;

      &:hover {
        border-color: $link-color--hover;

        path {
          fill: $link-color--hover;
        }
      }

      &:active {
        border-color: $link-color--active;

        path {
          fill: $link-color--active;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      path {
        @include transition();
      }
    }
  }

}

.contacts-col {
  width: 50%;

  @media screen {
    @media (max-width: 1250px) {
      width: 65%;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 7rem;
    padding-top: 5rem;

    @media screen {
      @media (max-width: 1250px) {
        padding-top: 0;
      }

      @media (max-width: 680px) {
        flex-direction: column;

        align-items: center;

        margin-bottom: 3rem;
      }
    }

    .phones {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      //width: 265px;
      // margin-right: 5.2rem;

      @media (max-width: 680px) {
        margin-top: 2rem;
      }

      .icon {
        width: 22px;
        height: 22px;
        @include sprite($icon-phone);
        margin-right: 20px;
        margin-top: 10px;

        @media screen {
          @media (max-width: 1440px) {
            margin-right: 10px;
          }
        }
      }

      .text {
        a {
          display: block;
          color: $dark-gray;
          font-size: 1.8rem;
          font-weight: 800;
          margin-bottom: 3px;
          letter-spacing: 0.1em;
          @include transition();

          &:hover {
            color: $link-color--hover;
            text-decoration: none;
          }

          &:active {
            color: $link-color--active;
          }

          &:last-child {
            margin-bottom: 0;
          }

          @media screen {
            @media (max-width: 1800px) {
              font-size: 1.4rem;
            }

            @media (max-width: 680px) {
              font-size: 1.8rem;

              line-height: normal;
            }
          }
        }
      }
    }

    .email {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      //margin-right: 11rem;

      @media (max-width: 680px) {
        margin-top: 2rem;
      }

      .icon {
        width: 24px;
        height: 24px;
        margin-right: 20px;
        margin-top: 10px;
        @include sprite($icon-mail);

        @media screen {
          @media (max-width: 1440px) {
            margin-right: 10px;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        padding-top: 4px;


        .email-link {
          font-size: 1.2rem;
          letter-spacing: 0.2em;
          font-weight: 800;
          color: $gray;
          text-decoration: none;
          margin-bottom: 10px;
          @include transition();

          &:hover {
            color: $link-color--hover;
          }

          &:active {
            color: $link-color--active;
          }

          @media screen {
            @media (max-width: 680px) {
              font-size: 1.8rem;
            }
          }
        }

        .btn {
          height: 24px;
          line-height: 22px;
          padding: 0 20px;
          @include transition();

          &:hover {
            color: $link-color--hover;
            border-color: $link-color--hover;
          }

          &:active {
            color: $link-color--active;
            border-color: $link-color--active;
          }

          @media screen {

            @media (max-width: 900px) {
              height: auto;
              padding: 5px 10px;
            }
            @media (max-width: 680px) {
              font-size: 1.8rem;
              line-height: normal;

              height: auto;
            }
          }
        }
      }
    }

    .address {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 4px;

      @media (max-width: 680px) {
        margin-top: 2rem;
        padding-top: 0;
      }

      .icon {
        width: 18px;
        height: 24px;
        @include sprite($icon-adress);
        margin-right: 20px;

        @media screen {
          @media (max-width: 1440px) {
            margin-right: 10px;
          }
        }
      }

      address {
        font-size: 1.5rem;
        color: $dark-gray;

        @media screen {
          @media (max-width: 680px) {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  .contacts-map {
    width: 100%;
    height: 520px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

    @media screen {
      @media (max-width: 768px) {
        height: 420px;
      }

      @media (max-width: 480px) {
        height: 300px;
      }
    }
  }
}

.img-col {
  width: calc(25% - 20px);
  min-height: 680px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  display: flex;

  background-image: url("../images/contacts-img.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  @media screen {
    @media (max-width: 1250px) {
      display: none;
    }
  }
}
