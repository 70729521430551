.title-page {
  width: 100%;
  background-color: #fff;
  min-height: 145px;
  margin-top: -60px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  padding: 10px;

  @media(max-width: 1440px){
    width: 100%;
  }

  @media (max-width: 768px) {
    min-height: 100px;
    margin-bottom: 35px;

  }


  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    //height: 100%;
    @media(max-width: 1440px){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .back-page {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    padding-left: 45px;

    @media(max-width: 1440px){
      position: static;
      transform: translateY(0);
    }

    a {
      position: relative;
      font: {
        weight: $font-weight--400;
        size: rem(13px);
      }
      color: $gray;
      @include transition();

      &:hover {
        text-decoration: none;
        color: darken($gray, 10%);

        &:before {
          opacity: 1;
        }
      }

      &:before {
        position: absolute;
        content: '';
        @include sprite($vector);
        left: -45px;
        top: 50%;
        transform: translateY(-50%);
        opacity: .5;
        @include transition();
      }
    }
  }

  &-item {
    padding: 0 120px;
    @media(max-width: 1440px){
      padding: 0 15px;
      margin-top: 1rem;
    }
    @media (max-width: 600px) {
      margin-top: 2rem;
    }
  }

  &-item,
  &-item h1 {
    font: {
      size: rem(48px);
      weight: $font-weight--200;
    }
    color: $base-color;
    text-align: center;

    @media (max-width: 1440px) {
      width: 100%;
    }

    @media (max-width: 1100px) {
      font-size: rem(39px);
    }

    @media (max-width: 768px) {
      font-size: rem(30px);
    }

    @media (max-width: 600px) {
      font-size: rem(25px);
    }
  }
}

.article {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  &-page {
    flex-basis: 74%;
    padding: 60px 55px 54px 85px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    text-align: left;
    margin-bottom: 60px;
    font: {
      weight: $font-weight--400;
      size: rem(15px);
    }
    color: $dark-gray;

    @media (max-width: 1100px) {
      flex-basis: 100%;
      width: 100%;

      padding: 30px 25px 25px 40px;
    }

    @media (max-width: 768px) {
      padding: 20px
    }

    p {
      margin-bottom: 25px;
    }

    ul {
      list-style: initial;
    }

    ol {
      list-style: decimal;
    }

    dl {
      list-style: inherit;

      dd {
        padding-left: 10px;
      }
    }

    ol, ul, dl {
      padding-left: 20px;
      margin-bottom: 20px;
    }

    li, dl {
      margin-bottom: 10px;
      font: {
        size: rem(15px);
        weight: $font-weight--300;
      }
      line-height: normal;
      color: $dark-gray;
    }


    &-title {
      margin-bottom: 25px;
      font: {
        weight: $font-weight--800;
        size: rem(18px);
      }
      letter-spacing: .1em;
      line-height: normal;
      color: $dark-gray;
    }

    &-cover {
      width: 44%;
      float: right;
      margin-left: 8.9%;
      margin-bottom: 10px;

      @media (max-width: 600px) {
        width: 80%;
        margin: 0 auto 10px;

        float: unset;
        //max-width: unset;
      }

      @media (max-width: 480px) {
        width: 100%;

        //max-width: unset;
      }

      p {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        display: block;
      }

      figcaption {
        font: {
          weight: $font-weight--300;
          size: rem(13px);
        }
        line-height: normal;
        color: $gray;

        @media (max-width: 600px) {
          text-align: center;
        }
      }
    }

    &-slider {
      width: 51%;
      display: block;
      margin-left: 0;
      margin-right: auto;
      max-width: 647px;
      position: relative;
      margin-bottom: 30px;

      @media (max-width: 1100px) {
        width: 100%;
        //height: auto;
        margin: 0 auto 30px;
        //max-width: unset;
      }

      .big-slider {
        width: 100%;

        & > .slick-arrow {
          position: absolute;
          width: 42px;
          height: 42px;
          top: 50%;
          background: #fff;
          border: none;
          overflow: hidden;
          text-indent: -999999px;
          transform: translateY(-50%);
          z-index: 99;

          @media (max-width: 500px) {
            background: rgba(255, 255, 255, 0.5);

            width: 25px;
            height: 25px;
          }

          &:after {
            position: absolute;
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            top: 50%;
            left: 50%;
            border-top: 1px solid #303239;
            border-right: 1px solid #303239;
          }

          &.slick-prev {
            left: 20px;

            &:after {
              transform: translate(-50%, -50%) rotate(-135deg);
            }
          }

          &.slick-next {
            right: 20px;

            &:after {
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }
        }

        &-item {


          @media (max-width: 500px) {
            img {
              width: 100%;
            }
          }

          &:after {
            position: absolute;
            z-index: 10;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(360deg, #303239 0%, rgba(20, 20, 22, 0) 19.37%),
          }

        }

      }

      .thimbl-slider {
        position: absolute;
        display: block;
        width: 80%;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;

        @media (max-width: 768px) {
          display: none;
        }

        .slick-list.draggable {
          padding: 5px 0 0 0;
        }

        & > .slick-arrow {
          position: absolute;
          width: 21px;
          height: 18px;
          top: 50%;
          background: none;
          border: none;
          overflow: hidden;
          text-indent: -999999px;
          transform: translateY(-50%);
          z-index: 99;

          &.slick-prev {
            left: -50px;

            &:after {
              position: absolute;
              content: '';
              display: block;
              @include sprite($slick-prev-white);
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;

            }
          }

          &.slick-next {
            right: -50px;

            &:after {
              position: absolute;
              content: '';
              display: block;
              @include sprite($slick-next-white);
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          }

        }

        .slick-current {
          img {
            outline: 3px solid #fff;
          }
        }

        &-item {
          width: 120px;
          padding: 0 10px;

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }

    &-video {
      position: relative;
      display: block;
      width: 51%;
      padding-top: 40px;

      @media (max-width: 1100px) {
        width: 100%;
        margin: 0 auto;
        //max-width: unset;
      }

      .video {
        width: 100%;
        padding-bottom: 56%;
        position: relative;

        iframe {
          display: none;
          position: absolute;
          // display: block;
          width: 100%;
          height: 100%;
        }

        &-cover {
          // display: none;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-size: cover !important;

          &-play {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 48px;
            height: 48px;
            display: block;
            background-color: rgba(255, 255, 255, .9);

            &:after {
              content: '';
              position: absolute;
              width: 24px;
              height: 16px;
              margin: auto;
              margin-left: 20px;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: {
                right: 12px solid transparent;
                top: 8px solid transparent;
                bottom: 8px solid transparent;
                left: 12px solid $base-color;
              }
              box-sizing: border-box;
            }

            &:before {
              position: absolute;
              content: '';
              width: 58px;
              height: 58px;
              margin: auto;
              margin-left: -4px;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 1px solid #AAAAC1;
              box-sizing: border-box;
              @include transition();
            }

            &:hover {
              text-decoration: none;

              &:before {
                transform: scale(2);
                opacity: 0;
              }
            }
          }
        }
      }

      h4 {
        padding-top: 20px;
        font: {
          weight: $font-weight--300;
          size: rem(13px);
        }
        color: $gray;
      }
    }

  }

  &-sidebar {
    flex-basis: 24%;

    @media (max-width: 1100px) {
      flex-basis: 100%;
    }

    .title {
      position: relative;
      padding-left: 40px;

      font {
        width: $font-weight--800;
        size: rem(12px);
      }

      letter-spacing: .2em;
      text-transform: uppercase;
      color: $dark-gray;
      margin-bottom: 57px;

      &:after {
        position: absolute;
        content: '';
        width: 65px;
        height: 1px;
        background: #141416;
        left: 40px;
        bottom: -16px;


        @media (max-width: 600px) {

          left: 0;
        }
      }

      &:hover {
        text-decoration: none;
      }

      @media (max-width: 1100px) {

        width: 100%;
      }

      @media (max-width: 600px) {

        padding-left: 0;
      }
    }


    .share-block {


      &-links {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 40px;
        margin-bottom: 60px;

        @media (max-width: 600px) {

          padding-left: 0;
        }

        li {
          margin-right: 20px;
          width: auto;
          display: inline-block;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .6;
            @include transition();
            width: 48px;
            height: 48px;
            border: 2px solid #AAAAC1;
            box-sizing: border-box;

            &:hover {
              opacity: 1;
            }
          }

          .icon {
            @include transition();
            display: block;

            &-vk {
              @include sprite($icon-vk);
            }

            &-fb {
              @include sprite($icon-fb);
            }

            &-ok {
              @include sprite($icon-ok);
            }
          }
        }
      }

      .ya-share2{
        &__list{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &__item_service{
          &_vkontakte{
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .6;
            @include transition();
            width: 48px;
            height: 48px;
            border: 2px solid #AAAAC1;
            box-sizing: border-box;
            a{
              border: 0;
              &::after{
                content: '';
                @include sprite($icon-vk);
              }
            }

            &:hover {
              opacity: 1;
            }
            .ya-share2__badge{
              display: none;
            }
          }
          &_facebook{
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .6;
            @include transition();
            width: 48px;
            height: 48px;
            border: 2px solid #AAAAC1;
            box-sizing: border-box;

            a{
              border: 0;
              &::after{
                content: '';
                @include sprite($icon-fb);
              }
            }

            &:hover {
              opacity: 1;
            }
            .ya-share2__badge{
              display: none;
            }
          }
          &_odnoklassniki{
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: .6;
            @include transition();
            width: 48px;
            height: 48px;
            border: 2px solid #AAAAC1;
            box-sizing: border-box;

            a{
              border: 0;
              &::after{
                content: '';
                @include sprite($icon-ok);
              }
            }

            &:hover {
              opacity: 1;
            }
            .ya-share2__badge{
              display: none;
            }
          }
        }
      }

    }

    .last-news {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      /*.news-slider {
        @media (max-width: 1100px) {
          width: 100%;
          display: block;
          margin: 0 auto;
          //max-width: 647px;
          position: relative;
          margin-bottom: 30px;
        }
      }*/


      @media (max-width: 1100px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        width: 100%;
      }

      &-item {

        box-sizing: border-box;
        background-color: #fff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        width: 100%;
        @include transition();
        margin-bottom: 20px;

        display: block;


        @media (max-width: 1100px) {
          width: 32%;
        }

        @media (max-width: 768px) {
          width: 49%;
        }

        @media (max-width: 500px) {
          width: 80%;
          margin: 0 auto 20px;
        }

        @media (max-width: 400px) {
          width: 100%;
        }


        &:hover {
          text-decoration: none;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        }

        h3 {
          padding: 40px 44px;
          font: {
            weight: $font-weight--800;
            size: rem(18px);
          }
          letter-spacing: .1rem;
          color: $dark-gray;
          line-height: 21px;
          //max-height: 82px;
          overflow: hidden;
          text-overflow: ellipsis;
          box-sizing: border-box;
        }

        &--img {
          display: block;
          width: 100%;
          height: auto;

          img {
            display: block;
            width: 100%;
          }
        }
      }
    }


  }

}
