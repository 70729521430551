.wrapper.cafe-page {
  @media (max-width: 400px) {
    padding: 0;
  }
}

.cafe-page {
  height: auto;
  position: relative;

  .about {
    margin-top: 0;

    @media (max-width: 600px) {
      margin-bottom: 20px;
    }


    &-info {

      padding-bottom: 20px;

      @media (max-width: 1250px) {
        width: 65%;
      }

      @media (max-width: 1024px) {
        flex-direction: column;
        padding-left: 0;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      &-col {

        @media (max-width: 1024px) {
          width: 100%;
        }

        &:first-child {
          @media (max-width: 1250px) {
            width: 36%;
          }

          @media (max-width: 1024px) {
            width: 100%;
            padding: 0 5%;
          }

          .about-info-img {
            @media (max-width: 1024px) {
              display: none;
            }
          }
        }

        &:not(:first-child) {

          padding-bottom: 40px;

          @media (max-width: 1700px) {
            padding: 20px 5% 40px;
          }

          @media (max-width: 1024px) {
            padding: 5px 5% 40px;
          }
        }

        .header-block {
          @media (max-width: 1700px) {
            font-size: 3.8rem;
          }

          @media (max-width: 1024px) {
            margin-bottom: 0;
          }

          @media (max-width: 768px) {
            text-align: center;
            font-size: 3.2rem;
          }
        }
      }
    }

    &-img {
      @media (max-width: 1250px) {
        width: 35%;
      }

      @media (max-width: 991px) {
        display: block;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .body-bg.two {
    display: block;
    position: absolute;
    right: 0;
    top: 25%;
    @media (max-width: 1600px) {
      opacity: .7;
    }
    @media (max-width: 1240px) {
      display: none;
    }
  }

}

.cafe {
  width: 100%;
  padding-bottom: 38%;
  min-height: 300px;
  position: relative;
  margin-bottom: 22px;
  margin-top: -40px;
  background: {
    size: cover;
    position: center;
    repeat: no-repeat;
  }


  @media (max-width: 1600px) {
    padding-bottom: 42%;
  }

  @media (max-width: 1440px) {
    padding-bottom: 37%;
  }

  @media (max-width: 1250px) {

    min-height: 500px;
  }

  @media (max-width: 1024px) {
    min-height: 550px;
  }

  /* @media (max-width: 950px) {
     padding-bottom: 55%;
   }*/

  @media (max-width: 768px) {
    min-height: 500px;
  }

  @media (max-width: 480px) {
    min-height: 500px;
  }

  @media (max-width: 380px) {
    padding-bottom: 115%;
  }


  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(10, 10, 11, 0.4) 0%, rgba(38, 41, 56, 0.85) 100%),
  }

  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    /*@media (max-width: 1650px) {
      padding-top: 9%;
    }

    @media (max-width: 1440px) {
      padding-top: 4%;
    }

    @media (max-width: 1200px) {
      padding-top: 2%;
    }


    @media (max-width: 1024px) {
      padding-top: 5.2%;
    }

    @media (max-width: 950px) {
      padding-top: 4.2%;
    }*/
  }

  &-logo {
    height: 104px;
    width: auto;
    margin-bottom: 3rem;

    @media (max-width: 1200px) {
      margin-bottom: 1.5rem;
    }

    @media (max-width: 400px) {
      height: 85px
    }

    img {
      display: block;
      height: 100%;
    }
  }

  &-schedule {
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;


    margin-bottom: 2rem;

    font-size: rem(18px);


    span {
      margin-bottom: 0.3rem;
    }

    &__title {
      font-size: rem(20px);
      font-weight: bold;
    }

    @media (max-width: 1440px) {
      font-size: rem(16px);

      margin-bottom: 1.5rem;
    }
  }

  &-btn {
    margin-bottom: 1.5%;

    @media (max-width: 400px) {
      margin-bottom: 4%;
    }

    .btn {
      border-color: #fff;


      &:hover {
        box-shadow: 0 0 5px rgba(255, 255, 255, .7), 0 0 5px rgba(255, 255, 255, .7) inset;
      }
    }
  }

  &-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3%;

    @media (max-width: 1440px) {
      margin-bottom: 2%;
    }

    @media (max-width: 400px) {
      margin-bottom: 5%;
    }

    .social-btn {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 7px;
      @include transition();

      @media (max-width: 480px) {
        width: 30px;
        height: 30px;
      }

      img {
        display: block;
        max-width: 100%;
        //max-height: 100%;
      }

      &:hover {
        box-shadow: 0 0 5px 1px rgba(255, 255, 255, .5);
      }
    }
  }

  &-contacts {

    display: flex;
    align-items: center;
    padding: 0 10px;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    a, &-phones {
      position: relative;
      display: inline-block;
      margin: 0 50px 0 50px;
      font: {
        size: 1.5rem;
        weight: $font-weight--400;
      }
      line-height: normal;
      color: #fff;

      @media (max-width: 1100px) {
        margin: 0 10px 5px;
      }

      @media (max-width: 1000px) {
        margin: 0 0 10px;
      }

      @media (max-width: 400px) {
        font-size: 1.3rem;
      }

      &:before {
        position: relative;
        display: inline-block;
        content: '';
        margin-right: 10px;
        vertical-align: middle;
      }

      &.phone {
        &:before {
          @include sprite($icon-phone)
        }
      }

      &.mail {
        &:before {
          @include sprite($icon-mail)
        }
      }

      &.address {
        &:before {
          @include sprite($icon-adress)
        }
      }

    }

    &-phones {
      display: inline-flex;

      flex-direction: column;

      margin: 0 !important;
    }

  }

}

.cafe-nav {
  border: {
    top: 1px solid rgba(170, 170, 193, 0.3);
    bottom: 1px solid rgba(170, 170, 193, 0.3);
  }
  margin-bottom: 20px;
  //.burger{
  //  display: none;
  //  position: absolute;
  //  right: 20px;
  //  top: 50px;
  //  cursor: pointer;
  //  z-index: 1000;
  //  height: 30px;
  //  span{
  //    display: block;
  //    width: 30px;
  //    height: 3px;
  //    background-color: $base-color;
  //    @include transition();
  //    &:after,
  //    &:before{
  //      position: absolute;
  //      content: '';
  //      width: 30px;
  //      height: 3px;
  //      background-color: $base-color;
  //      @include transition();
  //    }
  //    &:after{
  //      margin-top: 8px;
  //    }
  //    &:before{
  //      margin-top: -8px;
  //    }
  //  }
  //  &-active{
  //    span{
  //      background-color: #fff;
  //      &:after{
  //        transform: rotate(-43deg);
  //        margin-top: 0;
  //      }
  //      &:before{
  //        transform: rotate(43deg);
  //        margin-top: 0;
  //      }
  //    }
  //  }
  //  //адаптив
  //  @media screen{
  //    @media (max-width: 991px){
  //      display: block;
  //    }
  //  }
  //}

  .main-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 380px) {
      flex-direction: column;
    }

    //адаптив
    //@media (max-width: 991px) {
    //  position: absolute;
    //  z-index: -1;
    //  display: block;
    //  max-width: 280px;
    //  height: auto;
    //  right: 20px;
    //  transform: translateY(-99999999px);
    //  opacity: 0;
    //  @include transition();
    //  border: 1px solid rgba(170, 170, 193, 0.3);
    //  border-top: none;
    //  background-color: #fff;
    //  &--active{
    //    opacity: 1;
    //    transform: translateY(0);
    //    z-index: 1001;
    //  }
    //}
    &--item {


      @media (max-width: 380px) {
        width: 100%;
      }

      a {
        display: block;
        position: relative;
        min-height: 54px;
        padding: 0 45px;
        line-height: 54px;
        font: {
          weight: $font-weight--800;
          size: rem(12px);
        }
        letter-spacing: .2rem;
        text-transform: uppercase;
        color: $dark-gray;
        @include transition();
        @media (max-width: 1440px) {
          padding: 0 15px;
          line-height: 40px;
          min-height: auto;
        }

        @media (max-width: 560px) {
          letter-spacing: normal;
        }

        @media (max-width: 480px) {
          padding: 0 7px;
        }

        @media (max-width: 380px) {
          padding: 0 20px;
        }


        &:after {
          content: '/';
          position: absolute;
          color: rgba(170, 170, 193, 0.3);
          right: 0;
          font: {
            size: rem(18px);
            weight: $font-weight--300;
          }
          @media (max-width: 991px) {
            display: none;
          }
        }

        &.active {
          color: #fff;
          background-color: $base-color;

          &:after {
            display: none;
          }
        }

        &:hover {
          text-decoration: none;
          color: #fff;
          background-color: lighten($base-color, 50%);

          &:after {
            display: none;
          }
        }
      }

    }
  }
}

.menu {
  width: 100%;
  margin-bottom: 60px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  overflow: hidden;

  .header-block {
    text-align: center;
    margin-bottom: 20px;

    width: 100%;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 3.2rem;
    }
  }

  &-tabs {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 20px 20px;
    border-bottom: 1px solid rgba(170, 170, 193, 0.3);
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.2s;

    &.fixed {
      position: fixed;
      top: 54px;
      left: 0;
      right: 0;
      z-index: 1000;
      background-color: #fff;
      border-top: 1px solid rgba(170, 170, 193, 0.3);
      @media screen {
        @media (max-width: 1440px) {
          top: 40px;
        }
      }
    }
    &.hidden{
      opacity: 0;
      pointer-events: none;
    }



    li {
      position: relative;
      max-width: 300px;
      cursor: pointer;
      height: 40px;
      padding: 0 18px;
      margin: 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font: {
        weight: $font-weight--800;
        size: 1rem;
      }
      line-height: normal;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      @include transition();

      a {
        text-decoration: none;
        color: #303239;
        @include transition();
      }

      &:after {
        content: '/';
        position: absolute;
        color: rgba(170, 170, 193, 0.3);
        right: 0;
        font: {
          size: rem(18px);
          weight: $font-weight--300;
        }
        @media (max-width: 991px) {
          display: none;
        }
      }

      &.active {
        background: #303239;
        color: #fff;

        a {
          color: #fff;
        }

        &:after {
          display: none;
        }
      }

      &:hover {
        text-decoration: none;
        color: #fff;
        background-color: lighten($base-color, 50%);

        a {
          color: #ffffff;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &-container {
    width: 100%;
    background-color: #fff;

    .tab-drawer-heading {
      display: none;

      padding: 10px;

      transition: all ease-in .3s;

      font-weight: $font-weight--800;
      color: $dark-gray;

      border-bottom: 1px solid $dark-gray;

      position: relative;

      background-color: white;


      &:before {
        content: "";
        display: inline-block;
        position: absolute;

        height: 9px;
        width: 9px;

        border-left: 2px solid $dark-gray;
        border-bottom: 2px solid $dark-gray;

        right: 20px;
        top: calc(50% - 3px);

        transform: rotate(-45deg);

        transition: all ease-in .3s;
      }


      @media (max-width: 1200px) {
        display: block;

        cursor: pointer;
      }
    }

    .d-active {
      background-color: $dark-gray;

      color: #fff;

      transition: all ease-in .3s;

      position: relative;

      &:before {
        content: "";
        display: inline-block;
        position: absolute;

        height: 9px;
        width: 9px;

        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;

        right: 20px;
        top: calc(50% - 3px);

        transform: rotate(135deg);

        transition: all ease-in .3s;
      }
    }

    .tab-content {
      padding: 36px 40px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      opacity: 1;
      /*   max-height: 100%;
         height: auto;*/
      //transition: all 3s ease-out;

      visibility: visible;

      &--hidden {
        padding: 0 !important;
        margin: 0 !important;
        height: 0;
        // max-height: 0;
        //width: 0;
        visibility: hidden;

        //transition: all ease-in 3s;

      }

      @media (max-width: 1800px) {
        padding: 36px 10px 60px;
      }

      @media (max-width: 1200px) {
        padding: 0px 10px;
      }

      @media (max-width: 768px) {
        padding-bottom: 20px;
      }

      &--menu {
        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 55px;
        flex-wrap: wrap;

        &-title {
          margin-left: 10px;
          margin-bottom: 0;
          margin-top: 10px;
          display: block;
          font: {
            weight: $font-weight--800;
            size: 1.8rem
          }

          width: 100%;

          line-height: normal;
          letter-spacing: 0.1em;

          color: #474C66;
        }

        &_col {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: flex-start;
          width: 100%;


          flex-wrap: wrap;


          text-align: left;

          // margin: 0 -10px;

          span {
            margin-left: 10px;
            margin-bottom: 0;
            margin-top: 10px;
            display: block;
            font: {
              weight: $font-weight--800;
              size: 1.8rem
            }

            width: 100%;

            line-height: normal;
            letter-spacing: 0.1em;

            color: #474C66;

            @media (max-width: 1440px) {
              margin-left: 20px;
            }
          }

          .item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: calc(100% / 4 - 20px);
            border-right: 1px solid rgba(170, 170, 193, .3);
            padding-right: 10px;
            margin: 10px;


            @media (max-width: 1440px) {
              width: calc((100% / 3) - 40px);
              margin: 0 20px 20px;
            }

            @media (max-width: 1200px) {
              border-right: none;
            }

            @media (max-width: 1024px) {
              width: calc((100% / 2) - 40px);
              margin: 0 20px 20px;
            }

            @media (max-width: 768px) {
              width: 100%;
            }

            &-info {
              width: 100%;

              &-name {
                font: {
                  weight: $font-weight--400;
                  size: 1.5rem;
                }
                line-height: 2.5rem;

                color: #474C66;
              }

              &-disc {
                font: {
                  weight: $font-weight--400;
                  size: 1.3rem;
                }
                line-height: 2rem;

                color: #747495;
              }

            }

            &-price {
              max-width: 205px;
              width: 100%;

              p {
                display: flex;
                justify-content: flex-end;
                font: {
                  weight: $font-weight--800;
                  size: 1.2rem;
                }
                letter-spacing: 0.2em;
                color: #474C66;
                line-height: 2.5rem;

                text-align: center;

                @media (max-width: 768px) {
                  letter-spacing: normal;
                }

                span {
                  width: 50%;
                  display: block;
                  white-space: nowrap;
                  font-size: 1.6rem;

                  &:not(.price) {
                    font-weight: $font-weight--500;
                    letter-spacing: 0;

                    @media (max-width: 768px) {
                      margin-right: 4px;
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.inst-slider-section {

  margin-bottom: 65px;

  .header-block {
    text-align: center;
    margin-bottom: 65px;
  }

  .sliders-container {


    .gallery-slider-container {

      @media (max-width: 1500px) {
        padding: 0 100px;
      }

      .prev {
        background-image: url(../images/slick-prev.png);
      }

      .next {
        background-image: url(../images/slick-next.png);
      }

      .gallery-slider {

        .slick-slide {
          height: 100%;
          max-height: 500px;

          & > div {
            height: 100%;
            text-align: center;
          }
        }

        .gallery-slider__item {
          height: 100%;
          border-radius: 0;
          @media (max-width: 1500px) {
            max-width: 350px;
          }
          @media (max-width: 480px) {
            max-width: 220px;
          }

          img {
            width: auto;
            height: 100%;
            max-height: 470px;
            min-height: 470px;
            @media (max-width: 1800px) {
              max-height: 435px;
              min-height: 435px;
            }
            @media (max-width: 1600px) {
              max-height: 400px;
              min-height: 400px;
            }
            @media (max-width: 1500px) {
              max-height: 350px;
              min-height: 350px;
            }
            @media (max-width: 640px) {
              max-height: 300px;
              min-height: 300px;
            }
            @media (max-width: 480px) {
              max-height: 220px;
              min-height: 220px;
            }
          }
        }

      }

    }

  }
}

.sliders-container {
  position: relative;
  width: 100%;
  max-width: 1830px;
  margin: auto;

  .gallery-slider-container {
    width: 100%;
    padding: 0 155px;
    position: relative;


    .prev, .next {
      position: absolute;
      width: 48px;
      height: 97px;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
      background: center center no-repeat #343745;
      border: 8px solid #303239;
      width: 60px;
      height: 60px;
    }

    .prev {
      background-image: url("../images/arrow-icon.png");
      pointer-events: auto;
      background-repeat: no-repeat;
      left: 0;
      z-index: 100;
      cursor: pointer;
    }

    .next {
      background-image: url("../images/arrow-icon.png");
      pointer-events: auto;
      background-repeat: no-repeat;
      right: 0;
      z-index: 100;
      cursor: pointer;
    }

    .gallery-slider {
      width: 100%;
      overflow: hidden;
      position: relative;

      &__item {
        /*  width: 70% !important;
          display: block !important;*/
        overflow: hidden;
        display: block;
        border-radius: 10px;
        margin: 0 auto;
        padding: 0 10px;

        /*   @media (max-width: 1440px) {
             max-height: 800px;
           }

           @media (max-width: 768px) {
             width: 100% !important;
           }*/

        img {
          display: block;
          width: 100%;
          height: auto;
          // max-height: 800px;
          margin: 0 auto;
        }

        iframe {
          width: 100%;
          height: 100%;
          z-index: 3;
        }
      }
    }
  }

  .gallery-slider-nav {
    margin-top: 30px;
    padding: 0 155px;
    overflow: hidden;

    @media (max-width: 900px) {
      display: none;
    }

    .slick-list {
      margin-right: -1.5rem;
    }

    &__item {
      border-radius: 10px;
      overflow: hidden;
      display: block;
      width: 100%;
      max-height: 100px;

      img, iframe {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .slick-slide {
      margin-right: 1.5rem;

      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }

      &.slick-current {
        position: relative;
        padding-bottom: 10px;

        &::after {
          content: '';
          width: 100%;
          height: 2px;
          background: $link-color;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .inst-slider-section .sliders-container {
    .gallery-slider-container {
      padding: 0 45px;

      .prev, .next {
        width: 40px;
        height: 40px;
        background-size: contain;
      }
    }

    .gallery-slider-nav {
      padding: 0 45px;
    }
  }
  //.tab-content {
  //  display: none;
  //}
}

@media (max-width: 820px) {
  .inst-slider-section .sliders-container {
    .gallery-slider-nav {
      .slick-list {
        margin-right: -10px;
      }

      .slick-slide {
        margin-right: 10px;

      }
    }
  }
}

@media (max-width: 640px) {
  .inst-slider-section .sliders-container {
    .gallery-slider-container {
      padding: 0 5px;

      .gallery-slider__item {
        padding-left: 0;
      }

      .prev {
        left: 15px;
        width: 30px;
        height: 30px;
      }

      .next {
        right: 15px;
        width: 30px;
        height: 30px;
      }
    }

    .gallery-slider-nav {
      padding: 0 5px;
    }
  }
}