.modal, .contacts-form {
  width: 100%;
  max-width: 455px;

  position: relative;

  margin: 0 auto;
  padding: 40px;

  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

  @media (max-width: 480px) {
    padding: 30px 15px;
  }

  &__header {

    position: relative;

    text-transform: uppercase;
    font-weight: $font-weight--800;
    color: $dark-gray;
    letter-spacing: .2em;
    font-size: rem(12px);

    &:before {

      content: '';

      position: absolute;

      bottom: -16px;
      left: 0;

      width: 65px;
      height: 1px;

      background: #141416;
    }
  }

  &__form {
    margin-top: 57px;

    input, textarea {

      padding: 15px 21px;
      margin-top: 10px;

      width: 100%;

      &::placeholder {
        color: $dark-gray;
        font-family: $font-family;
        font-size: rem(15px);
      }

      @media (max-width: 480px) {
        padding: 10px 15px;
      }

      @media (max-width: 380px) {
        padding: 4px 15px;
        margin-top: 7px;
      }
    }

    input:first-child {
      margin-top: 0;
    }


  }

  &__terms {

    position: relative;

    margin-top: 13px;

    display: flex;


    .modal-label {


      span, {
        position: relative;
        padding-left: 35px;


        color: #4a4a79;
        font-size: rem(11px);

        cursor: pointer;
        display: block;

        @media (max-width: 768px) {
          font-size: rem(12px);
        }

        @media (max-width: 380px) {
          font-size: rem(11px);
        }

        &:before {
          content: "";
          display: inline-block;

          height: 24px;
          width: 24px;

          top: -3px;
          left: 0;

          //border: 2px solid black;
          background-color: #b9bed0;
          border-radius: 50%;

        }

        &:after {
          content: "";
          display: inline-block;

          height: 6px;
          width: 9px;

          border-left: 2px solid #f7f7ff;
          border-bottom: 2px solid #f7f7ff;

          left: 6px;
          top: 3px;
          transform: rotate(-45deg);

          /*@media (max-width: 450px) {
            top: calc(50% - 7px);
            left: 7px;
          }*/
        }

        &:before, &:after {
          position: absolute;
        }

        a {
          @include transition();
          display: inline;
          color: #4a4a79;
          font-size: rem(11px);
          text-decoration: underline;
        }

      }




    }

    .modal-checkbox {
      opacity: 0;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 22px;
      height: 22px;

      & + span:after {
        content: none;
      }

      &:checked + span:after {
        content: '';
      }

      &:checked + span:before {
        //background-color: black;
      }

    }
  }


  .btn {
    all: unset;

    position: relative;
    box-sizing: border-box;

    width: 100%;

    margin-top: 23px;
    padding: 5px 8px;

    text-align: center;

    /*@media (max-width: 480px) {
      padding: 0
    }*/

    span {
      font-family: $font-family;
      font-weight: $font-weight--800;
      text-transform: uppercase;
      letter-spacing: .2em;
    }
  }

  .mfp-close {
    background-color: black;
    opacity: 1;

    font-family: $font-family;
    font-size: rem(28px);
    color: #fff !important;
    font-weight: $font-weight--200;

    /* &:hover {
       background-color: white;
       border: 2px solid black;

       color: black!important;

       transition: all 0.3s ease-in;
     }*/
    &:active {
      top: 0;
    }
  }

}

.map-modal {
  min-height: 500px;

  width: 70%;

  margin: 0 auto;

  @media screen {
    @media (max-width: 1280px) {
      width: 90%;

      min-height: 60vh;
    }

    @media (max-width: 768px) {
      width: 95%;

      min-height: 40vh;
    }
  }

  .mfp-close {
    color: white !important;
    font-size: rem(36px);

    right: 10%;

    @media screen {
      @media (max-width: 1280px) {
        right: 1%;
      }

      @media (max-width: 768px) {
        right: 0;
        top: -15px
      }
    }
  }
}