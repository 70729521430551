.header {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  background-color: #fff;
  padding-bottom: 40px;

  .wrapper {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__delivery-btn {
    background: #F8CA3E !important;
    border-color: #F8CA3E !important;

    &:before {
      content: 'новинка';
      position: absolute;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -13px;
      right: -36px;
      font-size: 10px;
      letter-spacing: 1.8px;
      line-height: 12px;
      width: 73px;
      height: 22px;
      background-color: #FF4A4A;
      border-radius: 3px;
      transform: rotate(28.8deg);


      @keyframes pulse {
        0% {
          transform: scale(0.97) rotate(28.8deg);
          box-shadow: 0 0 0 0 rgba(#FF4A4A, 0.7);
        }

        70% {
          transform: scale(1) rotate(28.8deg);
          box-shadow: 0 0 0 10px rgba(#FF4A4A, 0);
        }

        100% {
          transform: scale(0.97) rotate(28.8deg);
          box-shadow: 0 0 0 0 rgba(#FF4A4A, 0);
        }
      }

      animation: pulse 3s infinite;

    }

  }

  &-top {
    display: flex;
    width: 100%;
    padding: 31px 0 35px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //адаптив
    @media (max-width: 1440px) {
      padding: 15px 0 17px;
    }
    @media (max-width: 991px) {
      justify-content: flex-start;
      padding-right: 30px;
    }

    &__buttons {
      margin: 10px 0;

      .btn {
        margin-right: 30px;
        @media(max-width: 1240px) {
          margin-right: 10px;
        }

        &:last-child {
          margin-right: 0;
        }


      }
    }


    &--left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      //flex-basis:40%;
      width: 40%;
      //адаптив
      @media (max-width: 991px) {
        order: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 16px;
      }
      @media(max-width: 380px) {
        padding: 0 8px;
      }

      .mail {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        box-sizing: border-box;
        font: {
          size: rem(15px);
          weight: $font-weight;
        }
        color: $dark-gray;
        //адаптив
        @media (max-width: 1440px) {
          margin-right: 22px;
        }
        @media (max-width: 1240px) {
          margin-right: 10px;
        }
        @media (max-width: 580px) {
          display: none;
        }

        &:hover {
          color: darken($dark-gray, 10%);
          text-decoration: none;
        }
      }
    }

    &--middle {
      //flex-basis:20%;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 991px) {
        justify-content: flex-start;
        width: 80px;
        min-width: 80px;
      }
      @media(max-width: 380px) {
        width: 60px;
        min-width: 60px;
      }

      .logo {
        display: inline-block;
        max-width: 136px;
        width: 100%;
        @include transition();

        .delivery-restaurant__logo {
          position: relative;
          max-width: 160px;
          top: unset;
          bottom: unset;
          transform: none;
        }

        &:hover {
          opacity: .7;
        }

        //адаптив
        @media (max-width: 1440px) {
          max-width: 100px;
        }
        @media (max-width: 991px) {
          width: 80px;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }

    &--right {
      //flex-basis: 40%;
      max-width: 40%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      //адаптив
      @media (max-width: 991px) {
        order: 2;
        width: 120px;
        right: 60px;
        position: absolute;
      }

      .lang {
        display: none;
        align-items: center;
        justify-content: flex-start;
        //padding-left: 80px;
        box-sizing: border-box;
        margin-right: 92px;
        //адаптив
        @media (max-width: 1440px) {
          margin-right: 40px;
        }
        @media (max-width: 1199px) {
          margin-right: 20px;
        }
        @media (max-width: 991px) {
          margin-right: 20px;
        }

        ul {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          //margin-right: 71px;

          li {
            margin-right: 20px;
            position: relative;

            a {
              font: {
                size: rem(12px);
                weight: $font-weight--400;
              }
              letter-spacing: .2rem;
              text-transform: uppercase;
              color: $gray;

              &.active {
                font-weight: $font-weight--800;
                color: $dark-gray;

                &:hover {
                  color: darken($dark-gray, 10%);
                }
              }

              &:hover {
                text-decoration: none;
                color: darken($gray, 10%);
              }
            }

            &:after {
              content: '/';
              position: absolute;
              margin-right: -13px;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              font: {
                size: rem(12px);
                weight: $font-weight--400;
              }
              color: $gray;

            }

            &:last-child {
              margin-right: 0;

              &:after {
                display: none;
              }
            }
          }
        }
      }

      .phone-list {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        width: 42%;

        //perspective: 500px;
        //адаптив
        @media (max-width: 991px) {
          display: none;
        }

        &--name {
          position: relative;
          width: 100%;
          // height: 42px;
          text-align: left;
          cursor: pointer;
          font: {
            size: rem(14px);
            weight: $font-weight--300;
          }
          letter-spacing: .2rem;
          line-height: 42px;
          color: #fff;
          background-color: $base-color;

          @media screen {
            @media (max-width: 1199px) {
              font-size: rem(12px);
            }
          }
          //адаптив
          /*  @media (max-width: 1440px) {
              width: 120px;
            }
            @media (max-width: 991px) {
              height: 32px;
              line-height: rem(32px);
            }*/

          p {
            padding: 0 1rem;
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.active {
            &::after {
              transform: translateY(-20%) rotate(225deg);
            }
          }

          &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-70%) rotate(45deg);
            display: block;
            width: 9px;
            height: 9px;
            transition: .2s;
            border: {
              right: 1px solid $gray;
              bottom: 1px solid $gray;
            }
            pointer-events: none;
          }

          ul {
            position: absolute;
            display: none;
            left: 0;
            right: 0;
            top: 100%;
            padding-top: 5px;
            background-color: #fff;
            z-index: 99;
            border: 1px solid $gray;
            box-sizing: border-box;

            opacity: 0;


            li {
              /*height: 42px;
              //адаптив
              @media (max-width: 991px) {
                height: 32px;
              }*/

              padding: 0.7rem 1rem;

              &:hover {
                text-decoration: none;
                background-color: lighten($base-color, 70%);

                padding-left: 1.6rem;

                transition: all 0.2s ease-out;
              }

              .select-name--list_item, a {
                display: block;
                width: 100%;
                height: 100%;
                ///padding: 0 15px;
                box-sizing: border-box;
                //white-space: nowrap;
                color: $base-color;
                //overflow: hidden;
                //text-overflow: ellipsis;

                line-height: normal;
                //адаптив
                /*@media (max-width: 1440px) {
                  padding: 0 7px;
                }*/
              }

              a {

                font-weight: bold;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }

          .select-name--list-animate {
            display: block;
            opacity: 1;
            /*transform: translate(0, 0);
            animation-name: phone-list-anim;
            animation-iteration-count: 1;
            animation-duration: .5s;
            //transform: rotateZ(0);*/


            transition: all 0.3s ease-out;

            li {
              transform-origin: top left;
              opacity: 0;
              border-bottom: 1px solid rgba(170, 170, 193, .3);


              &.active {
                animation: menu-anim;
                animation-duration: 400ms;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out;
              }


            }
          }

          @keyframes menu-anim {
            0% {
              opacity: 0;
              transform: rotateZ(90deg);
            }

            70% {
              transform: skewX(10deg);
            }

            100% {
              opacity: 1;
              transform: rotateZ(0deg);
            }

          }
        }

        & > .phone-list--result {
          width: 215px;
          height: 42px;
          display: inline-block;
          border: 1px solid $gray;
          box-sizing: border-box;
          text-align: center;
          font: {
            weight: $font-weight--300;
            size: rem(18px);
          }
          line-height: 42px;
          color: $dark-gray;
          //адаптив
          @media (max-width: 1440px) {
            width: 160px;
            font-size: rem(14px);
          }
          @media (max-width: 991px) {
            height: 32px;
            line-height: rem(32px);
          }

          &:hover {
            text-decoration: none;
            color: darken($dark-gray, 10%)
          }
        }
      }
    }
  }

  &-bottom {
    width: 100%;
    height: auto;
    border: {
      top: 1px solid rgba(170, 170, 193, 0.3);
      bottom: 1px solid rgba(170, 170, 193, 0.3);
    }
    //адаптив
    @media (max-width: 991px) {
      border-bottom: none;
      position: static !important;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      background-color: #fff;
    }


    .burger {
      display: none;
      position: absolute;
      right: 20px;
      top: 38px;
      cursor: pointer;
      z-index: 1000;
      height: 30px;
      @media(max-width: 380px) {
        top: 28px;
      }

      span {
        position: relative;
        top: 50%;
        display: block;
        width: 30px;
        height: 3px;
        background-color: $base-color;
        @include transition();
        transform: translateY(-50%);

        &:after,
        &:before {
          position: absolute;
          content: '';
          width: 30px;
          height: 3px;
          background-color: $base-color;
          @include transition();
        }

        &:after {
          margin-top: 8px;
        }

        &:before {
          margin-top: -8px;
        }
      }

      &-active {
        span {
          background-color: #fff;

          &:after {
            transform: rotate(-43deg);
            margin-top: 0;
          }

          &:before {
            transform: rotate(43deg);
            margin-top: 0;
          }
        }
      }

      //адаптив
      @media screen {
        @media (max-width: 991px) {
          display: block;
        }
      }
    }

    .main-menu {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      //адаптив
      @media (max-width: 991px) {
        position: absolute;
        z-index: -1;
        display: block;
        max-width: 280px;
        height: auto;
        right: 20px;
        transform: translateY(-99999999px);
        opacity: 0;
        @include transition();
        border: 1px solid rgba(170, 170, 193, 0.3);
        border-top: none;
        background-color: #fff;
        &--active {
          opacity: 1;
          transform: translateY(0);
          z-index: 1001;
        }
      }

      &--item {
        a {
          display: block;
          position: relative;
          min-height: 54px;
          padding: 0 45px;
          line-height: 54px;
          font: {
            weight: $font-weight--800;
            size: rem(12px);
          }
          letter-spacing: .2rem;
          text-transform: uppercase;
          color: $dark-gray;
          @include transition();
          @media (max-width: 1440px) {
            padding: 0 15px;
            line-height: 40px;
            min-height: auto;
          }

          &:after {
            content: '/';
            position: absolute;
            color: rgba(170, 170, 193, 0.3);
            right: 0;
            font: {
              size: rem(18px);
              weight: $font-weight--300;
            }
            @media (max-width: 991px) {
              display: none;
            }
          }

          &.active {
            color: #fff;
            background-color: $base-color;

            &:after {
              display: none;
            }
          }

          &:hover {
            text-decoration: none;
            color: #fff;
            background-color: lighten($base-color, 50%);

            &:after {
              display: none;
            }
          }
        }

      }
    }
  }

  &__banquet-btn {
    @media(max-width: 580px) {
      display: none;
    }
  }


}