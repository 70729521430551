.events {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  &-page {
    display: flex;
    flex-basis: 74%;
    justify-content: space-between;
    flex-wrap: wrap;

    background: none;
    box-sizing: border-box;
    // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    text-align: left;
    margin-bottom: 60px;
    font: {
      weight: $font-weight--400;
      size: rem(15px);
    }
    color: $dark-gray;

    position: relative;

    @media (max-width: 1440px) {
      flex-basis: 80%;
    }

    @media (max-width: 1200px) {
      flex-basis: 100%;
    }

    @media (max-width: 990px) {
      justify-content: center;
      width: 100%;
    }


    &__item {

      width: 49%;
      max-height: 445px;
      //height: 100%;

      margin-bottom: 20px;

      display: inline-flex !important;
      align-items: stretch;
      justify-content: flex-start;

      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

      @media (max-width: 1200px) {
        height: auto;
      }

      @media (max-width: 990px) {
        width: 70%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      .img {

        width: 43%;
        position: relative;
        background-position: center center;
        background-size: cover;
        @media (max-width: 1440px) {
          width: 40%;
        }

        @media (max-width: 450px) {
          display: none;
        }

        &-logo {
          position: absolute;
          width: 30%;
          top: 45px;
          left: 30px;

          img {
            width: 100%;
            display: block;
          }
        }
      }

      .info {

        width: 57%;
        padding: 20px;
        background-color: #fff;

        @media (max-width: 1440px) {
          width: 60%;
        }

        @media (max-width: 450px) {
          width: 100%;
        }

        &-top-description {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__date {
            display: flex;
            align-items: center;

            .info-date-img {
              width: 13px;
              height: 10px;
              margin-right: 10px;

              img {
                width: 100%;
                height: auto;
                display: block;
              }
            }

          }

          &__section {

            .title {

              font-size: rem(10px);
              letter-spacing: .2em;
              color: $gray;
              text-transform: uppercase;

              position: relative;

              &:hover {
                text-decoration: none;
              }

              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $gray;
                bottom: -6px;
                left: 0;
                @include transition();
              }
            }
          }
        }

        &-body {

          width: 100%;
          margin-top: 20px;

          .title-link {
            display: block;
            color: $dark-gray;
          }

          h3 {

            font: {
              weight: $font-weight--800;
              size: rem(18px);
            }
            letter-spacing: .1rem;
            color: $dark-gray;
            line-height: 21px;
            max-height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px;
          }

          p {

            font: {
              weight: $font-weight--400;
              size: rem(15px);
            }

            text-overflow: ellipsis;
            @media (max-width: 1480px) {
              font-size: rem(14px);
            }
          }
        }

        &-more {

          display: flex;
          align-items: center;

          margin-top: 40px;

          color: $dark-gray;
          letter-spacing: .2em;
          text-transform: uppercase;
          font-size: rem(12px);
          font-weight: $font-weight--800;

          @media (max-width: 1440px) {
            margin-top: 10px;
            height: auto;
          }

          &:after {
            content: '';
            display: inline-block;
            vertical-align: middle;

            margin-left: 8px;

            width: 14px;
            height: 11px;

            background-image: url("../images/news-arrow.png");
            background-size: contain;
            background-repeat: no-repeat;

          }
        }

      }

    }

    &__navigation {

      width: 100%;
      display: flex;
      justify-content: center;

      margin-top: 40px;

      .dots {

        display: flex;
        flex-direction: row;

        li {

          a {

            display: flex;
            justify-content: center;
            align-items: center;

            color: $base-color;
            font-weight: $font-weight--800;

            width: 56px;
            height: 56px;
            transition: all 0.3s ease-in;

            border: 2px solid transparent;

            @media(max-width: 540px) {
              width: 30px;
              height: 30px;
            }

            &:hover {
              text-decoration: none;
              background-color: #aeb0ba;
            }
          }

          .active {
            background: $base-color;
            color: white;
          }

        }
      }
    }

  }

  &-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .6);
    display: none;

    &::after {
      content: '';
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../images/spinning-circles.svg);
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &-sidebar {
    flex-basis: 24%;

    margin-bottom: 40px;


    @media (max-width: 1440px) {
      flex-basis: 19%;
    }

    @media (max-width: 1200px) {
      flex-basis: 100%;
      width: 100%;
    }

    @media (max-width: 450px) {
      margin-bottom: 0;
    }


    .title {
      position: relative;

      font {
        width: $font-weight--800;
        size: rem(13px);
      }

      text-transform: uppercase;
      color: #747495;

      &:hover {
        text-decoration: none;
      }

    }

    .filter-news-block {

      padding-left: 40px;

      .title {
        width: 100%;

        @media (max-width: 580px) {
          text-align: center;
        }
      }


      @media (max-width: 1200px) {
        padding-left: 0;
      }

      @media (max-width: 580px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      &__links {

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        margin-top: 24px;

        @media (max-width: 1200px) {
          flex-direction: row;
          margin-top: 10px;
        }

        @media (max-width: 768px) {
          flex-wrap: wrap;
        }

        @media (max-width: 500px) {
          width: 100%;
          justify-content: space-between;
        }

        li {

          width: 100%;
          display: flex;
          align-items: center;
          position: relative;

          margin-top: 20px;


          @media (max-width: 1200px) {
            width: auto;
            margin-right: 20px;
            margin-top: 0;
          }

          @media (max-width: 450px) {
            margin-right: 10px;
            &:first-child {
              margin-bottom: 10px;
            }
          }

          &:first-child {
            margin-top: 0;
          }

          .filter-label {
            position: relative;
            padding-left: 40px;

            line-height: 24px;
            letter-spacing: .2em;
            text-transform: uppercase;
            color: $dark-gray;
            font-weight: $font-weight--800;
            font-size: rem(12px);

            cursor: pointer;

            @media (max-width: 450px) {
              padding-left: 30px;
              font-size: rem(11px);
            }

            &:before {
              content: "";
              display: inline-block;

              height: 24px;
              width: 24px;

              top: -2px;
              left: 0;

              border: 2px solid black;
              border-radius: 50%;

              @media (max-width: 450px) {
                height: 20px;
                width: 20px;
              }

            }

            &:after {
              content: "";
              display: inline-block;

              height: 6px;
              width: 9px;

              border-left: 2px solid white;
              border-bottom: 2px solid white;

              left: 9px;
              top: calc(50% - 5px);

              transform: rotate(-45deg);

              @media (max-width: 450px) {
                top: calc(50% - 7px);
                left: 7px;
              }
            }

            &:before, &:after {
              position: absolute;
            }
          }

          .filter-checkbox {
            opacity: 0;

            position: absolute;
            z-index: 111;

            width: 30px;
            height: 30px;

            & + .filter-label:after {
              content: none;
            }

            &:checked + .filter-label:after {
              content: '';
            }

            &:checked + .filter-label:before {
              background-color: black;
            }

          }

        }
      }
    }

    .filter-restorans-block {

      padding: 40px;

      margin-top: 45px;

      background-color: white;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);

      &-control {
        width: 100%;
        margin-top: 20px;
        text-align: left;
        padding: 8px 24px 8px 8px;
        background: $dark-gray;
        border: 0;
        color: #ffffff;
        font-weight: 600;
        position: relative;
        display: none;
        @media(max-width: 820px) {
          display: block;
        }

        &.active {
          &::after {
            transform: translateY(-5px) rotate(225deg);
          }
        }

        &::after {
          content: '';
          width: 12px;
          height: 12px;
          border-bottom: 2px solid #ffffff;
          border-right: 2px solid #ffffff;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-10px) rotate(45deg);
          transition: .2s;
        }
      }

      .title {
        @media (max-width: 1200px) {
          display: none;
        }
      }

      @media (max-width: 1200px) {
        margin-top: 0;
        padding: 0;
        background: none;
        box-shadow: none;
      }

      &__links {

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: auto;
        margin-top: 20px;

        @media (max-width: 1200px) {
          flex-direction: row;
          margin-top: 0;
          flex-wrap: wrap;
          padding: 10px 0;
        }



        @media (max-width: 820px) {
          height: 0;
          overflow: hidden;
          transition: .4s;
        }

        li {

          width: auto;
          display: flex;
          align-items: center;
          position: relative;

          border-bottom: 1px solid $gray;

          &:last-child {
            border: none;
          }

          @media (max-width: 1200px) {
            width: auto;
            margin-right: 20px;

            &:last-child {
              border-bottom: 1px solid $gray;
            }
          }

          a {

            line-height: 24px;
            letter-spacing: .2em;
            text-transform: uppercase;
            color: $dark-gray;
            font-weight: $font-weight--800;
            font-size: rem(12px);

            width: 100%;
            height: 100%;

            padding: 15px 0;

            transition: all 0.2s ease-in;

            @media (max-width: 1200px) {
              padding: 10px 5px;
            }

            @media (max-width: 450px) {
              font-size: rem(11px);
            }

            &:hover {
              color: #fff;
              background-color: $base-color;
              text-decoration: none;
              padding-left: 20px;
              @media (max-width: 1200px) {
                padding: 10px 5px;
              }
            }

            &.active {
              color: #fff;
              background-color: $base-color;
              text-decoration: none;
              padding-left: 20px;
              @media (max-width: 1200px) {
                padding: 10px 5px;
              }
            }
          }
        }
      }
    }
  }
}
