.cookie-policy {
  padding: 41px 60px;
  display: none;
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border: 1px solid $gray;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1111;

  @media (max-width: 1280px) {
    padding: 20px 30px
  }

  @media (max-width: 768px) {
    padding: 15px 0;
  }

  noindex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }


  .cookies-desc {
    display: flex;

    flex-direction: column;
    justify-content: flex-start;

    width: 70%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .terms {
    padding-top: 25px;

    @media (max-width: 1280px) {
      padding-top: 15px;
    }

    @media (max-width: 768px) {
      padding-top: 5px;
    }

    @media (max-width: 480px) {
      padding: 3px 0;
    }
  }

  p {
    color: $dark-gray;
    font-size: rem(14px);
    font-family: $font-family;
    padding-bottom: 8px;

    a {
      text-transform: uppercase;
    }

    @media (max-width: 700px) {
      display: inline-block;
    }

    @media (max-width: 580px) {
      font-size: 12px;
    }

    @media (max-width: 480px) {
      padding-bottom: 0;
    }

  }

  a {
    font-family: $font-family;
    color: $dark-gray;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: .2em;

    font-size: rem(12px);

    text-transform: uppercase;

    @media (max-width: 480px) {
      font-size: rem(11px);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .btn {
    background-color: #fff;
    display: flex;
    justify-self: flex-end;
    padding: 0 75px;

    &:hover {
      background-color: $gray;
    }

    @media (max-width: 480px) {
      width: 100%;
      padding: 0;

      display: flex;
      justify-content: center;

    }
  }


  @media print {
    .cookie-policy {
      display: none !important
    }
  }

}
