.page-404 {
  background-image: url("../images/backgroung-404.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  height: auto;
  width: 100%;

  margin-top: -20px; //remove margin from header

  display: flex;
  justify-content: center;

  .content {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 30px;

    margin-top: 50px;
    margin-bottom: 150px;

    @media (max-width: 1280px) {
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      margin-bottom: 50px;
    }

    @media (max-width: 768px) {
      padding: 15px;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;
    padding-top: 120px;

    @media (max-width: 1280px) {
      transform: scale(0.9);
    }

    @media (max-width: 768px) {
      transform: scale(0.8);
      padding-top: 60px;
    }

    @media (max-width: 580px) {
      padding-top: 0;
    }

    img {
      width: 100%;
      height: 100%;

      display: block;
    }
  }

  .page-404-title {
    text-align: center;
    font: {
      size: rem(48px);
      weight: $font-weight--200;
    }
    color: $base-color;

    @media (max-width: 768px) {
      font-size: rem(36px);
    }

    @media (max-width: 480px) {
      font-size: rem(26px);
    }
  }

  .text-description {
    width: 60%;

    padding-top: 14px;

    display: flex;
    justify-content: center;

    @media (max-width: 1280px) {
      width: 70%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    p {
      font-family: $font-family;
      font-size: rem(15px);
      color: $dark-gray;
      text-align: center;
    }
  }
}