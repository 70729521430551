// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-adress-name: 'icon-adress';
$icon-adress-x: 64px;
$icon-adress-y: 25px;
$icon-adress-offset-x: -64px;
$icon-adress-offset-y: -25px;
$icon-adress-width: 18px;
$icon-adress-height: 24px;
$icon-adress-total-width: 104px;
$icon-adress-total-height: 86px;
$icon-adress-image: '../images/sprite.png';
$icon-adress: (64px, 25px, -64px, -25px, 18px, 24px, 104px, 86px, '../images/sprite.png', 'icon-adress', );
$icon-coffeestudio-name: 'icon-coffeestudio';
$icon-coffeestudio-x: 0px;
$icon-coffeestudio-y: 0px;
$icon-coffeestudio-offset-x: 0px;
$icon-coffeestudio-offset-y: 0px;
$icon-coffeestudio-width: 32px;
$icon-coffeestudio-height: 32px;
$icon-coffeestudio-total-width: 104px;
$icon-coffeestudio-total-height: 86px;
$icon-coffeestudio-image: '../images/sprite.png';
$icon-coffeestudio: (0px, 0px, 0px, 0px, 32px, 32px, 104px, 86px, '../images/sprite.png', 'icon-coffeestudio', );
$icon-fb-name: 'icon-fb';
$icon-fb-x: 91px;
$icon-fb-y: 0px;
$icon-fb-offset-x: -91px;
$icon-fb-offset-y: 0px;
$icon-fb-width: 13px;
$icon-fb-height: 24px;
$icon-fb-total-width: 104px;
$icon-fb-total-height: 86px;
$icon-fb-image: '../images/sprite.png';
$icon-fb: (91px, 0px, -91px, 0px, 13px, 24px, 104px, 86px, '../images/sprite.png', 'icon-fb', );
$icon-mail-name: 'icon-mail';
$icon-mail-x: 22px;
$icon-mail-y: 62px;
$icon-mail-offset-x: -22px;
$icon-mail-offset-y: -62px;
$icon-mail-width: 24px;
$icon-mail-height: 16px;
$icon-mail-total-width: 104px;
$icon-mail-total-height: 86px;
$icon-mail-image: '../images/sprite.png';
$icon-mail: (22px, 62px, -22px, -62px, 24px, 16px, 104px, 86px, '../images/sprite.png', 'icon-mail', );
$icon-ok-name: 'icon-ok';
$icon-ok-x: 0px;
$icon-ok-y: 62px;
$icon-ok-offset-x: 0px;
$icon-ok-offset-y: -62px;
$icon-ok-width: 17px;
$icon-ok-height: 24px;
$icon-ok-total-width: 104px;
$icon-ok-total-height: 86px;
$icon-ok-image: '../images/sprite.png';
$icon-ok: (0px, 62px, 0px, -62px, 17px, 24px, 104px, 86px, '../images/sprite.png', 'icon-ok', );
$icon-phone-name: 'icon-phone';
$icon-phone-x: 37px;
$icon-phone-y: 0px;
$icon-phone-offset-x: -37px;
$icon-phone-offset-y: 0px;
$icon-phone-width: 22px;
$icon-phone-height: 22px;
$icon-phone-total-width: 104px;
$icon-phone-total-height: 86px;
$icon-phone-image: '../images/sprite.png';
$icon-phone: (37px, 0px, -37px, 0px, 22px, 22px, 104px, 86px, '../images/sprite.png', 'icon-phone', );
$icon-vk-name: 'icon-vk';
$icon-vk-x: 51px;
$icon-vk-y: 62px;
$icon-vk-offset-x: -51px;
$icon-vk-offset-y: -62px;
$icon-vk-width: 24px;
$icon-vk-height: 14px;
$icon-vk-total-width: 104px;
$icon-vk-total-height: 86px;
$icon-vk-image: '../images/sprite.png';
$icon-vk: (51px, 62px, -51px, -62px, 24px, 14px, 104px, 86px, '../images/sprite.png', 'icon-vk', );
$slick-next-white-name: 'slick-next-white';
$slick-next-white-x: 0px;
$slick-next-white-y: 37px;
$slick-next-white-offset-x: 0px;
$slick-next-white-offset-y: -37px;
$slick-next-white-width: 22px;
$slick-next-white-height: 20px;
$slick-next-white-total-width: 104px;
$slick-next-white-total-height: 86px;
$slick-next-white-image: '../images/sprite.png';
$slick-next-white: (0px, 37px, 0px, -37px, 22px, 20px, 104px, 86px, '../images/sprite.png', 'slick-next-white', );
$slick-prev-white-name: 'slick-prev-white';
$slick-prev-white-x: 27px;
$slick-prev-white-y: 37px;
$slick-prev-white-offset-x: -27px;
$slick-prev-white-offset-y: -37px;
$slick-prev-white-width: 22px;
$slick-prev-white-height: 20px;
$slick-prev-white-total-width: 104px;
$slick-prev-white-total-height: 86px;
$slick-prev-white-image: '../images/sprite.png';
$slick-prev-white: (27px, 37px, -27px, -37px, 22px, 20px, 104px, 86px, '../images/sprite.png', 'slick-prev-white', );
$vector-name: 'vector';
$vector-x: 64px;
$vector-y: 0px;
$vector-offset-x: -64px;
$vector-offset-y: 0px;
$vector-width: 22px;
$vector-height: 20px;
$vector-total-width: 104px;
$vector-total-height: 86px;
$vector-image: '../images/sprite.png';
$vector: (64px, 0px, -64px, 0px, 22px, 20px, 104px, 86px, '../images/sprite.png', 'vector', );
$spritesheet-width: 104px;
$spritesheet-height: 86px;
$spritesheet-image: '../images/sprite.png';
$spritesheet-sprites: ($icon-adress, $icon-coffeestudio, $icon-fb, $icon-mail, $icon-ok, $icon-phone, $icon-vk, $slick-next-white, $slick-prev-white, $vector, );
$spritesheet: (104px, 86px, '../images/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
