.body-bg {
  display: none;
}

.index {
  height: auto;

  .body-bg {
    display: block;
    position: absolute;
    @media (max-width: 1600px) {
      opacity: .7;
    }
    @media (max-width: 1240px) {
      display: none;
    }

    &.one {
      left: 0;
      top: 29%;
    }

    &.two {
      right: 0;
      top: 65%;
    }

    img {
      max-width: 100%;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .content {
    flex: 1 0 auto;
    position: relative;
  }
}

.wrapper {
  margin: 0 auto;
  padding: map-get($wrapper, 'padding');
  max-width: map-get($wrapper, 'max-width');
  min-width: map-get($wrapper, 'min-width');
}

//заголовки
.header-block {
  font: {
    weight: $font-weight--100;
    size: rem(48px);
  }
  line-height: rem(58px);
  color: $base-color;
}

//кнопки
.btn {
  display: inline-block;
  width: auto;
  padding: 0 27px;
  box-sizing: border-box;
  background: $white-color;
  border: 2px solid #303239;
  height: 42px;
  font: {
    size: rem(12px);
    weight: $font-weight--800;
  }
  color: $dark-gray;
  text-align: center;
  letter-spacing: .2rem;
  line-height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  @include transition();

  &:hover {
    text-decoration: none;
    color: darken($dark-gray, 10%);
    opacity: .9;
  }

  @media (max-width: 1400px) {
    padding: 0 15px;
  }
  @media (max-width: 991px) {
    padding: 0 7px;
    height: 32px;
    line-height: 30px;
  }


  &--white {
    background-color: transparent;
    border-color: $white-color;
    color: $white-color;

    &:hover {
      text-decoration: none!important;
      color: $white-color!important;
      background-color: rgba(#000, 0.3);
    }
  }

  &--secondary {
    position: relative;
    color: $white-color;
    background: $base-color;

    &:hover, &:active {
      color: $white-color;
    }

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 1px solid $white-color;
    }
  }
}

.dark-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;

  span {
    display: block;
    padding: 0 30px;
    background-color: $base-color;
    text-transform: uppercase;
    color: #fff;
    line-height: rem(36px);
    font: {
      weight: $font-weight--800;
      size: rem(12px);
    }
    letter-spacing: .2rem;
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #AAAAC1;
    box-sizing: border-box;
    @include transition();
  }

  &:hover {
    text-decoration: none;

    &:before {
      opacity: 0;
      transform: scale(1.3);
    }
  }
}

input, textarea {
  border: 1px inset gray;
  font-size: 16px;
  font-weight: 400;
  font-family: Mplus1p, sans-serif;
  color: #303239;

  &::placeholder {
    opacity: 1;
  }
}

.select {
  display: block;
  width: 100%;
  margin-top: 10px;
  position: relative;

  select {
    display: block !important;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.select-wrap {
  width: 100%;
  //padding: 15px 21px;
  //border: 2px inset ThreeDLightShadow;
}

.select-wrap select {
  margin: 20px;
}

.select__gap {
  background: transparent;
  color: #303239;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  position: relative;
  padding: 15px 50px 15px 21px;
  border: 1px inset gray;
  font-weight: 400;
  font-family: Mplus1p, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//.on.select__gap,
//.select__gap:hover {
//  color: #303239;
//}

.select__list {
  position: absolute;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 11px 11px rgba(0, 0, 0, .1);
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: $dark-gray $border-color; /* scroll thumb and track */

  &::-webkit-scrollbar {
    width: 1px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: $border-color; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $dark-gray; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 1px solid $dark-gray; /* creates padding around scroll thumb */
  }
}

.select__list.on {
  display: block;
}

.select__item {
  &:first-child {
    display: none;
  }
}


.select__item span {
  @include transition();
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  color: #333;
  background: $white-color;
}

.select__item.selected span,
.select__item span:hover {
  background: $dark-gray;
    color: $white-color;
}


.select__gap:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 20px;
  top: calc(50% - 5px);
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  transform: rotate(133deg);
  transition: .3s ease-in;
}

.on.select__gap:after {
  transform: rotate(316deg);
  top: 50%;
}

.hidden-h1 {
  position: absolute;
  z-index: -100;
  opacity: 0;
  text-indent: -999px;
}