.footer {
  position: relative;
  flex: 0 0 auto;
  @media (max-width: 720px) {
    background-color: #fff;
  }

  &-questions {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 56px 9% 45px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @media (max-width: 1600px) {
      padding: 56px 4% 45px;
    }
    @media (max-width: 991px) {
      flex-direction: column;
      padding: 25px 10px 40px;
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(20, 20, 22, 0.672) 0%, rgba(20, 20, 22, 0.4) 100%),
      linear-gradient(180deg, rgba(20, 20, 22, 0.55) 0%, rgba(71, 76, 102, 0.35) 100%);
      z-index: 1;
    }

    &--info {
      position: relative;
      z-index: 2;
      @media (max-width: 991px) {
        margin-bottom: 30px;
      }

      h2 {
        font: {
          weight: $font-weight--100;
          size: rem(48px);
        }
        line-height: rem(52px);
        color: #fff;
        margin-bottom: 10px;
        @media (max-width: 991px) {
          font-size: rem(36px);
          line-height: rem(40px);
        }
      }

      p {
        font: {
          weight: $font-weight--100;

        }
        line-height: rem(21px);
        color: #fff;
        margin-bottom: 5px;
        font-size: rem(14px);

        span {
          font-weight: $font-weight--800;
          font-size: rem(18px);
          letter-spacing: .1rem;
        }
      }
    }

    &--form {
      position: relative;
      z-index: 2;
      width: 40%;
      max-width: 614px;
      @media (max-width: 991px) {
        width: 100%;
      }

      .modal__terms .modal-label span {
        z-index: 2;
        color: #dfdfe8;
        font-size: 1.4rem;

        a {
          color: #dfdfe8;
          font-size: 1.4rem;
        }
      }

      .subscribe {
        position: relative;
        width: 100%;
        display: block;

        .btn {
          position: absolute;
          right: 16px;
          top: 14px;
          height: 49px;
          line-height: 49px;
          background-color: #ffff;
          @media (max-width: 991px) {
            top: 0;
            right: 0;

            background-color: $dark-gray;
            color: white;

            border: 1px solid transparent;

            &:hover {
              background-color: white;
              color: $dark-gray;

              transition: all 0.3s ease-in;

              border: 1px solid $dark-gray;
            }
          }
        }

        input {
          width: 100%;
          display: inline-block;
          height: 78px;
          box-sizing: border-box;
          background-color: #fff;
          border: none;
          padding: 0 200px 0 20px;
          @media (max-width: 991px) {
            height: 49px;
            padding-right: 130px;
          }
        }
      }

      .errorlist {
        position: absolute;
        color: white;

        top: -30px;
        left: 0;

        font-size: rem(13px);

        &.nonfield {
          top: unset;
          bottom: -30px;
          left: 0;
        }
      }
    }

  }

  &-main {
    background-color: #fff;
    padding: {
      top: 80px;
      right: 120px;
      bottom: 60px;
      left: 60px;
    }
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 1600px) {
      padding: 80px 60px 60px;
    }
    @media (max-width: 720px) {
      padding: 20px 10px 30px;
      max-width: 320px;
      margin: auto;

      justify-content: center;
    }

    &--contacts {
      max-width: 320px;
      white-space: nowrap;
      @media (max-width: 1600px) {
        width: 220px;
      }
      @media(max-width: 820px) {
        white-space: normal;
      }
      @media (max-width: 720px) {
        order: 1;
        //  width: 100%;
        max-width: none;
        text-align: center;
        margin-bottom: 10px;

        flex-direction: column;
      }

      a {
        display: block;
        padding-left: 58px;
        font: {
          weight: $font-weight--400;
          size: rem(15px);
        }
        color: $dark-gray;
        margin-bottom: 30px;
        position: relative;
        @include transition();
        @media (max-width: 720px) {
          display: inline-block;
          margin-bottom: 10px;
          padding-left: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          @media (max-width: 720px) {
            left: -48px;
          }
          @media (max-width: 400px) {
            left: -38px;
          }
        }

        &.mail {
          &:after {
            @include sprite($icon-mail);
          }
        }

        &.adress {
          &:after {
            @include sprite($icon-adress);
          }
        }

        &:hover {
          text-decoration: none;
          color: darken($dark-gray, 20%);
        }
      }
    }

    &--menu {
      max-width: calc(100% - 700px);
      width: 100%;
      @media (max-width: 1600px) {
        max-width: calc(100% - 600px);
      }
      @media (max-width: 1440px) {
        max-width: calc(100% - 460px);
      }
      @media(max-width: 1200px) {
        display: none;
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          flex-basis: 50%;
          min-width: 210px;

          &:nth-child(even) {
            text-align: right;

            a {
              margin-right: 0;
              margin-left: auto;
              display: block;
            }
          }

          a {
            display: inline-block;
            max-width: 210px;
            width: auto;
            text-align: left;
            font: {
              weight: $font-weight--800;
              size: rem(12px);
            }
            text-transform: uppercase;
            color: $dark-gray;
            letter-spacing: .2rem;
            line-height: rem(36px);

            &:hover {
              text-decoration: none;
              color: lighten($dark-gray, 10%);
            }

          }

        }
      }

    }

    &--logo {
      position: absolute;
      top: 48px;
      left: 50%;
      transform: translateX(-50%);
      width: 255px;
      @media(max-width: 1440px) {
        width: 180px;
      }
      @media (max-width: 720px) {
        order: 0;
        position: static;
        width: 100%;
        margin-bottom: 10px;
        left: auto;
        right: auto;
        transform: none;
      }

      a {
        width: 136px;
        display: block;
        height: auto;
        @include transition();
        margin: {
          right: auto;
          left: auto;
          bottom: 37px;
        }
        @media(max-width: 1440px) {
          width: 100px;
          margin-bottom: 15px;
        }

        img {
          display: block;
          width: 100%;
        }

        &:hover {
          text-decoration: none;
          opacity: .7;
        }
      }

      p {
        text-align: center;
        font: {
          weight: $font-weight--400;
          size: rem(12px);
        }
        line-height: rem(16px);
        color: $gray;
      }
    }

    &--social {
      max-width: 320px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
      @media (max-width: 720px) {
        order: 2;
        width: 100%;
        max-width: none;
        align-items: center;
      }

      .phone {
        margin-bottom: 40px;
        position: relative;
        padding-left: 48px;
        @media (max-width: 720px) {
          padding-left: 0;
          margin-bottom: 10px;
        }

        &:before {
          position: absolute;
          content: '';
          top: 3px;
          left: 0;
          @include sprite($icon-phone);

          @media (max-width: 720px) {
            left: -48px;
          }

          @media (max-width: 400px) {
            left: -38px;
          }

        }

        a {
          display: block;
          font: {
            weight: $font-weight--400;
            size: rem(15px);
          }
          color: $dark-gray;
          @include transition();
          @media (max-width: 720px) {
            margin-bottom: 10px;
          }

          &:hover {
            text-decoration: none;
            color: darken($dark-gray, 20%);
          }
        }
      }

      .social-link {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        a {
          max-width: 24px;
          max-height: 24px;
          display: block;
          @include transition();
          margin: 5px;

          img {
            max-width: 100%;
            display: block;
          }

          &:hover {
            text-decoration: none;
            opacity: .8;
          }
        }
      }
    }
  }

  &-production {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: {
      top: 11px;
      right: 44px;
      left: 44px;
      bottom: 5px;
    };
    background-color: #E5E5E5;

    a {
      position: relative;
      display: block;
      max-width: 220px;
      font: {
        weight: $font-weight--400;
        size: rem(12px);
      }
      line-height: rem(18px);
      color: $gray;

      /*&:before {
        position: absolute;
        content: '';
        @include sprite($icon-coffeestudio);
        left: -50px;
        top: 0;
      }*/
    }
  }

  .modal__terms {

    /// padding-left: 20px;

    @media (max-width: 600px) {
      padding-left: 0;
    }


    .modal-label {

      color: #e3e3ea;

      &:before {
        background-color: #767d9c;
      }
    }
  }
}