.field {
  input, textarea {
    width: 100%;
    padding: 15px 21px;

    &::placeholder {
      color: $dark-gray;
      font-family: $font-family;
      font-size: rem(15px);
    }

    &:required {
      background-image: url(../images/required-icon.svg);
      background-position: calc(100% - 4px) 4px;
      background-repeat: no-repeat;
      background-size: 10px;
    }

    @media (max-width: 480px) {
      padding: 10px 15px;
    }

    @media (max-width: 380px) {
      padding: 4px 15px;
      margin-top: 7px;
    }
  }

  textarea {
    resize: none;
    height: 125px;
  }

  select {
    &:required {
      & ~ .select__gap {
        background-image: url(../images/required-icon.svg);
        background-position: calc(100% - 4px) 4px;
        background-repeat: no-repeat;
        background-size: 10px;
      }
    }
  }
}