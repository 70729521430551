.main {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  position: relative;
  margin-right: -20px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 110px);
  grid-auto-rows: 110px;
  grid-gap: 20px;
  max-width: 1880px;
  margin-top: -40px;

  &-item {
    display: flex;
    background-size: cover;
    background-position: center center;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    &:nth-child(8n + 1),
    &:nth-child(8n + 6) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto;
    }

    &:nth-child(8n + 2),
    &:nth-child(8n + 3) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto;
    }

    &:nth-child(8n + 4),
    &:nth-child(8n + 5) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto;
    }

    &:nth-child(8n + 7),
    &:nth-child(8n + 8) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto;
    }

    //@media (max-width: 1440px){
    //  &:nth-child(8n + 1),
    //  &:nth-child(8n + 4)
    //  {
    //    grid-column: span 2 / auto;
    //    grid-row: span 2 / auto;
    //  }
    //
    //  &:nth-child(8n + 2),
    //  &:nth-child(8n + 3)
    //  {
    //    grid-column: span 2 / auto;
    //    grid-row: span 3 / auto;
    //  }
    //
    //  &:nth-child(8n + 5),
    //  &:nth-child(8n + 6)
    //  {
    //    grid-column: span 2 / auto;
    //    grid-row: span 1 / auto;
    //  }
    //
    //  &:nth-child(8n + 7),
    //  &:nth-child(8n + 8)
    //  {
    //    grid-column: span 3 / auto;
    //    grid-row: span 2 / auto;
    //  }
    //}
    //@media (max-width: 991px){
    //  &:nth-child(8n + 1) {
    //    grid-column: span 3 / auto;
    //    grid-row: span 2 / auto;
    //  }
    //}

    //&.square{
    //  max-width: 24%;
    //  height: 460px;
    //  &-small{
    //    max-width: 24%;
    //    height:340px;
    //  }
    //}
    //&.rectangle{
    //  max-width: 36.5%;
    //  height:220px;
    //  &-small{
    //    max-width: 24%;
    //    height: 220px;
    //  }
    //}

    &--logo {
      display: flex;
      max-width: 290px;
      height: auto;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 10px;
      @include transition();
      opacity: 1;

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        height: auto;
      }
    }

    &--hover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(58, 60, 67, 0.7);
      mix-blend-mode: normal;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      @include transition();

      h3 {
        font: {
          size: rem(18px);
          weight: $font-weight--800;
        }
        letter-spacing: 0.1rem;
        line-height: rem(32px);
        color: #ffffff;

        @media (max-width: 768px) {
          font: {
            size: rem(14px);
          }
          line-height: rem(24px);
        }
      }

      p {
        font: {
          size: rem(14px);
          weight: $font-weight--300;
        }
        letter-spacing: 0.1rem;
        line-height: rem(32px);
        color: #ffffff;

        @media screen {
          @media (max-width: 1000px) {
            line-height: normal;
          }

          @media (max-width: 768px) {
            font: {
              size: rem(12px);
            }

            line-height: rem(20);
          }
        }
      }

      &__phones {
        display: flex;
        flex-direction: column;
      }
    }

    &:hover {
      text-decoration: none;

      .main-item--logo {
        opacity: 0;
      }

      .main-item--hover {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1440px) {
  .main {
    grid-gap: 15px;
    grid-template-rows: repeat(3, 110px);
    &-item {
      &:nth-child(8n + 1),
      &:nth-child(8n + 4) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }

      &:nth-child(8n + 2),
      &:nth-child(8n + 3) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }

      &:nth-child(8n + 5),
      &:nth-child(8n + 6) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }

      &:nth-child(8n + 7),
      &:nth-child(8n + 8) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }
    }
  }

}

@media (max-width: 991px) {
  .main {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 70px);
    grid-auto-rows: 70px;
    grid-gap: 10px;

    &-item {
      &:nth-child(8n + 1) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }

      &:nth-child(8n + 2),
      &:nth-child(8n + 4),
      &:nth-child(8n + 6),
      &:nth-child(8n + 7) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }

      &:nth-child(8n + 3),
      &:nth-child(8n + 5) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }

      &:nth-child(8n + 8) {
        grid-column: span 2 / auto;
        grid-row: span 3 / auto;
      }

      &--logo {
        max-width: 130px;
      }
    }
  }
}

@media (max-width: 691px) {
  .main {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 70px);
    grid-auto-rows: 70px;
    grid-gap: 5px;

    &-item {
      grid-column: span 2 / auto !important;
      grid-row: span 2 / auto !important;
    }
  }
}

@media (max-width: 540px) {
  .main {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 70px);
    grid-auto-rows: 70px;
    grid-gap: 5px;

    &-item {
      grid-column: span 2 / auto !important;
      grid-row: span 2 / auto !important;
    }
  }
}


.services {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  background: rgba(255, 255, 255, 1);
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 47%, rgba(237, 237, 237, 0.78) 100%);

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
  }

  &-info {
    width: 29.5%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: {
      top: 100px;
      left: 50px;
      bottom: 20px;
    }
    box-sizing: border-box;
    font: {
      weight: $font-weight--400;
      size: rem(15px);
    }
    line-height: rem(25px);
    color: $dark-gray;
    text-align: left;
    @media (max-width: 991px) {
      width: 100%;
      margin-bottom: 20px;
      padding-top: 0;
    }

    @media (max-width: 768px) {
      padding-left: 0;
    }

    .header-block {
      margin-bottom: 25px;

      @media (max-width: 768px) {
        text-align: center;
        width: 100%;
      }
    }

    .dark-btn {
      margin-top: 40px;
    }

  }

  &-list {
    width: 65%;
    column-count: 4;
    column-gap: 20px;
    @media (max-width: 1440px) {
      column-count: 3;
      column-gap: 15px;
    }
    @media (max-width: 991px) {
      width: 100%;
    }
    @media (max-width: 691px) {
      column-count: 2;
      column-gap: 10px;
    }
    @media (max-width: 480px) {
      column-count: initial;
      column-gap: initial;
      position: relative;
    }


    &--item {
      display: inline-block;
      margin-bottom: 20px;
      max-width: 297px;
      width: 100%;
      height: 297px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      position: relative;
      box-sizing: border-box;
      @include transition();

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 1440px) {
        margin-bottom: 7.5px;
        height: 250px;
      }
      @media (max-width: 691px) {
        margin-bottom: 5px;
        height: 200px;
        max-width: 340px;
        background-repeat: no-repeat;
        background-size: cover;
      }
      @media (max-width: 480px) {
        max-width: 440px;
        width: 100%;
        margin-top: 0 !important;
      }

      &:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(20, 20, 22, 0.472) 0%, rgba(20, 20, 22, 0) 100%), linear-gradient(180deg, rgba(20, 20, 22, 0.25) 0%, rgba(71, 76, 102, 0.25) 100%);
        @include transition();
      }

      span {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        text-align: center;
        width: 85%;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        padding: 16px 0;
        font: {
          weight: $font-weight--800;
          size: rem(12px);
        }
        letter-spacing: .2rem;
        text-transform: uppercase;
        color: #fff;
      }

      &:nth-child(1) {
        margin-top: 50px;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &:nth-child(5) {
        margin-top: 60px;
        @media (max-width: 1440px) {
          margin-top: 0;
        }
      }

      &:nth-child(6) {
        @media (max-width: 1440px) {
          margin-top: 20px;
        }
        @media (max-width: 691px) {
          margin-top: 0;
        }
      }

      &:nth-child(7) {
        margin-top: 20px;
        @media (max-width: 1440px) {
          margin-top: 0;
        }
      }

      &:hover {
        text-decoration: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &:after {
          opacity: .5;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      z-index: 99;
      top: 0;
      width: 60px;
      height: 60px;
      text-indent: -99999999999px;
      overflow: hidden;
      box-sizing: border-box;
      background: #343745;
      border: 8px solid #303239;
      background-position: center center;
      background-repeat: no-repeat;

      &.slick-prev {
        right: 60px;
        background-image: url(../images/slick-prev.png);
      }

      &.slick-next {
        right: 0;
        background-image: url(../images/slick-next.png);
      }
    }
  }

}

.products {
  width: 100%;
  margin-bottom: 20px;


  @media (max-width: 991px) {
    margin-bottom: 150px;
  }

  @media (max-width: 480px) {
    margin-bottom: 0;
  }


  .header-block {
    text-align: center;
    margin-bottom: 55px;

    &--btn{
      background: linear-gradient(180deg, rgba(236, 142, 28, 0.06) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      padding: 60px 55px 42px 55px;
      margin-top: 40px;
      @media (max-width: 1440px) {
        padding: 42px 55px 42px 55px;
      }
      @media (max-width: 991px) {
        padding: 30px 30px 30px;
        margin-top: 30px;
      }
      &__description{
        display: flex;
        justify-content: space-between;
        @media (max-width: 991px) {
            flex-direction: column;
        }
        .item{
          display: flex;
          margin-right: 30px;
          &:last-child{
            margin-right: 0;
          }
          @media (max-width: 1440px) {
            flex-direction: column;
          }
          @media (max-width: 991px) {
              margin-right: 0;
              margin-bottom: 20px;
              &:last-child{
                margin-bottom: 0;
              }
          }
          svg{
            min-width: 60px;
            margin-right: 30px;
            @media (max-width: 1440px) {
              align-self: center;
            }
            @media (max-width: 991px) {
              margin-right: 0;
            }
          }
          &-description{
            font-weight: $font-weight--500;
            font-size: rem(15px);
            line-height: rem(22px);
            color: #474C66;
            max-width: 400px;
            text-align: left;
            @media (max-width: 991px) {
              max-width: 100%;
              text-align: center;
            }
            &--strong{
              font-weight: $font-weight--700;
            }
            &--item {
              display: block;
              a {
                color: $dark-gray;
                &:hover {
                  color: $link-color;
                }
              }
            }
          }
        }
      }
      .dark-btn{
        margin-top: 30px;
        @media (max-width: 540px) {
          span{
            padding: 10px 30px;
            line-height: 2.5rem;
          }
        }
      }
    }
  }

  &-list {
    column-count: 4;
    column-gap: 20px;
    column-fill: balance;
    max-height: 760px; // fix me todo
    @media (max-width: 1240px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    @media (max-width: 480px) {
      all: unset;
    }

    &--item {
      position: relative;
      width: 100%;
      max-width: 445px;
      display: inline-block;
      height: 400px;
      margin-bottom: 20px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: linear-gradient(180deg, rgba(236, 142, 28, 0.06) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
      background-repeat: no-repeat;
      background-position: center top;
      @include transition();

      &:nth-child(8n + 2) {
        height: 300px;

        span {
          top: 230px;
        }

      }

      &:nth-child(8n + 3) {
        height: 300px;

        span {
          top: 230px;
        }
      }

      &:nth-child(8n + 6) {
        height: 300px;

        span {
          top: 230px;
        }
      }

      &:nth-child(8n + 7) {
        height: 300px;

        span {
          top: 230px;
        }
      }

      span {
        top: 330px;
        left: 40px;
        right: 40px;
        position: absolute;
        display: block;
        font: {
          weight: $font-weight--800;
          size: rem(12px);
        }
        line-height: rem(14px);
        letter-spacing: .2rem;
        text-transform: uppercase;
        color: $dark-gray;

        padding: 1rem;

        background-color: rgba(247, 252, 252, 0.9);

        text-align: center;

      }

      @media (max-width: 1240px) {
        height: 200px !important;
        width: 32%;
        position: relative;
        span {
          position: absolute;
          left: -1px;
          right: -1px;
          bottom: -1px;
          top: 0 !important;
          padding: 0px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background-color: rgba(255, 255, 255, .8);
          font-size: 1.8rem;
          line-height: 2.5rem;

        }
      }
      @media (max-width: 991px) {
        width: 49%;
      }
      @media (max-width: 480px) {
        width: 100%;
        height: 200px !important;
        span {
          position: absolute;
          left: -1px;
          right: -1px;
          bottom: -1px;
          top: 0 !important;
          padding: 0px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          background-color: rgba(255, 255, 255, .8);
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }

      &:hover {
        text-decoration: none;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
      }
    }

    &__desc {

    }

  }
}

.about {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  //background-color: $body-bg-color;
  position: relative;
  z-index: 99;
  margin-bottom: 75px;

  background: rgba(255, 255, 255, 1);
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 47%, rgba(237, 237, 237, 0.78) 100%);


  @media (min-width: 1241px) {
    //margin-top: -100px ;
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }

  &-img {
    width: 40%;
    @media (max-width: 991px) {
      display: none;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &-info {
    width: 60%;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 991px) {
      flex-wrap: wrap;
      width: 100%;
    }

    @media (max-width: 768px) {
      padding-left: 0;
    }

    &-col {
      width: 70%;

      max-height: 100%;

      @include transition();

      &:first-child {
        width: 30%;
        padding-top: 80px;
        @media (max-width: 1240px) {
          padding-top: 25px;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
      }

      &:not(:first-child) {
        padding: 20px 10% 0;
        font: {
          size: rem(15px);
          weight: $font-weight--400;
        }
        line-height: rem(21px);
        color: $base-color;

        p {
          margin-bottom: 40px;
        }

        @media (max-width: 1240px) {
          padding: 10px 5% 0;
          p {
            margin-bottom: 10px;
          }
        }
        @media (max-width: 991px) {
          padding-top: 40px;
        }
        @media (max-width: 600px) {
          padding-top: 0;
          width: 100%;
          padding: 0;
        }
      }

      .header-block {
        margin-bottom: 80px;
        @media (max-width: 1240px) {
          margin-bottom: 25px;
        }
        @media (max-width: 600px) {
          padding-top: 0;
          margin-bottom: 30px;
          text-align: center;
        }
      }

      .about-info-img {
        width: 100%;
        @media (max-width: 600px) {
          display: none;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

.news {


  .header-block {
    text-align: center;
    margin-bottom: 65px;
    @media(max-width: 600px){
      margin-bottom: 20px;
    }
  }

  &-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 1120px) {
      flex-wrap: wrap;
    }

    &-item {
      width: 49%;
      @media (max-width: 1120px) {
        width: 100%;
        margin-bottom: 20px;
      }

      .main-slider {
        width: 100%;

        margin-bottom: 50px;

        @media(max-width: 1120px){
          margin-bottom: 20px;
        }

        &--menu {
          width: 100%;

          .slick-track {
            display: flex;
            align-items: flex-end;

            @media (max-width: 600px) {
              align-items: flex-start;
            }

          }

          .slick-arrow {
            position: absolute;
            z-index: 99;
            top: 0;
            width: 60px;
            height: 60px;
            text-indent: -99999999999px;
            overflow: hidden;
            box-sizing: border-box;
            background: #343745;
            border: 8px solid #303239;
            background-position: center center;
            background-repeat: no-repeat;

            &.slick-prev {
              right: 60px;
              background-image: url(../images/slick-prev.png);
            }

            &.slick-next {
              right: 0;
              background-image: url(../images/slick-next.png);
            }
          }
        }

        &-item {
          width: 100%;
          display: inline-flex !important;
          align-items: stretch;
          justify-content: flex-start;
          max-height: 445px;
          height: 100%;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
          @media (max-width: 600px) {
            flex-direction: column;
            max-height: none;
            height: auto;

            margin-bottom: 2rem;
          }

          .img {
            width: 50%;
            position: relative;
            background-position: center center;
            -webkit-background-size: cover;
            background-size: cover;
            @media (max-width: 600px) {
              width: 100%;
              height: 200px;
            }


            &-logo {
              position: absolute;
              width: 30%;
              top: 45px;
              left: 30px;

              img {
                width: 100%;
                display: block;
              }
            }
          }

          .info {
            width: 50%;
            padding: 45px 40px;
            background-color: #fff;
            @media (max-width: 1640px) {
              padding: 35px 20px;
            }
            @media (max-width: 1480px) {
              padding-right: 10px;
            }
            @media (max-width: 600px) {
              width: 100%;
              padding: 15px 10px;
            }

            .title {
              display: inline-block;
              font: {
                weight: $font-weight--800;
                size: rem(12px);
              }
              letter-spacing: .2rem;
              text-transform: uppercase;
              line-height: rem(18px);
              color: $dark-gray;
              position: relative;
              margin-bottom: 71px;
              @media (max-width: 1480px) {
                margin-bottom: 25px;
              }
            }

            &-body {
              width: 100%;
              max-height: 185px;
              overflow: hidden;

              .title-link{
                color: $dark-gray;
              }

              h3 {
                font: {
                  weight: $font-weight--800;
                  size: rem(18px);
                }
                letter-spacing: .1rem;
                color: $dark-gray;
                line-height: 21px;
                max-height: 42px;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 10px;
              }

              p {
                font: {
                  weight: $font-weight--400;
                  size: rem(15px);
                }
                @media (max-width: 1480px) {
                  font-size: rem(14px);
                }
              }
            }
          }

          .dark-btn {
            margin-top: 24px;
          }
        }

        .slick-arrow {
          position: absolute;
          z-index: 99;
          top: 0;
          width: 60px;
          height: 60px;
          text-indent: -99999999999px;
          overflow: hidden;
          box-sizing: border-box;
          background: #343745;
          border: 8px solid #303239;
          background-position: center center;
          background-repeat: no-repeat;

          &.slick-prev {
            right: 60px;
            background-image: url(../images/slick-prev.png);
          }

          &.slick-next {
            right: 0;
            background-image: url(../images/slick-next.png);
          }
        }
      }
    }
  }

  &--menu {
    width: 100%;

    margin-bottom: 2rem;

    @media (max-width: 1200px) {
      margin-top: 1.5rem;
    }

    .news-list-item {
      width: 50%;

      margin: 0 auto;

      @media (max-width: 1200px) {
        width: 75%;
      }

      @media (max-width: 890px) {
        width: 90%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      &--menu {
        width: 100%;

        @media (max-width: 1200px) {
          width: 100%;
        }
      }
    }
  }
}

.map-btn {
  color: white;

  font-size: rem(14px);

  padding: 0.7rem 1.2rem;
}

.accordion-info-col {
  position: relative;

  @include transition();

  &__inner{
    overflow: hidden;
    height: 100%;
    ul {
      padding-left: 20px;
      list-style: disc;
      li {
        margin-bottom: 5px;
      }
    }
  }

  &_active {
    height: 498px;
    overflow: hidden;

    @include transition();

    &:before {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 100%;

      border-bottom: 2rem;

    }

    &:hover {
      &:before {
        //border-bottom: 3rem solid rgba(193, 193, 193, 0.97);

        @include transition();
      }
    }
  }

  &__more {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;

    bottom: 0;

    cursor: pointer;
    text-align: center;

    padding: 0;

    color: $base-color;
    font-weight: bold;

    cursor: pointer;

    //background-color: rgba(201, 201, 226, 0.93);

    &:hover {
      //@include transition();
      //color: $dark-gray;
      //background-color: rgba(201, 201, 226, 0.99);
    }
  }
}