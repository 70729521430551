@charset "UTF-8";
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

* {
  box-sizing: border-box; }

html {
  font-size: 10px;
  font-family: "M PLUS 1p", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  @media (max-width: 1400px) {
    html {
      font-size: 9px; } }

html,
body {
  height: 100%;
  position: relative; }

body {
  color: #303239;
  font-size: 16px;
  font-weight: 400;
  font-family: "M PLUS 1p", sans-serif;
  line-height: normal;
  background-color: #f7f8fc; }

a {
  color: #41afd3;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  -webkit-text-decoration-skip: objects; }
  a[href^="tel"] {
    color: inherit;
    text-decoration: none; }
    a[href^="tel"]:hover {
      text-decoration: underline; }
  a:hover {
    color: #2fa5cb;
    text-decoration: underline; }
  a:active {
    color: #2a94b7; }

ol,
ul {
  list-style: none; }

b,
strong {
  font-weight: 700; }

i,
em {
  font-style: italic; }

s,
strike {
  text-decoration: line-through; }

dfn {
  font-style: italic; }

hr {
  margin: 10px 0;
  padding: 0;
  height: 0;
  border-color: #aaaac1;
  border-style: solid;
  border-width: 1px 0 0 0;
  box-sizing: content-box; }

blockquote,
q {
  margin: 0;
  quotes: none; }
  blockquote:before, blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none; }

sub,
sup {
  font-size: 80%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

mark {
  color: #000;
  background: #ff0; }

svg:not(:root) {
  overflow: hidden; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  height: 0;
  display: none; }

img {
  border: 0; }

figure {
  margin: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

input,
textarea,
button,
select,
optgroup {
  margin: 0;
  padding: 0;
  outline: none;
  color: #303239;
  font-size: 16px;
  font-weight: 400;
  font-family: "M PLUS 1p", sans-serif;
  line-height: normal;
  border-radius: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

legend {
  border: 0;
  padding: 0; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  box-sizing: content-box;
  outline-offset: -2px;
  -webkit-appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.body-bg {
  display: none; }

.index {
  height: auto; }
  .index .body-bg {
    display: block;
    position: absolute; }
    @media (max-width: 1600px) {
      .index .body-bg {
        opacity: .7; } }
    @media (max-width: 1240px) {
      .index .body-bg {
        display: none; } }
    .index .body-bg.one {
      left: 0;
      top: 29%; }
    .index .body-bg.two {
      right: 0;
      top: 65%; }
    .index .body-bg img {
      max-width: 100%; }

.container {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .container > .content {
    flex: 1 0 auto;
    position: relative; }

.wrapper {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1920px;
  min-width: 280px; }

.header-block {
  font-weight: 100;
  font-size: 4.8rem;
  line-height: 5.8rem;
  color: #303239; }

.btn {
  display: inline-block;
  width: auto;
  padding: 0 27px;
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #303239;
  height: 42px;
  font-size: 1.2rem;
  font-weight: 800;
  color: #474C66;
  text-align: center;
  letter-spacing: .2rem;
  line-height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s; }
  .btn:hover {
    text-decoration: none;
    color: #323648;
    opacity: .9; }
  @media (max-width: 1400px) {
    .btn {
      padding: 0 15px; } }
  @media (max-width: 991px) {
    .btn {
      padding: 0 7px;
      height: 32px;
      line-height: 30px; } }
  .btn--white {
    background-color: transparent;
    border-color: #ffffff;
    color: #ffffff; }
    .btn--white:hover {
      text-decoration: none !important;
      color: #ffffff !important;
      background-color: rgba(0, 0, 0, 0.3); }
  .btn--secondary {
    position: relative;
    color: #ffffff;
    background: #303239; }
    .btn--secondary:hover, .btn--secondary:active {
      color: #ffffff; }
    .btn--secondary::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 1px solid #ffffff; }

.dark-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  padding: 5px;
  box-sizing: border-box; }
  .dark-btn span {
    display: block;
    padding: 0 30px;
    background-color: #303239;
    text-transform: uppercase;
    color: #fff;
    line-height: 3.6rem;
    font-weight: 800;
    font-size: 1.2rem;
    letter-spacing: .2rem; }
  .dark-btn:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #AAAAC1;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out 0s; }
  .dark-btn:hover {
    text-decoration: none; }
    .dark-btn:hover:before {
      opacity: 0;
      transform: scale(1.3); }

input, textarea {
  border: 1px inset gray;
  font-size: 16px;
  font-weight: 400;
  font-family: Mplus1p, sans-serif;
  color: #303239; }
  input::placeholder, textarea::placeholder {
    opacity: 1; }

.select {
  display: block;
  width: 100%;
  margin-top: 10px;
  position: relative; }
  .select select {
    display: block !important;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }

.select-wrap {
  width: 100%; }

.select-wrap select {
  margin: 20px; }

.select__gap {
  background: transparent;
  color: #303239;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  position: relative;
  padding: 15px 50px 15px 21px;
  border: 1px inset gray;
  font-weight: 400;
  font-family: Mplus1p, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.select__list {
  position: absolute;
  background: #fff;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0 11px 11px rgba(0, 0, 0, 0.1);
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #474C66 #E5E5EC;
  /* scroll thumb and track */ }
  .select__list::-webkit-scrollbar {
    width: 1px;
    /* width of the entire scrollbar */ }
  .select__list::-webkit-scrollbar-track {
    background: #E5E5EC;
    /* color of the tracking area */ }
  .select__list::-webkit-scrollbar-thumb {
    background-color: #474C66;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 1px solid #474C66;
    /* creates padding around scroll thumb */ }

.select__list.on {
  display: block; }

.select__item:first-child {
  display: none; }

.select__item span {
  transition: all 0.2s ease-in-out 0s;
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  color: #333;
  background: #ffffff; }

.select__item.selected span,
.select__item span:hover {
  background: #474C66;
  color: #ffffff; }

.select__gap:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 20px;
  top: calc(50% - 5px);
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  transform: rotate(133deg);
  transition: .3s ease-in; }

.on.select__gap:after {
  transform: rotate(316deg);
  top: 50%; }

.hidden-h1 {
  position: absolute;
  z-index: -100;
  opacity: 0;
  text-indent: -999px; }

@font-face {
  font-family: 'M PLUS 1p';
  src: local("Mplus 1p Thin"), local("Mplus1p-Thin"), url("../fonts/Mplus1pthin.woff2") format("woff2"), url("../fonts/Mplus1pthin.woff") format("woff"), url("../fonts/Mplus1pthin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'M PLUS 1p';
  src: local("Mplus 1p Light"), local("Mplus1p-Light"), url("../fonts/Mplus1plight.woff2") format("woff2"), url("../fonts/Mplus1plight.woff") format("woff"), url("../fonts/Mplus1plight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'M PLUS 1p';
  src: local("Mplus 1p"), local("Mplus1p-Regular"), url("../fonts/Mplus1p.woff2") format("woff2"), url("../fonts/Mplus1p.woff") format("woff"), url("../fonts/Mplus1p.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'M PLUS 1p';
  src: local("Mplus 1p Medium"), local("Mplus1p-Medium"), url("../fonts/Mplus1pmedium.woff2") format("woff2"), url("../fonts/Mplus1pmedium.woff") format("woff"), url("../fonts/Mplus1pmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'M PLUS 1p';
  src: local("Mplus 1p Bold"), local("Mplus1p-Bold"), url("../fonts/Mplus1pbold.woff2") format("woff2"), url("../fonts/Mplus1pbold.woff") format("woff"), url("../fonts/Mplus1pbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'M PLUS 1p';
  src: local("Mplus 1p ExtraBold"), local("Mplus1p-ExtraBold"), url("../fonts/Mplus1pextrabold.woff2") format("woff2"), url("../fonts/Mplus1pextrabold.woff") format("woff"), url("../fonts/Mplus1pextrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'M PLUS 1p';
  src: local("Mplus 1p Black"), local("Mplus1p-Black"), url("../fonts/Mplus1pblack.woff2") format("woff2"), url("../fonts/Mplus1pblack.woff") format("woff"), url("../fonts/Mplus1pblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

.header {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  background-color: #fff;
  padding-bottom: 40px; }
  .header .wrapper {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; }
  .header__delivery-btn {
    background: #F8CA3E !important;
    border-color: #F8CA3E !important; }
    .header__delivery-btn:before {
      content: 'новинка';
      position: absolute;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -13px;
      right: -36px;
      font-size: 10px;
      letter-spacing: 1.8px;
      line-height: 12px;
      width: 73px;
      height: 22px;
      background-color: #FF4A4A;
      border-radius: 3px;
      transform: rotate(28.8deg);
      animation: pulse 3s infinite; }

@keyframes pulse {
  0% {
    transform: scale(0.97) rotate(28.8deg);
    box-shadow: 0 0 0 0 rgba(255, 74, 74, 0.7); }
  70% {
    transform: scale(1) rotate(28.8deg);
    box-shadow: 0 0 0 10px rgba(255, 74, 74, 0); }
  100% {
    transform: scale(0.97) rotate(28.8deg);
    box-shadow: 0 0 0 0 rgba(255, 74, 74, 0); } }
  .header-top {
    display: flex;
    width: 100%;
    padding: 31px 0 35px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1440px) {
      .header-top {
        padding: 15px 0 17px; } }
    @media (max-width: 991px) {
      .header-top {
        justify-content: flex-start;
        padding-right: 30px; } }
    .header-top__buttons {
      margin: 10px 0; }
      .header-top__buttons .btn {
        margin-right: 30px; }
        @media (max-width: 1240px) {
          .header-top__buttons .btn {
            margin-right: 10px; } }
        .header-top__buttons .btn:last-child {
          margin-right: 0; }
    .header-top--left {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 40%; }
      @media (max-width: 991px) {
        .header-top--left {
          order: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0 16px; } }
      @media (max-width: 380px) {
        .header-top--left {
          padding: 0 8px; } }
      .header-top--left .mail {
        position: relative;
        display: inline-block;
        margin-right: 30px;
        box-sizing: border-box;
        font-size: 1.5rem;
        font-weight: 400;
        color: #474C66; }
        @media (max-width: 1440px) {
          .header-top--left .mail {
            margin-right: 22px; } }
        @media (max-width: 1240px) {
          .header-top--left .mail {
            margin-right: 10px; } }
        @media (max-width: 580px) {
          .header-top--left .mail {
            display: none; } }
        .header-top--left .mail:hover {
          color: #323648;
          text-decoration: none; }
    .header-top--middle {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991px) {
        .header-top--middle {
          justify-content: flex-start;
          width: 80px;
          min-width: 80px; } }
      @media (max-width: 380px) {
        .header-top--middle {
          width: 60px;
          min-width: 60px; } }
      .header-top--middle .logo {
        display: inline-block;
        max-width: 136px;
        width: 100%;
        transition: all 0.2s ease-in-out 0s; }
        .header-top--middle .logo .delivery-restaurant__logo {
          position: relative;
          max-width: 160px;
          top: unset;
          bottom: unset;
          transform: none; }
        .header-top--middle .logo:hover {
          opacity: .7; }
        @media (max-width: 1440px) {
          .header-top--middle .logo {
            max-width: 100px; } }
        @media (max-width: 991px) {
          .header-top--middle .logo {
            width: 80px; } }
        .header-top--middle .logo img {
          display: block;
          width: 100%; }
    .header-top--right {
      max-width: 40%;
      width: 100%;
      display: flex;
      justify-content: flex-end; }
      @media (max-width: 991px) {
        .header-top--right {
          order: 2;
          width: 120px;
          right: 60px;
          position: absolute; } }
      .header-top--right .lang {
        display: none;
        align-items: center;
        justify-content: flex-start;
        box-sizing: border-box;
        margin-right: 92px; }
        @media (max-width: 1440px) {
          .header-top--right .lang {
            margin-right: 40px; } }
        @media (max-width: 1199px) {
          .header-top--right .lang {
            margin-right: 20px; } }
        @media (max-width: 991px) {
          .header-top--right .lang {
            margin-right: 20px; } }
        .header-top--right .lang ul {
          width: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
          .header-top--right .lang ul li {
            margin-right: 20px;
            position: relative; }
            .header-top--right .lang ul li a {
              font-size: 1.2rem;
              font-weight: 400;
              letter-spacing: .2rem;
              text-transform: uppercase;
              color: #aaaac1; }
              .header-top--right .lang ul li a.active {
                font-weight: 800;
                color: #474C66; }
                .header-top--right .lang ul li a.active:hover {
                  color: #323648; }
              .header-top--right .lang ul li a:hover {
                text-decoration: none;
                color: #8d8dab; }
            .header-top--right .lang ul li:after {
              content: '/';
              position: absolute;
              margin-right: -13px;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              font-size: 1.2rem;
              font-weight: 400;
              color: #aaaac1; }
            .header-top--right .lang ul li:last-child {
              margin-right: 0; }
              .header-top--right .lang ul li:last-child:after {
                display: none; }
      .header-top--right .phone-list {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 42%; }
        @media (max-width: 991px) {
          .header-top--right .phone-list {
            display: none; } }
        .header-top--right .phone-list--name {
          position: relative;
          width: 100%;
          text-align: left;
          cursor: pointer;
          font-size: 1.4rem;
          font-weight: 300;
          letter-spacing: .2rem;
          line-height: 42px;
          color: #fff;
          background-color: #303239;
          /*  @media (max-width: 1440px) {
              width: 120px;
            }
            @media (max-width: 991px) {
              height: 32px;
              line-height: rem(32px);
            }*/ }

@media screen and (max-width: 1199px) {
  .header-top--right .phone-list--name {
    font-size: 1.2rem; } }
          .header-top--right .phone-list--name p {
            padding: 0 1rem;
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis; }
          .header-top--right .phone-list--name.active::after {
            transform: translateY(-20%) rotate(225deg); }
          .header-top--right .phone-list--name:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-70%) rotate(45deg);
            display: block;
            width: 9px;
            height: 9px;
            transition: .2s;
            border-right: 1px solid #aaaac1;
            border-bottom: 1px solid #aaaac1;
            pointer-events: none; }
          .header-top--right .phone-list--name ul {
            position: absolute;
            display: none;
            left: 0;
            right: 0;
            top: 100%;
            padding-top: 5px;
            background-color: #fff;
            z-index: 99;
            border: 1px solid #aaaac1;
            box-sizing: border-box;
            opacity: 0; }
            .header-top--right .phone-list--name ul li {
              /*height: 42px;
              //адаптив
              @media (max-width: 991px) {
                height: 32px;
              }*/
              padding: 0.7rem 1rem; }
              .header-top--right .phone-list--name ul li:hover {
                text-decoration: none;
                background-color: #e5e6e9;
                padding-left: 1.6rem;
                transition: all 0.2s ease-out; }
              .header-top--right .phone-list--name ul li .select-name--list_item, .header-top--right .phone-list--name ul li a {
                display: block;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                color: #303239;
                line-height: normal;
                /*@media (max-width: 1440px) {
                  padding: 0 7px;
                }*/ }
              .header-top--right .phone-list--name ul li a {
                font-weight: bold; }
                .header-top--right .phone-list--name ul li a:hover {
                  text-decoration: none; }
          .header-top--right .phone-list--name .select-name--list-animate {
            display: block;
            opacity: 1;
            /*transform: translate(0, 0);
            animation-name: phone-list-anim;
            animation-iteration-count: 1;
            animation-duration: .5s;
            //transform: rotateZ(0);*/
            transition: all 0.3s ease-out; }
            .header-top--right .phone-list--name .select-name--list-animate li {
              transform-origin: top left;
              opacity: 0;
              border-bottom: 1px solid rgba(170, 170, 193, 0.3); }
              .header-top--right .phone-list--name .select-name--list-animate li.active {
                animation: menu-anim;
                animation-duration: 400ms;
                animation-fill-mode: forwards;
                animation-timing-function: ease-in-out; }

@keyframes menu-anim {
  0% {
    opacity: 0;
    transform: rotateZ(90deg); }
  70% {
    transform: skewX(10deg); }
  100% {
    opacity: 1;
    transform: rotateZ(0deg); } }
        .header-top--right .phone-list > .phone-list--result {
          width: 215px;
          height: 42px;
          display: inline-block;
          border: 1px solid #aaaac1;
          box-sizing: border-box;
          text-align: center;
          font-weight: 300;
          font-size: 1.8rem;
          line-height: 42px;
          color: #474C66; }
          @media (max-width: 1440px) {
            .header-top--right .phone-list > .phone-list--result {
              width: 160px;
              font-size: 1.4rem; } }
          @media (max-width: 991px) {
            .header-top--right .phone-list > .phone-list--result {
              height: 32px;
              line-height: 3.2rem; } }
          .header-top--right .phone-list > .phone-list--result:hover {
            text-decoration: none;
            color: #323648; }
  .header-bottom {
    width: 100%;
    height: auto;
    border-top: 1px solid rgba(170, 170, 193, 0.3);
    border-bottom: 1px solid rgba(170, 170, 193, 0.3); }
    @media (max-width: 991px) {
      .header-bottom {
        border-bottom: none;
        position: static !important; } }
    .header-bottom.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      background-color: #fff; }
    .header-bottom .burger {
      display: none;
      position: absolute;
      right: 20px;
      top: 38px;
      cursor: pointer;
      z-index: 1000;
      height: 30px; }
      @media (max-width: 380px) {
        .header-bottom .burger {
          top: 28px; } }
      .header-bottom .burger span {
        position: relative;
        top: 50%;
        display: block;
        width: 30px;
        height: 3px;
        background-color: #303239;
        transition: all 0.2s ease-in-out 0s;
        transform: translateY(-50%); }
        .header-bottom .burger span:after, .header-bottom .burger span:before {
          position: absolute;
          content: '';
          width: 30px;
          height: 3px;
          background-color: #303239;
          transition: all 0.2s ease-in-out 0s; }
        .header-bottom .burger span:after {
          margin-top: 8px; }
        .header-bottom .burger span:before {
          margin-top: -8px; }
      .header-bottom .burger-active span {
        background-color: #fff; }
        .header-bottom .burger-active span:after {
          transform: rotate(-43deg);
          margin-top: 0; }
        .header-bottom .burger-active span:before {
          transform: rotate(43deg);
          margin-top: 0; }

@media screen and (max-width: 991px) {
  .header-bottom .burger {
    display: block; } }
    .header-bottom .main-menu {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991px) {
        .header-bottom .main-menu {
          position: absolute;
          z-index: -1;
          display: block;
          max-width: 280px;
          height: auto;
          right: 20px;
          transform: translateY(-99999999px);
          opacity: 0;
          transition: all 0.2s ease-in-out 0s;
          border: 1px solid rgba(170, 170, 193, 0.3);
          border-top: none;
          background-color: #fff; }
          .header-bottom .main-menu--active {
            opacity: 1;
            transform: translateY(0);
            z-index: 1001; } }
      .header-bottom .main-menu--item a {
        display: block;
        position: relative;
        min-height: 54px;
        padding: 0 45px;
        line-height: 54px;
        font-weight: 800;
        font-size: 1.2rem;
        letter-spacing: .2rem;
        text-transform: uppercase;
        color: #474C66;
        transition: all 0.2s ease-in-out 0s; }
        @media (max-width: 1440px) {
          .header-bottom .main-menu--item a {
            padding: 0 15px;
            line-height: 40px;
            min-height: auto; } }
        .header-bottom .main-menu--item a:after {
          content: '/';
          position: absolute;
          color: rgba(170, 170, 193, 0.3);
          right: 0;
          font-size: 1.8rem;
          font-weight: 300; }
          @media (max-width: 991px) {
            .header-bottom .main-menu--item a:after {
              display: none; } }
        .header-bottom .main-menu--item a.active {
          color: #fff;
          background-color: #303239; }
          .header-bottom .main-menu--item a.active:after {
            display: none; }
        .header-bottom .main-menu--item a:hover {
          text-decoration: none;
          color: #fff;
          background-color: #aeb0ba; }
          .header-bottom .main-menu--item a:hover:after {
            display: none; }
  @media (max-width: 580px) {
    .header__banquet-btn {
      display: none; } }

.footer {
  position: relative;
  flex: 0 0 auto; }
  @media (max-width: 720px) {
    .footer {
      background-color: #fff; } }
  .footer-questions {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 56px 9% 45px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
    @media (max-width: 1600px) {
      .footer-questions {
        padding: 56px 4% 45px; } }
    @media (max-width: 991px) {
      .footer-questions {
        flex-direction: column;
        padding: 25px 10px 40px; } }
    .footer-questions:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(20, 20, 22, 0.672) 0%, rgba(20, 20, 22, 0.4) 100%), linear-gradient(180deg, rgba(20, 20, 22, 0.55) 0%, rgba(71, 76, 102, 0.35) 100%);
      z-index: 1; }
    .footer-questions--info {
      position: relative;
      z-index: 2; }
      @media (max-width: 991px) {
        .footer-questions--info {
          margin-bottom: 30px; } }
      .footer-questions--info h2 {
        font-weight: 100;
        font-size: 4.8rem;
        line-height: 5.2rem;
        color: #fff;
        margin-bottom: 10px; }
        @media (max-width: 991px) {
          .footer-questions--info h2 {
            font-size: 3.6rem;
            line-height: 4rem; } }
      .footer-questions--info p {
        font-weight: 100;
        line-height: 2.1rem;
        color: #fff;
        margin-bottom: 5px;
        font-size: 1.4rem; }
        .footer-questions--info p span {
          font-weight: 800;
          font-size: 1.8rem;
          letter-spacing: .1rem; }
    .footer-questions--form {
      position: relative;
      z-index: 2;
      width: 40%;
      max-width: 614px; }
      @media (max-width: 991px) {
        .footer-questions--form {
          width: 100%; } }
      .footer-questions--form .modal__terms .modal-label span {
        z-index: 2;
        color: #dfdfe8;
        font-size: 1.4rem; }
        .footer-questions--form .modal__terms .modal-label span a {
          color: #dfdfe8;
          font-size: 1.4rem; }
      .footer-questions--form .subscribe {
        position: relative;
        width: 100%;
        display: block; }
        .footer-questions--form .subscribe .btn {
          position: absolute;
          right: 16px;
          top: 14px;
          height: 49px;
          line-height: 49px;
          background-color: #ffff; }
          @media (max-width: 991px) {
            .footer-questions--form .subscribe .btn {
              top: 0;
              right: 0;
              background-color: #474C66;
              color: white;
              border: 1px solid transparent; }
              .footer-questions--form .subscribe .btn:hover {
                background-color: white;
                color: #474C66;
                transition: all 0.3s ease-in;
                border: 1px solid #474C66; } }
        .footer-questions--form .subscribe input {
          width: 100%;
          display: inline-block;
          height: 78px;
          box-sizing: border-box;
          background-color: #fff;
          border: none;
          padding: 0 200px 0 20px; }
          @media (max-width: 991px) {
            .footer-questions--form .subscribe input {
              height: 49px;
              padding-right: 130px; } }
      .footer-questions--form .errorlist {
        position: absolute;
        color: white;
        top: -30px;
        left: 0;
        font-size: 1.3rem; }
        .footer-questions--form .errorlist.nonfield {
          top: unset;
          bottom: -30px;
          left: 0; }
  .footer-main {
    background-color: #fff;
    padding-top: 80px;
    padding-right: 120px;
    padding-bottom: 60px;
    padding-left: 60px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (max-width: 1600px) {
      .footer-main {
        padding: 80px 60px 60px; } }
    @media (max-width: 720px) {
      .footer-main {
        padding: 20px 10px 30px;
        max-width: 320px;
        margin: auto;
        justify-content: center; } }
    .footer-main--contacts {
      max-width: 320px;
      white-space: nowrap; }
      @media (max-width: 1600px) {
        .footer-main--contacts {
          width: 220px; } }
      @media (max-width: 820px) {
        .footer-main--contacts {
          white-space: normal; } }
      @media (max-width: 720px) {
        .footer-main--contacts {
          order: 1;
          max-width: none;
          text-align: center;
          margin-bottom: 10px;
          flex-direction: column; } }
      .footer-main--contacts a {
        display: block;
        padding-left: 58px;
        font-weight: 400;
        font-size: 1.5rem;
        color: #474C66;
        margin-bottom: 30px;
        position: relative;
        transition: all 0.2s ease-in-out 0s; }
        @media (max-width: 720px) {
          .footer-main--contacts a {
            display: inline-block;
            margin-bottom: 10px;
            padding-left: 0; } }
        .footer-main--contacts a:last-child {
          margin-bottom: 0; }
        .footer-main--contacts a:after {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          transform: translateY(-50%); }
          @media (max-width: 720px) {
            .footer-main--contacts a:after {
              left: -48px; } }
          @media (max-width: 400px) {
            .footer-main--contacts a:after {
              left: -38px; } }
        .footer-main--contacts a.mail:after {
          background-image: url(../images/sprite.png);
          background-position: -22px -62px;
          width: 24px;
          height: 16px; }
        .footer-main--contacts a.adress:after {
          background-image: url(../images/sprite.png);
          background-position: -64px -25px;
          width: 18px;
          height: 24px; }
        .footer-main--contacts a:hover {
          text-decoration: none;
          color: #1d1f2a; }
    .footer-main--menu {
      max-width: calc(100% - 700px);
      width: 100%; }
      @media (max-width: 1600px) {
        .footer-main--menu {
          max-width: calc(100% - 600px); } }
      @media (max-width: 1440px) {
        .footer-main--menu {
          max-width: calc(100% - 460px); } }
      @media (max-width: 1200px) {
        .footer-main--menu {
          display: none; } }
      .footer-main--menu ul {
        display: flex;
        flex-wrap: wrap; }
        .footer-main--menu ul li {
          flex-basis: 50%;
          min-width: 210px; }
          .footer-main--menu ul li:nth-child(even) {
            text-align: right; }
            .footer-main--menu ul li:nth-child(even) a {
              margin-right: 0;
              margin-left: auto;
              display: block; }
          .footer-main--menu ul li a {
            display: inline-block;
            max-width: 210px;
            width: auto;
            text-align: left;
            font-weight: 800;
            font-size: 1.2rem;
            text-transform: uppercase;
            color: #474C66;
            letter-spacing: .2rem;
            line-height: 3.6rem; }
            .footer-main--menu ul li a:hover {
              text-decoration: none;
              color: #5c6284; }
    .footer-main--logo {
      position: absolute;
      top: 48px;
      left: 50%;
      transform: translateX(-50%);
      width: 255px; }
      @media (max-width: 1440px) {
        .footer-main--logo {
          width: 180px; } }
      @media (max-width: 720px) {
        .footer-main--logo {
          order: 0;
          position: static;
          width: 100%;
          margin-bottom: 10px;
          left: auto;
          right: auto;
          transform: none; } }
      .footer-main--logo a {
        width: 136px;
        display: block;
        height: auto;
        transition: all 0.2s ease-in-out 0s;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 37px; }
        @media (max-width: 1440px) {
          .footer-main--logo a {
            width: 100px;
            margin-bottom: 15px; } }
        .footer-main--logo a img {
          display: block;
          width: 100%; }
        .footer-main--logo a:hover {
          text-decoration: none;
          opacity: .7; }
      .footer-main--logo p {
        text-align: center;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #aaaac1; }
    .footer-main--social {
      max-width: 320px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column; }
      @media (max-width: 720px) {
        .footer-main--social {
          order: 2;
          width: 100%;
          max-width: none;
          align-items: center; } }
      .footer-main--social .phone {
        margin-bottom: 40px;
        position: relative;
        padding-left: 48px; }
        @media (max-width: 720px) {
          .footer-main--social .phone {
            padding-left: 0;
            margin-bottom: 10px; } }
        .footer-main--social .phone:before {
          position: absolute;
          content: '';
          top: 3px;
          left: 0;
          background-image: url(../images/sprite.png);
          background-position: -37px 0px;
          width: 22px;
          height: 22px; }
          @media (max-width: 720px) {
            .footer-main--social .phone:before {
              left: -48px; } }
          @media (max-width: 400px) {
            .footer-main--social .phone:before {
              left: -38px; } }
        .footer-main--social .phone a {
          display: block;
          font-weight: 400;
          font-size: 1.5rem;
          color: #474C66;
          transition: all 0.2s ease-in-out 0s; }
          @media (max-width: 720px) {
            .footer-main--social .phone a {
              margin-bottom: 10px; } }
          .footer-main--social .phone a:hover {
            text-decoration: none;
            color: #1d1f2a; }
      .footer-main--social .social-link {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap; }
        .footer-main--social .social-link a {
          max-width: 24px;
          max-height: 24px;
          display: block;
          transition: all 0.2s ease-in-out 0s;
          margin: 5px; }
          .footer-main--social .social-link a img {
            max-width: 100%;
            display: block; }
          .footer-main--social .social-link a:hover {
            text-decoration: none;
            opacity: .8; }
  .footer-production {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: 11px;
    padding-right: 44px;
    padding-left: 44px;
    padding-bottom: 5px;
    background-color: #E5E5E5; }
    .footer-production a {
      position: relative;
      display: block;
      max-width: 220px;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: #aaaac1;
      /*&:before {
        position: absolute;
        content: '';
        @include sprite($icon-coffeestudio);
        left: -50px;
        top: 0;
      }*/ }
  @media (max-width: 600px) {
    .footer .modal__terms {
      padding-left: 0; } }
  .footer .modal__terms .modal-label {
    color: #e3e3ea; }
    .footer .modal__terms .modal-label:before {
      background-color: #767d9c; }

.main {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  position: relative;
  margin-right: -20px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 110px);
  grid-auto-rows: 110px;
  grid-gap: 20px;
  max-width: 1880px;
  margin-top: -40px; }
  .main-item {
    display: flex;
    background-size: cover;
    background-position: center center;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%; }
    .main-item:nth-child(8n + 1), .main-item:nth-child(8n + 6) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto; }
    .main-item:nth-child(8n + 2), .main-item:nth-child(8n + 3) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto; }
    .main-item:nth-child(8n + 4), .main-item:nth-child(8n + 5) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto; }
    .main-item:nth-child(8n + 7), .main-item:nth-child(8n + 8) {
      grid-column: span 2 / auto;
      grid-row: span 4 / auto; }
    .main-item--logo {
      display: flex;
      max-width: 290px;
      height: auto;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 10px;
      transition: all 0.2s ease-in-out 0s;
      opacity: 1; }
      .main-item--logo img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        height: auto; }
    .main-item--hover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(58, 60, 67, 0.7);
      mix-blend-mode: normal;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      transition: all 0.2s ease-in-out 0s; }
      .main-item--hover h3 {
        font-size: 1.8rem;
        font-weight: 800;
        letter-spacing: 0.1rem;
        line-height: 3.2rem;
        color: #ffffff; }
        @media (max-width: 768px) {
          .main-item--hover h3 {
            font-size: 1.4rem;
            line-height: 2.4rem; } }
      .main-item--hover p {
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 0.1rem;
        line-height: 3.2rem;
        color: #ffffff; }

@media screen and (max-width: 1000px) {
  .main-item--hover p {
    line-height: normal; } }

@media screen and (max-width: 768px) {
  .main-item--hover p {
    font-size: 1.2rem;
    line-height: 2rem; } }
      .main-item--hover__phones {
        display: flex;
        flex-direction: column; }
    .main-item:hover {
      text-decoration: none; }
      .main-item:hover .main-item--logo {
        opacity: 0; }
      .main-item:hover .main-item--hover {
        opacity: 1; }

@media (max-width: 1440px) {
  .main {
    grid-gap: 15px;
    grid-template-rows: repeat(3, 110px); }
    .main-item:nth-child(8n + 1), .main-item:nth-child(8n + 4) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; }
    .main-item:nth-child(8n + 2), .main-item:nth-child(8n + 3) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; }
    .main-item:nth-child(8n + 5), .main-item:nth-child(8n + 6) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; }
    .main-item:nth-child(8n + 7), .main-item:nth-child(8n + 8) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; } }

@media (max-width: 991px) {
  .main {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, 70px);
    grid-auto-rows: 70px;
    grid-gap: 10px; }
    .main-item:nth-child(8n + 1) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; }
    .main-item:nth-child(8n + 2), .main-item:nth-child(8n + 4), .main-item:nth-child(8n + 6), .main-item:nth-child(8n + 7) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; }
    .main-item:nth-child(8n + 3), .main-item:nth-child(8n + 5) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; }
    .main-item:nth-child(8n + 8) {
      grid-column: span 2 / auto;
      grid-row: span 3 / auto; }
    .main-item--logo {
      max-width: 130px; } }

@media (max-width: 691px) {
  .main {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 70px);
    grid-auto-rows: 70px;
    grid-gap: 5px; }
    .main-item {
      grid-column: span 2 / auto !important;
      grid-row: span 2 / auto !important; } }

@media (max-width: 540px) {
  .main {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 70px);
    grid-auto-rows: 70px;
    grid-gap: 5px; }
    .main-item {
      grid-column: span 2 / auto !important;
      grid-row: span 2 / auto !important; } }

.services {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
  background: white;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0.7) 47%, rgba(237, 237, 237, 0.78) 100%); }
  @media (max-width: 991px) {
    .services {
      flex-wrap: wrap;
      margin-bottom: 30px; } }
  @media (max-width: 480px) {
    .services {
      margin-bottom: 20px; } }
  .services-info {
    width: 29.5%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 100px;
    padding-left: 50px;
    padding-bottom: 20px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #474C66;
    text-align: left; }
    @media (max-width: 991px) {
      .services-info {
        width: 100%;
        margin-bottom: 20px;
        padding-top: 0; } }
    @media (max-width: 768px) {
      .services-info {
        padding-left: 0; } }
    .services-info .header-block {
      margin-bottom: 25px; }
      @media (max-width: 768px) {
        .services-info .header-block {
          text-align: center;
          width: 100%; } }
    .services-info .dark-btn {
      margin-top: 40px; }
  .services-list {
    width: 65%;
    column-count: 4;
    column-gap: 20px; }
    @media (max-width: 1440px) {
      .services-list {
        column-count: 3;
        column-gap: 15px; } }
    @media (max-width: 991px) {
      .services-list {
        width: 100%; } }
    @media (max-width: 691px) {
      .services-list {
        column-count: 2;
        column-gap: 10px; } }
    @media (max-width: 480px) {
      .services-list {
        column-count: initial;
        column-gap: initial;
        position: relative; } }
    .services-list--item {
      display: inline-block;
      margin-bottom: 20px;
      max-width: 297px;
      width: 100%;
      height: 297px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      position: relative;
      box-sizing: border-box;
      transition: all 0.2s ease-in-out 0s;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      @media (max-width: 1440px) {
        .services-list--item {
          margin-bottom: 7.5px;
          height: 250px; } }
      @media (max-width: 691px) {
        .services-list--item {
          margin-bottom: 5px;
          height: 200px;
          max-width: 340px;
          background-repeat: no-repeat;
          background-size: cover; } }
      @media (max-width: 480px) {
        .services-list--item {
          max-width: 440px;
          width: 100%;
          margin-top: 0 !important; } }
      .services-list--item:after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(20, 20, 22, 0.472) 0%, rgba(20, 20, 22, 0) 100%), linear-gradient(180deg, rgba(20, 20, 22, 0.25) 0%, rgba(71, 76, 102, 0.25) 100%);
        transition: all 0.2s ease-in-out 0s; }
      .services-list--item span {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        text-align: center;
        width: 85%;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        padding: 16px 0;
        font-weight: 800;
        font-size: 1.2rem;
        letter-spacing: .2rem;
        text-transform: uppercase;
        color: #fff; }
      .services-list--item:nth-child(1) {
        margin-top: 50px; }
      .services-list--item:nth-child(3) {
        margin-top: 0; }
      .services-list--item:nth-child(5) {
        margin-top: 60px; }
        @media (max-width: 1440px) {
          .services-list--item:nth-child(5) {
            margin-top: 0; } }
      @media (max-width: 1440px) {
        .services-list--item:nth-child(6) {
          margin-top: 20px; } }
      @media (max-width: 691px) {
        .services-list--item:nth-child(6) {
          margin-top: 0; } }
      .services-list--item:nth-child(7) {
        margin-top: 20px; }
        @media (max-width: 1440px) {
          .services-list--item:nth-child(7) {
            margin-top: 0; } }
      .services-list--item:hover {
        text-decoration: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }
        .services-list--item:hover:after {
          opacity: .5; }
    .services-list .slick-arrow {
      position: absolute;
      z-index: 99;
      top: 0;
      width: 60px;
      height: 60px;
      text-indent: -99999999999px;
      overflow: hidden;
      box-sizing: border-box;
      background: #343745;
      border: 8px solid #303239;
      background-position: center center;
      background-repeat: no-repeat; }
      .services-list .slick-arrow.slick-prev {
        right: 60px;
        background-image: url(../images/slick-prev.png); }
      .services-list .slick-arrow.slick-next {
        right: 0;
        background-image: url(../images/slick-next.png); }

.products {
  width: 100%;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .products {
      margin-bottom: 150px; } }
  @media (max-width: 480px) {
    .products {
      margin-bottom: 0; } }
  .products .header-block {
    text-align: center;
    margin-bottom: 55px; }
    .products .header-block--btn {
      background: linear-gradient(180deg, rgba(236, 142, 28, 0.06) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      padding: 60px 55px 42px 55px;
      margin-top: 40px; }
      @media (max-width: 1440px) {
        .products .header-block--btn {
          padding: 42px 55px 42px 55px; } }
      @media (max-width: 991px) {
        .products .header-block--btn {
          padding: 30px 30px 30px;
          margin-top: 30px; } }
      .products .header-block--btn__description {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 991px) {
          .products .header-block--btn__description {
            flex-direction: column; } }
        .products .header-block--btn__description .item {
          display: flex;
          margin-right: 30px; }
          .products .header-block--btn__description .item:last-child {
            margin-right: 0; }
          @media (max-width: 1440px) {
            .products .header-block--btn__description .item {
              flex-direction: column; } }
          @media (max-width: 991px) {
            .products .header-block--btn__description .item {
              margin-right: 0;
              margin-bottom: 20px; }
              .products .header-block--btn__description .item:last-child {
                margin-bottom: 0; } }
          .products .header-block--btn__description .item svg {
            min-width: 60px;
            margin-right: 30px; }
            @media (max-width: 1440px) {
              .products .header-block--btn__description .item svg {
                align-self: center; } }
            @media (max-width: 991px) {
              .products .header-block--btn__description .item svg {
                margin-right: 0; } }
          .products .header-block--btn__description .item-description {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2.2rem;
            color: #474C66;
            max-width: 400px;
            text-align: left; }
            @media (max-width: 991px) {
              .products .header-block--btn__description .item-description {
                max-width: 100%;
                text-align: center; } }
            .products .header-block--btn__description .item-description--strong {
              font-weight: 700; }
            .products .header-block--btn__description .item-description--item {
              display: block; }
              .products .header-block--btn__description .item-description--item a {
                color: #474C66; }
                .products .header-block--btn__description .item-description--item a:hover {
                  color: #41afd3; }
      .products .header-block--btn .dark-btn {
        margin-top: 30px; }
        @media (max-width: 540px) {
          .products .header-block--btn .dark-btn span {
            padding: 10px 30px;
            line-height: 2.5rem; } }
  .products-list {
    column-count: 4;
    column-gap: 20px;
    column-fill: balance;
    max-height: 760px; }
    @media (max-width: 1240px) {
      .products-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-wrap: wrap; } }
    @media (max-width: 480px) {
      .products-list {
        all: unset; } }
    .products-list--item {
      position: relative;
      width: 100%;
      max-width: 445px;
      display: inline-block;
      height: 400px;
      margin-bottom: 20px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid rgba(0, 0, 0, 0.05);
      background: linear-gradient(180deg, rgba(236, 142, 28, 0.06) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
      background-repeat: no-repeat;
      background-position: center top;
      transition: all 0.2s ease-in-out 0s; }
      .products-list--item:nth-child(8n + 2) {
        height: 300px; }
        .products-list--item:nth-child(8n + 2) span {
          top: 230px; }
      .products-list--item:nth-child(8n + 3) {
        height: 300px; }
        .products-list--item:nth-child(8n + 3) span {
          top: 230px; }
      .products-list--item:nth-child(8n + 6) {
        height: 300px; }
        .products-list--item:nth-child(8n + 6) span {
          top: 230px; }
      .products-list--item:nth-child(8n + 7) {
        height: 300px; }
        .products-list--item:nth-child(8n + 7) span {
          top: 230px; }
      .products-list--item span {
        top: 330px;
        left: 40px;
        right: 40px;
        position: absolute;
        display: block;
        font-weight: 800;
        font-size: 1.2rem;
        line-height: 1.4rem;
        letter-spacing: .2rem;
        text-transform: uppercase;
        color: #474C66;
        padding: 1rem;
        background-color: rgba(247, 252, 252, 0.9);
        text-align: center; }
      @media (max-width: 1240px) {
        .products-list--item {
          height: 200px !important;
          width: 32%;
          position: relative; }
          .products-list--item span {
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -1px;
            top: 0 !important;
            padding: 0px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.8);
            font-size: 1.8rem;
            line-height: 2.5rem; } }
      @media (max-width: 991px) {
        .products-list--item {
          width: 49%; } }
      @media (max-width: 480px) {
        .products-list--item {
          width: 100%;
          height: 200px !important; }
          .products-list--item span {
            position: absolute;
            left: -1px;
            right: -1px;
            bottom: -1px;
            top: 0 !important;
            padding: 0px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.8);
            font-size: 1.8rem;
            line-height: 2.5rem; } }
      .products-list--item:hover {
        text-decoration: none;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25); }

.about {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  margin-bottom: 75px;
  background: white;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0.7) 47%, rgba(237, 237, 237, 0.78) 100%); }
  @media (min-width: 1241px) {
    .about {
      margin-bottom: 50px; } }
  @media (max-width: 991px) {
    .about {
      margin-bottom: 40px; } }
  @media (max-width: 600px) {
    .about {
      margin-bottom: 20px; } }
  .about-img {
    width: 40%; }
    @media (max-width: 991px) {
      .about-img {
        display: none; } }
    .about-img img {
      display: block;
      width: 100%; }
  .about-info {
    width: 60%;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start; }
    @media (max-width: 991px) {
      .about-info {
        flex-wrap: wrap;
        width: 100%; } }
    @media (max-width: 768px) {
      .about-info {
        padding-left: 0; } }
    .about-info-col {
      width: 70%;
      max-height: 100%;
      transition: all 0.2s ease-in-out 0s; }
      .about-info-col:first-child {
        width: 30%;
        padding-top: 80px; }
        @media (max-width: 1240px) {
          .about-info-col:first-child {
            padding-top: 25px; } }
        @media (max-width: 600px) {
          .about-info-col:first-child {
            width: 100%; } }
      .about-info-col:not(:first-child) {
        padding: 20px 10% 0;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.1rem;
        color: #303239; }
        .about-info-col:not(:first-child) p {
          margin-bottom: 40px; }
        @media (max-width: 1240px) {
          .about-info-col:not(:first-child) {
            padding: 10px 5% 0; }
            .about-info-col:not(:first-child) p {
              margin-bottom: 10px; } }
        @media (max-width: 991px) {
          .about-info-col:not(:first-child) {
            padding-top: 40px; } }
        @media (max-width: 600px) {
          .about-info-col:not(:first-child) {
            padding-top: 0;
            width: 100%;
            padding: 0; } }
      .about-info-col .header-block {
        margin-bottom: 80px; }
        @media (max-width: 1240px) {
          .about-info-col .header-block {
            margin-bottom: 25px; } }
        @media (max-width: 600px) {
          .about-info-col .header-block {
            padding-top: 0;
            margin-bottom: 30px;
            text-align: center; } }
      .about-info-col .about-info-img {
        width: 100%; }
        @media (max-width: 600px) {
          .about-info-col .about-info-img {
            display: none; } }
        .about-info-col .about-info-img img {
          display: block;
          width: 100%; }

.news .header-block {
  text-align: center;
  margin-bottom: 65px; }
  @media (max-width: 600px) {
    .news .header-block {
      margin-bottom: 20px; } }

.news-list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 1120px) {
    .news-list {
      flex-wrap: wrap; } }
  .news-list-item {
    width: 49%; }
    @media (max-width: 1120px) {
      .news-list-item {
        width: 100%;
        margin-bottom: 20px; } }
    .news-list-item .main-slider {
      width: 100%;
      margin-bottom: 50px; }
      @media (max-width: 1120px) {
        .news-list-item .main-slider {
          margin-bottom: 20px; } }
      .news-list-item .main-slider--menu {
        width: 100%; }
        .news-list-item .main-slider--menu .slick-track {
          display: flex;
          align-items: flex-end; }
          @media (max-width: 600px) {
            .news-list-item .main-slider--menu .slick-track {
              align-items: flex-start; } }
        .news-list-item .main-slider--menu .slick-arrow {
          position: absolute;
          z-index: 99;
          top: 0;
          width: 60px;
          height: 60px;
          text-indent: -99999999999px;
          overflow: hidden;
          box-sizing: border-box;
          background: #343745;
          border: 8px solid #303239;
          background-position: center center;
          background-repeat: no-repeat; }
          .news-list-item .main-slider--menu .slick-arrow.slick-prev {
            right: 60px;
            background-image: url(../images/slick-prev.png); }
          .news-list-item .main-slider--menu .slick-arrow.slick-next {
            right: 0;
            background-image: url(../images/slick-next.png); }
      .news-list-item .main-slider-item {
        width: 100%;
        display: inline-flex !important;
        align-items: stretch;
        justify-content: flex-start;
        max-height: 445px;
        height: 100%;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15); }
        @media (max-width: 600px) {
          .news-list-item .main-slider-item {
            flex-direction: column;
            max-height: none;
            height: auto;
            margin-bottom: 2rem; } }
        .news-list-item .main-slider-item .img {
          width: 50%;
          position: relative;
          background-position: center center;
          -webkit-background-size: cover;
          background-size: cover; }
          @media (max-width: 600px) {
            .news-list-item .main-slider-item .img {
              width: 100%;
              height: 200px; } }
          .news-list-item .main-slider-item .img-logo {
            position: absolute;
            width: 30%;
            top: 45px;
            left: 30px; }
            .news-list-item .main-slider-item .img-logo img {
              width: 100%;
              display: block; }
        .news-list-item .main-slider-item .info {
          width: 50%;
          padding: 45px 40px;
          background-color: #fff; }
          @media (max-width: 1640px) {
            .news-list-item .main-slider-item .info {
              padding: 35px 20px; } }
          @media (max-width: 1480px) {
            .news-list-item .main-slider-item .info {
              padding-right: 10px; } }
          @media (max-width: 600px) {
            .news-list-item .main-slider-item .info {
              width: 100%;
              padding: 15px 10px; } }
          .news-list-item .main-slider-item .info .title {
            display: inline-block;
            font-weight: 800;
            font-size: 1.2rem;
            letter-spacing: .2rem;
            text-transform: uppercase;
            line-height: 1.8rem;
            color: #474C66;
            position: relative;
            margin-bottom: 71px; }
            @media (max-width: 1480px) {
              .news-list-item .main-slider-item .info .title {
                margin-bottom: 25px; } }
          .news-list-item .main-slider-item .info-body {
            width: 100%;
            max-height: 185px;
            overflow: hidden; }
            .news-list-item .main-slider-item .info-body .title-link {
              color: #474C66; }
            .news-list-item .main-slider-item .info-body h3 {
              font-weight: 800;
              font-size: 1.8rem;
              letter-spacing: .1rem;
              color: #474C66;
              line-height: 21px;
              max-height: 42px;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 10px; }
            .news-list-item .main-slider-item .info-body p {
              font-weight: 400;
              font-size: 1.5rem; }
              @media (max-width: 1480px) {
                .news-list-item .main-slider-item .info-body p {
                  font-size: 1.4rem; } }
        .news-list-item .main-slider-item .dark-btn {
          margin-top: 24px; }
      .news-list-item .main-slider .slick-arrow {
        position: absolute;
        z-index: 99;
        top: 0;
        width: 60px;
        height: 60px;
        text-indent: -99999999999px;
        overflow: hidden;
        box-sizing: border-box;
        background: #343745;
        border: 8px solid #303239;
        background-position: center center;
        background-repeat: no-repeat; }
        .news-list-item .main-slider .slick-arrow.slick-prev {
          right: 60px;
          background-image: url(../images/slick-prev.png); }
        .news-list-item .main-slider .slick-arrow.slick-next {
          right: 0;
          background-image: url(../images/slick-next.png); }

.news--menu {
  width: 100%;
  margin-bottom: 2rem; }
  @media (max-width: 1200px) {
    .news--menu {
      margin-top: 1.5rem; } }
  .news--menu .news-list-item {
    width: 50%;
    margin: 0 auto; }
    @media (max-width: 1200px) {
      .news--menu .news-list-item {
        width: 75%; } }
    @media (max-width: 890px) {
      .news--menu .news-list-item {
        width: 90%; } }
    @media (max-width: 768px) {
      .news--menu .news-list-item {
        width: 100%; } }
    .news--menu .news-list-item--menu {
      width: 100%; }
      @media (max-width: 1200px) {
        .news--menu .news-list-item--menu {
          width: 100%; } }

.map-btn {
  color: white;
  font-size: 1.4rem;
  padding: 0.7rem 1.2rem; }

.accordion-info-col {
  position: relative;
  transition: all 0.2s ease-in-out 0s; }
  .accordion-info-col__inner {
    overflow: hidden;
    height: 100%; }
    .accordion-info-col__inner ul {
      padding-left: 20px;
      list-style: disc; }
      .accordion-info-col__inner ul li {
        margin-bottom: 5px; }
  .accordion-info-col_active {
    height: 498px;
    overflow: hidden;
    transition: all 0.2s ease-in-out 0s; }
    .accordion-info-col_active:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: 2rem; }
    .accordion-info-col_active:hover:before {
      transition: all 0.2s ease-in-out 0s; }
  .accordion-info-col__more {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: 0;
    cursor: pointer;
    text-align: center;
    padding: 0;
    color: #303239;
    font-weight: bold;
    cursor: pointer; }

.title-page {
  width: 100%;
  background-color: #fff;
  min-height: 145px;
  margin-top: -60px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  padding: 10px; }
  @media (max-width: 1440px) {
    .title-page {
      width: 100%; } }
  @media (max-width: 768px) {
    .title-page {
      min-height: 100px;
      margin-bottom: 35px; } }
  .title-page .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    @media (max-width: 1440px) {
      .title-page .wrapper {
        flex-direction: column;
        align-items: flex-start; } }
  .title-page .back-page {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
    padding-left: 45px; }
    @media (max-width: 1440px) {
      .title-page .back-page {
        position: static;
        transform: translateY(0); } }
    .title-page .back-page a {
      position: relative;
      font-weight: 400;
      font-size: 1.3rem;
      color: #aaaac1;
      transition: all 0.2s ease-in-out 0s; }
      .title-page .back-page a:hover {
        text-decoration: none;
        color: #8d8dab; }
        .title-page .back-page a:hover:before {
          opacity: 1; }
      .title-page .back-page a:before {
        position: absolute;
        content: '';
        background-image: url(../images/sprite.png);
        background-position: -64px 0px;
        width: 22px;
        height: 20px;
        left: -45px;
        top: 50%;
        transform: translateY(-50%);
        opacity: .5;
        transition: all 0.2s ease-in-out 0s; }
  .title-page-item {
    padding: 0 120px; }
    @media (max-width: 1440px) {
      .title-page-item {
        padding: 0 15px;
        margin-top: 1rem; } }
    @media (max-width: 600px) {
      .title-page-item {
        margin-top: 2rem; } }
  .title-page-item,
  .title-page-item h1 {
    font-size: 4.8rem;
    font-weight: 100;
    color: #303239;
    text-align: center; }
    @media (max-width: 1440px) {
      .title-page-item,
      .title-page-item h1 {
        width: 100%; } }
    @media (max-width: 1100px) {
      .title-page-item,
      .title-page-item h1 {
        font-size: 3.9rem; } }
    @media (max-width: 768px) {
      .title-page-item,
      .title-page-item h1 {
        font-size: 3rem; } }
    @media (max-width: 600px) {
      .title-page-item,
      .title-page-item h1 {
        font-size: 2.5rem; } }

.article {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 1100px) {
    .article {
      flex-direction: column; } }
  .article-page {
    flex-basis: 74%;
    padding: 60px 55px 54px 85px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    text-align: left;
    margin-bottom: 60px;
    font-weight: 400;
    font-size: 1.5rem;
    color: #474C66; }
    @media (max-width: 1100px) {
      .article-page {
        flex-basis: 100%;
        width: 100%;
        padding: 30px 25px 25px 40px; } }
    @media (max-width: 768px) {
      .article-page {
        padding: 20px; } }
    .article-page p {
      margin-bottom: 25px; }
    .article-page ul {
      list-style: initial; }
    .article-page ol {
      list-style: decimal; }
    .article-page dl {
      list-style: inherit; }
      .article-page dl dd {
        padding-left: 10px; }
    .article-page ol, .article-page ul, .article-page dl {
      padding-left: 20px;
      margin-bottom: 20px; }
    .article-page li, .article-page dl {
      margin-bottom: 10px;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: normal;
      color: #474C66; }
    .article-page-title {
      margin-bottom: 25px;
      font-weight: 800;
      font-size: 1.8rem;
      letter-spacing: .1em;
      line-height: normal;
      color: #474C66; }
    .article-page-cover {
      width: 44%;
      float: right;
      margin-left: 8.9%;
      margin-bottom: 10px; }
      @media (max-width: 600px) {
        .article-page-cover {
          width: 80%;
          margin: 0 auto 10px;
          float: unset; } }
      @media (max-width: 480px) {
        .article-page-cover {
          width: 100%; } }
      .article-page-cover p {
        margin-bottom: 20px; }
      .article-page-cover img {
        width: 100%;
        display: block; }
      .article-page-cover figcaption {
        font-weight: 300;
        font-size: 1.3rem;
        line-height: normal;
        color: #aaaac1; }
        @media (max-width: 600px) {
          .article-page-cover figcaption {
            text-align: center; } }
    .article-page-slider {
      width: 51%;
      display: block;
      margin-left: 0;
      margin-right: auto;
      max-width: 647px;
      position: relative;
      margin-bottom: 30px; }
      @media (max-width: 1100px) {
        .article-page-slider {
          width: 100%;
          margin: 0 auto 30px; } }
      .article-page-slider .big-slider {
        width: 100%; }
        .article-page-slider .big-slider > .slick-arrow {
          position: absolute;
          width: 42px;
          height: 42px;
          top: 50%;
          background: #fff;
          border: none;
          overflow: hidden;
          text-indent: -999999px;
          transform: translateY(-50%);
          z-index: 99; }
          @media (max-width: 500px) {
            .article-page-slider .big-slider > .slick-arrow {
              background: rgba(255, 255, 255, 0.5);
              width: 25px;
              height: 25px; } }
          .article-page-slider .big-slider > .slick-arrow:after {
            position: absolute;
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            top: 50%;
            left: 50%;
            border-top: 1px solid #303239;
            border-right: 1px solid #303239; }
          .article-page-slider .big-slider > .slick-arrow.slick-prev {
            left: 20px; }
            .article-page-slider .big-slider > .slick-arrow.slick-prev:after {
              transform: translate(-50%, -50%) rotate(-135deg); }
          .article-page-slider .big-slider > .slick-arrow.slick-next {
            right: 20px; }
            .article-page-slider .big-slider > .slick-arrow.slick-next:after {
              transform: translate(-50%, -50%) rotate(45deg); }
        @media (max-width: 500px) {
          .article-page-slider .big-slider-item img {
            width: 100%; } }
        .article-page-slider .big-slider-item:after {
          position: absolute;
          z-index: 10;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(360deg, #303239 0%, rgba(20, 20, 22, 0) 19.37%); }
      .article-page-slider .thimbl-slider {
        position: absolute;
        display: block;
        width: 80%;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto; }
        @media (max-width: 768px) {
          .article-page-slider .thimbl-slider {
            display: none; } }
        .article-page-slider .thimbl-slider .slick-list.draggable {
          padding: 5px 0 0 0; }
        .article-page-slider .thimbl-slider > .slick-arrow {
          position: absolute;
          width: 21px;
          height: 18px;
          top: 50%;
          background: none;
          border: none;
          overflow: hidden;
          text-indent: -999999px;
          transform: translateY(-50%);
          z-index: 99; }
          .article-page-slider .thimbl-slider > .slick-arrow.slick-prev {
            left: -50px; }
            .article-page-slider .thimbl-slider > .slick-arrow.slick-prev:after {
              position: absolute;
              content: '';
              display: block;
              background-image: url(../images/sprite.png);
              background-position: -27px -37px;
              width: 22px;
              height: 20px;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0; }
          .article-page-slider .thimbl-slider > .slick-arrow.slick-next {
            right: -50px; }
            .article-page-slider .thimbl-slider > .slick-arrow.slick-next:after {
              position: absolute;
              content: '';
              display: block;
              background-image: url(../images/sprite.png);
              background-position: 0px -37px;
              width: 22px;
              height: 20px;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0; }
        .article-page-slider .thimbl-slider .slick-current img {
          outline: 3px solid #fff; }
        .article-page-slider .thimbl-slider-item {
          width: 120px;
          padding: 0 10px; }
          .article-page-slider .thimbl-slider-item img {
            display: block;
            width: 100%; }
    .article-page-video {
      position: relative;
      display: block;
      width: 51%;
      padding-top: 40px; }
      @media (max-width: 1100px) {
        .article-page-video {
          width: 100%;
          margin: 0 auto; } }
      .article-page-video .video {
        width: 100%;
        padding-bottom: 56%;
        position: relative; }
        .article-page-video .video iframe {
          display: none;
          position: absolute;
          width: 100%;
          height: 100%; }
        .article-page-video .video-cover {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-size: cover !important; }
          .article-page-video .video-cover-play {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 48px;
            height: 48px;
            display: block;
            background-color: rgba(255, 255, 255, 0.9); }
            .article-page-video .video-cover-play:after {
              content: '';
              position: absolute;
              width: 24px;
              height: 16px;
              margin: auto;
              margin-left: 20px;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-right: 12px solid transparent;
              border-top: 8px solid transparent;
              border-bottom: 8px solid transparent;
              border-left: 12px solid #303239;
              box-sizing: border-box; }
            .article-page-video .video-cover-play:before {
              position: absolute;
              content: '';
              width: 58px;
              height: 58px;
              margin: auto;
              margin-left: -4px;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 1px solid #AAAAC1;
              box-sizing: border-box;
              transition: all 0.2s ease-in-out 0s; }
            .article-page-video .video-cover-play:hover {
              text-decoration: none; }
              .article-page-video .video-cover-play:hover:before {
                transform: scale(2);
                opacity: 0; }
      .article-page-video h4 {
        padding-top: 20px;
        font-weight: 300;
        font-size: 1.3rem;
        color: #aaaac1; }
  .article-sidebar {
    flex-basis: 24%; }
    @media (max-width: 1100px) {
      .article-sidebar {
        flex-basis: 100%; } }
    .article-sidebar .title {
      position: relative;
      padding-left: 40px;
      letter-spacing: .2em;
      text-transform: uppercase;
      color: #474C66;
      margin-bottom: 57px; }
      .article-sidebar .title font {
        width: 800;
        size: 1.2rem; }
      .article-sidebar .title:after {
        position: absolute;
        content: '';
        width: 65px;
        height: 1px;
        background: #141416;
        left: 40px;
        bottom: -16px; }
        @media (max-width: 600px) {
          .article-sidebar .title:after {
            left: 0; } }
      .article-sidebar .title:hover {
        text-decoration: none; }
      @media (max-width: 1100px) {
        .article-sidebar .title {
          width: 100%; } }
      @media (max-width: 600px) {
        .article-sidebar .title {
          padding-left: 0; } }
    .article-sidebar .share-block-links {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 40px;
      margin-bottom: 60px; }
      @media (max-width: 600px) {
        .article-sidebar .share-block-links {
          padding-left: 0; } }
      .article-sidebar .share-block-links li {
        margin-right: 20px;
        width: auto;
        display: inline-block; }
        .article-sidebar .share-block-links li a {
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: .6;
          transition: all 0.2s ease-in-out 0s;
          width: 48px;
          height: 48px;
          border: 2px solid #AAAAC1;
          box-sizing: border-box; }
          .article-sidebar .share-block-links li a:hover {
            opacity: 1; }
        .article-sidebar .share-block-links li .icon {
          transition: all 0.2s ease-in-out 0s;
          display: block; }
          .article-sidebar .share-block-links li .icon-vk {
            background-image: url(../images/sprite.png);
            background-position: -51px -62px;
            width: 24px;
            height: 14px; }
          .article-sidebar .share-block-links li .icon-fb {
            background-image: url(../images/sprite.png);
            background-position: -91px 0px;
            width: 13px;
            height: 24px; }
          .article-sidebar .share-block-links li .icon-ok {
            background-image: url(../images/sprite.png);
            background-position: 0px -62px;
            width: 17px;
            height: 24px; }
    .article-sidebar .share-block .ya-share2__list {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
    .article-sidebar .share-block .ya-share2__item_service_vkontakte {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: .6;
      transition: all 0.2s ease-in-out 0s;
      width: 48px;
      height: 48px;
      border: 2px solid #AAAAC1;
      box-sizing: border-box; }
      .article-sidebar .share-block .ya-share2__item_service_vkontakte a {
        border: 0; }
        .article-sidebar .share-block .ya-share2__item_service_vkontakte a::after {
          content: '';
          background-image: url(../images/sprite.png);
          background-position: -51px -62px;
          width: 24px;
          height: 14px; }
      .article-sidebar .share-block .ya-share2__item_service_vkontakte:hover {
        opacity: 1; }
      .article-sidebar .share-block .ya-share2__item_service_vkontakte .ya-share2__badge {
        display: none; }
    .article-sidebar .share-block .ya-share2__item_service_facebook {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: .6;
      transition: all 0.2s ease-in-out 0s;
      width: 48px;
      height: 48px;
      border: 2px solid #AAAAC1;
      box-sizing: border-box; }
      .article-sidebar .share-block .ya-share2__item_service_facebook a {
        border: 0; }
        .article-sidebar .share-block .ya-share2__item_service_facebook a::after {
          content: '';
          background-image: url(../images/sprite.png);
          background-position: -91px 0px;
          width: 13px;
          height: 24px; }
      .article-sidebar .share-block .ya-share2__item_service_facebook:hover {
        opacity: 1; }
      .article-sidebar .share-block .ya-share2__item_service_facebook .ya-share2__badge {
        display: none; }
    .article-sidebar .share-block .ya-share2__item_service_odnoklassniki {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: .6;
      transition: all 0.2s ease-in-out 0s;
      width: 48px;
      height: 48px;
      border: 2px solid #AAAAC1;
      box-sizing: border-box; }
      .article-sidebar .share-block .ya-share2__item_service_odnoklassniki a {
        border: 0; }
        .article-sidebar .share-block .ya-share2__item_service_odnoklassniki a::after {
          content: '';
          background-image: url(../images/sprite.png);
          background-position: 0px -62px;
          width: 17px;
          height: 24px; }
      .article-sidebar .share-block .ya-share2__item_service_odnoklassniki:hover {
        opacity: 1; }
      .article-sidebar .share-block .ya-share2__item_service_odnoklassniki .ya-share2__badge {
        display: none; }
    .article-sidebar .last-news {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      /*.news-slider {
        @media (max-width: 1100px) {
          width: 100%;
          display: block;
          margin: 0 auto;
          //max-width: 647px;
          position: relative;
          margin-bottom: 30px;
        }
      }*/ }
      @media (max-width: 1100px) {
        .article-sidebar .last-news {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%; } }
      .article-sidebar .last-news-item {
        box-sizing: border-box;
        background-color: #fff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        width: 100%;
        transition: all 0.2s ease-in-out 0s;
        margin-bottom: 20px;
        display: block; }
        @media (max-width: 1100px) {
          .article-sidebar .last-news-item {
            width: 32%; } }
        @media (max-width: 768px) {
          .article-sidebar .last-news-item {
            width: 49%; } }
        @media (max-width: 500px) {
          .article-sidebar .last-news-item {
            width: 80%;
            margin: 0 auto 20px; } }
        @media (max-width: 400px) {
          .article-sidebar .last-news-item {
            width: 100%; } }
        .article-sidebar .last-news-item:hover {
          text-decoration: none;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25); }
        .article-sidebar .last-news-item h3 {
          padding: 40px 44px;
          font-weight: 800;
          font-size: 1.8rem;
          letter-spacing: .1rem;
          color: #474C66;
          line-height: 21px;
          overflow: hidden;
          text-overflow: ellipsis;
          box-sizing: border-box; }
        .article-sidebar .last-news-item--img {
          display: block;
          width: 100%;
          height: auto; }
          .article-sidebar .last-news-item--img img {
            display: block;
            width: 100%; }

@media (max-width: 400px) {
  .wrapper.cafe-page {
    padding: 0; } }

.cafe-page {
  height: auto;
  position: relative; }
  .cafe-page .about {
    margin-top: 0; }
    @media (max-width: 600px) {
      .cafe-page .about {
        margin-bottom: 20px; } }
    .cafe-page .about-info {
      padding-bottom: 20px; }
      @media (max-width: 1250px) {
        .cafe-page .about-info {
          width: 65%; } }
      @media (max-width: 1024px) {
        .cafe-page .about-info {
          flex-direction: column;
          padding-left: 0; } }
      @media (max-width: 768px) {
        .cafe-page .about-info {
          width: 100%; } }
      @media (max-width: 1024px) {
        .cafe-page .about-info-col {
          width: 100%; } }
      @media (max-width: 1250px) {
        .cafe-page .about-info-col:first-child {
          width: 36%; } }
      @media (max-width: 1024px) {
        .cafe-page .about-info-col:first-child {
          width: 100%;
          padding: 0 5%; } }
      @media (max-width: 1024px) {
        .cafe-page .about-info-col:first-child .about-info-img {
          display: none; } }
      .cafe-page .about-info-col:not(:first-child) {
        padding-bottom: 40px; }
        @media (max-width: 1700px) {
          .cafe-page .about-info-col:not(:first-child) {
            padding: 20px 5% 40px; } }
        @media (max-width: 1024px) {
          .cafe-page .about-info-col:not(:first-child) {
            padding: 5px 5% 40px; } }
      @media (max-width: 1700px) {
        .cafe-page .about-info-col .header-block {
          font-size: 3.8rem; } }
      @media (max-width: 1024px) {
        .cafe-page .about-info-col .header-block {
          margin-bottom: 0; } }
      @media (max-width: 768px) {
        .cafe-page .about-info-col .header-block {
          text-align: center;
          font-size: 3.2rem; } }
    @media (max-width: 1250px) {
      .cafe-page .about-img {
        width: 35%; } }
    @media (max-width: 991px) {
      .cafe-page .about-img {
        display: block; } }
    @media (max-width: 768px) {
      .cafe-page .about-img {
        display: none; } }
  .cafe-page .body-bg.two {
    display: block;
    position: absolute;
    right: 0;
    top: 25%; }
    @media (max-width: 1600px) {
      .cafe-page .body-bg.two {
        opacity: .7; } }
    @media (max-width: 1240px) {
      .cafe-page .body-bg.two {
        display: none; } }

.cafe {
  width: 100%;
  padding-bottom: 38%;
  min-height: 300px;
  position: relative;
  margin-bottom: 22px;
  margin-top: -40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* @media (max-width: 950px) {
     padding-bottom: 55%;
   }*/ }
  @media (max-width: 1600px) {
    .cafe {
      padding-bottom: 42%; } }
  @media (max-width: 1440px) {
    .cafe {
      padding-bottom: 37%; } }
  @media (max-width: 1250px) {
    .cafe {
      min-height: 500px; } }
  @media (max-width: 1024px) {
    .cafe {
      min-height: 550px; } }
  @media (max-width: 768px) {
    .cafe {
      min-height: 500px; } }
  @media (max-width: 480px) {
    .cafe {
      min-height: 500px; } }
  @media (max-width: 380px) {
    .cafe {
      padding-bottom: 115%; } }
  .cafe:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(10, 10, 11, 0.4) 0%, rgba(38, 41, 56, 0.85) 100%); }
  .cafe-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    /*@media (max-width: 1650px) {
      padding-top: 9%;
    }

    @media (max-width: 1440px) {
      padding-top: 4%;
    }

    @media (max-width: 1200px) {
      padding-top: 2%;
    }


    @media (max-width: 1024px) {
      padding-top: 5.2%;
    }

    @media (max-width: 950px) {
      padding-top: 4.2%;
    }*/ }
  .cafe-logo {
    height: 104px;
    width: auto;
    margin-bottom: 3rem; }
    @media (max-width: 1200px) {
      .cafe-logo {
        margin-bottom: 1.5rem; } }
    @media (max-width: 400px) {
      .cafe-logo {
        height: 85px; } }
    .cafe-logo img {
      display: block;
      height: 100%; }
  .cafe-schedule {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 1.8rem; }
    .cafe-schedule span {
      margin-bottom: 0.3rem; }
    .cafe-schedule__title {
      font-size: 2rem;
      font-weight: bold; }
    @media (max-width: 1440px) {
      .cafe-schedule {
        font-size: 1.6rem;
        margin-bottom: 1.5rem; } }
  .cafe-btn {
    margin-bottom: 1.5%; }
    @media (max-width: 400px) {
      .cafe-btn {
        margin-bottom: 4%; } }
    .cafe-btn .btn {
      border-color: #fff; }
      .cafe-btn .btn:hover {
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.7), 0 0 5px rgba(255, 255, 255, 0.7) inset; }
  .cafe-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3%; }
    @media (max-width: 1440px) {
      .cafe-social {
        margin-bottom: 2%; } }
    @media (max-width: 400px) {
      .cafe-social {
        margin-bottom: 5%; } }
    .cafe-social .social-btn {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 7px;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 480px) {
        .cafe-social .social-btn {
          width: 30px;
          height: 30px; } }
      .cafe-social .social-btn img {
        display: block;
        max-width: 100%; }
      .cafe-social .social-btn:hover {
        box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.5); }
  .cafe-contacts {
    display: flex;
    align-items: center;
    padding: 0 10px; }
    @media (max-width: 1024px) {
      .cafe-contacts {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; } }
    .cafe-contacts a, .cafe-contacts-phones {
      position: relative;
      display: inline-block;
      margin: 0 50px 0 50px;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: normal;
      color: #fff; }
      @media (max-width: 1100px) {
        .cafe-contacts a, .cafe-contacts-phones {
          margin: 0 10px 5px; } }
      @media (max-width: 1000px) {
        .cafe-contacts a, .cafe-contacts-phones {
          margin: 0 0 10px; } }
      @media (max-width: 400px) {
        .cafe-contacts a, .cafe-contacts-phones {
          font-size: 1.3rem; } }
      .cafe-contacts a:before, .cafe-contacts-phones:before {
        position: relative;
        display: inline-block;
        content: '';
        margin-right: 10px;
        vertical-align: middle; }
      .cafe-contacts a.phone:before, .cafe-contacts-phones.phone:before {
        background-image: url(../images/sprite.png);
        background-position: -37px 0px;
        width: 22px;
        height: 22px; }
      .cafe-contacts a.mail:before, .cafe-contacts-phones.mail:before {
        background-image: url(../images/sprite.png);
        background-position: -22px -62px;
        width: 24px;
        height: 16px; }
      .cafe-contacts a.address:before, .cafe-contacts-phones.address:before {
        background-image: url(../images/sprite.png);
        background-position: -64px -25px;
        width: 18px;
        height: 24px; }
    .cafe-contacts-phones {
      display: inline-flex;
      flex-direction: column;
      margin: 0 !important; }

.cafe-nav {
  border-top: 1px solid rgba(170, 170, 193, 0.3);
  border-bottom: 1px solid rgba(170, 170, 193, 0.3);
  margin-bottom: 20px; }
  .cafe-nav .main-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 380px) {
      .cafe-nav .main-menu {
        flex-direction: column; } }
    @media (max-width: 380px) {
      .cafe-nav .main-menu--item {
        width: 100%; } }
    .cafe-nav .main-menu--item a {
      display: block;
      position: relative;
      min-height: 54px;
      padding: 0 45px;
      line-height: 54px;
      font-weight: 800;
      font-size: 1.2rem;
      letter-spacing: .2rem;
      text-transform: uppercase;
      color: #474C66;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 1440px) {
        .cafe-nav .main-menu--item a {
          padding: 0 15px;
          line-height: 40px;
          min-height: auto; } }
      @media (max-width: 560px) {
        .cafe-nav .main-menu--item a {
          letter-spacing: normal; } }
      @media (max-width: 480px) {
        .cafe-nav .main-menu--item a {
          padding: 0 7px; } }
      @media (max-width: 380px) {
        .cafe-nav .main-menu--item a {
          padding: 0 20px; } }
      .cafe-nav .main-menu--item a:after {
        content: '/';
        position: absolute;
        color: rgba(170, 170, 193, 0.3);
        right: 0;
        font-size: 1.8rem;
        font-weight: 300; }
        @media (max-width: 991px) {
          .cafe-nav .main-menu--item a:after {
            display: none; } }
      .cafe-nav .main-menu--item a.active {
        color: #fff;
        background-color: #303239; }
        .cafe-nav .main-menu--item a.active:after {
          display: none; }
      .cafe-nav .main-menu--item a:hover {
        text-decoration: none;
        color: #fff;
        background-color: #aeb0ba; }
        .cafe-nav .main-menu--item a:hover:after {
          display: none; }

.menu {
  width: 100%;
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden; }
  .menu .header-block {
    text-align: center;
    margin-bottom: 20px;
    width: 100%; }
    @media (max-width: 768px) {
      .menu .header-block {
        text-align: center;
        font-size: 3.2rem; } }
  .menu-tabs {
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 20px 20px;
    border-bottom: 1px solid rgba(170, 170, 193, 0.3);
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.2s; }
    .menu-tabs.fixed {
      position: fixed;
      top: 54px;
      left: 0;
      right: 0;
      z-index: 1000;
      background-color: #fff;
      border-top: 1px solid rgba(170, 170, 193, 0.3); }

@media screen and (max-width: 1440px) {
  .menu-tabs.fixed {
    top: 40px; } }
    .menu-tabs.hidden {
      opacity: 0;
      pointer-events: none; }
    .menu-tabs li {
      position: relative;
      max-width: 300px;
      cursor: pointer;
      height: 40px;
      padding: 0 18px;
      margin: 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 1rem;
      line-height: normal;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out 0s; }
      .menu-tabs li a {
        text-decoration: none;
        color: #303239;
        transition: all 0.2s ease-in-out 0s; }
      .menu-tabs li:after {
        content: '/';
        position: absolute;
        color: rgba(170, 170, 193, 0.3);
        right: 0;
        font-size: 1.8rem;
        font-weight: 300; }
        @media (max-width: 991px) {
          .menu-tabs li:after {
            display: none; } }
      .menu-tabs li.active {
        background: #303239;
        color: #fff; }
        .menu-tabs li.active a {
          color: #fff; }
        .menu-tabs li.active:after {
          display: none; }
      .menu-tabs li:hover {
        text-decoration: none;
        color: #fff;
        background-color: #aeb0ba; }
        .menu-tabs li:hover a {
          color: #ffffff; }
        .menu-tabs li:hover:after {
          display: none; }
  .menu-container {
    width: 100%;
    background-color: #fff; }
    .menu-container .tab-drawer-heading {
      display: none;
      padding: 10px;
      transition: all ease-in .3s;
      font-weight: 800;
      color: #474C66;
      border-bottom: 1px solid #474C66;
      position: relative;
      background-color: white; }
      .menu-container .tab-drawer-heading:before {
        content: "";
        display: inline-block;
        position: absolute;
        height: 9px;
        width: 9px;
        border-left: 2px solid #474C66;
        border-bottom: 2px solid #474C66;
        right: 20px;
        top: calc(50% - 3px);
        transform: rotate(-45deg);
        transition: all ease-in .3s; }
      @media (max-width: 1200px) {
        .menu-container .tab-drawer-heading {
          display: block;
          cursor: pointer; } }
    .menu-container .d-active {
      background-color: #474C66;
      color: #fff;
      transition: all ease-in .3s;
      position: relative; }
      .menu-container .d-active:before {
        content: "";
        display: inline-block;
        position: absolute;
        height: 9px;
        width: 9px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        right: 20px;
        top: calc(50% - 3px);
        transform: rotate(135deg);
        transition: all ease-in .3s; }
    .menu-container .tab-content {
      padding: 36px 40px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: auto;
      opacity: 1;
      /*   max-height: 100%;
         height: auto;*/
      visibility: visible; }
      .menu-container .tab-content--hidden {
        padding: 0 !important;
        margin: 0 !important;
        height: 0;
        visibility: hidden; }
      @media (max-width: 1800px) {
        .menu-container .tab-content {
          padding: 36px 10px 60px; } }
      @media (max-width: 1200px) {
        .menu-container .tab-content {
          padding: 0px 10px; } }
      @media (max-width: 768px) {
        .menu-container .tab-content {
          padding-bottom: 20px; } }
      .menu-container .tab-content--menu {
        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 55px;
        flex-wrap: wrap; }
        .menu-container .tab-content--menu-title {
          margin-left: 10px;
          margin-bottom: 0;
          margin-top: 10px;
          display: block;
          font-weight: 800;
          font-size: 1.8rem;
          width: 100%;
          line-height: normal;
          letter-spacing: 0.1em;
          color: #474C66; }
        .menu-container .tab-content--menu_col {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: flex-start;
          width: 100%;
          flex-wrap: wrap;
          text-align: left; }
          .menu-container .tab-content--menu_col span {
            margin-left: 10px;
            margin-bottom: 0;
            margin-top: 10px;
            display: block;
            font-weight: 800;
            font-size: 1.8rem;
            width: 100%;
            line-height: normal;
            letter-spacing: 0.1em;
            color: #474C66; }
            @media (max-width: 1440px) {
              .menu-container .tab-content--menu_col span {
                margin-left: 20px; } }
          .menu-container .tab-content--menu_col .item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: calc(100% / 4 - 20px);
            border-right: 1px solid rgba(170, 170, 193, 0.3);
            padding-right: 10px;
            margin: 10px; }
            @media (max-width: 1440px) {
              .menu-container .tab-content--menu_col .item {
                width: calc((100% / 3) - 40px);
                margin: 0 20px 20px; } }
            @media (max-width: 1200px) {
              .menu-container .tab-content--menu_col .item {
                border-right: none; } }
            @media (max-width: 1024px) {
              .menu-container .tab-content--menu_col .item {
                width: calc((100% / 2) - 40px);
                margin: 0 20px 20px; } }
            @media (max-width: 768px) {
              .menu-container .tab-content--menu_col .item {
                width: 100%; } }
            .menu-container .tab-content--menu_col .item-info {
              width: 100%; }
              .menu-container .tab-content--menu_col .item-info-name {
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 2.5rem;
                color: #474C66; }
              .menu-container .tab-content--menu_col .item-info-disc {
                font-weight: 400;
                font-size: 1.3rem;
                line-height: 2rem;
                color: #747495; }
            .menu-container .tab-content--menu_col .item-price {
              max-width: 205px;
              width: 100%; }
              .menu-container .tab-content--menu_col .item-price p {
                display: flex;
                justify-content: flex-end;
                font-weight: 800;
                font-size: 1.2rem;
                letter-spacing: 0.2em;
                color: #474C66;
                line-height: 2.5rem;
                text-align: center; }
                @media (max-width: 768px) {
                  .menu-container .tab-content--menu_col .item-price p {
                    letter-spacing: normal; } }
                .menu-container .tab-content--menu_col .item-price p span {
                  width: 50%;
                  display: block;
                  white-space: nowrap;
                  font-size: 1.6rem; }
                  .menu-container .tab-content--menu_col .item-price p span:not(.price) {
                    font-weight: 500;
                    letter-spacing: 0; }
                    @media (max-width: 768px) {
                      .menu-container .tab-content--menu_col .item-price p span:not(.price) {
                        margin-right: 4px; } }

.inst-slider-section {
  margin-bottom: 65px; }
  .inst-slider-section .header-block {
    text-align: center;
    margin-bottom: 65px; }
  @media (max-width: 1500px) {
    .inst-slider-section .sliders-container .gallery-slider-container {
      padding: 0 100px; } }
  .inst-slider-section .sliders-container .gallery-slider-container .prev {
    background-image: url(../images/slick-prev.png); }
  .inst-slider-section .sliders-container .gallery-slider-container .next {
    background-image: url(../images/slick-next.png); }
  .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .slick-slide {
    height: 100%;
    max-height: 500px; }
    .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .slick-slide > div {
      height: 100%;
      text-align: center; }
  .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item {
    height: 100%;
    border-radius: 0; }
    @media (max-width: 1500px) {
      .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item {
        max-width: 350px; } }
    @media (max-width: 480px) {
      .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item {
        max-width: 220px; } }
    .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item img {
      width: auto;
      height: 100%;
      max-height: 470px;
      min-height: 470px; }
      @media (max-width: 1800px) {
        .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item img {
          max-height: 435px;
          min-height: 435px; } }
      @media (max-width: 1600px) {
        .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item img {
          max-height: 400px;
          min-height: 400px; } }
      @media (max-width: 1500px) {
        .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item img {
          max-height: 350px;
          min-height: 350px; } }
      @media (max-width: 640px) {
        .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item img {
          max-height: 300px;
          min-height: 300px; } }
      @media (max-width: 480px) {
        .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider .gallery-slider__item img {
          max-height: 220px;
          min-height: 220px; } }

.sliders-container {
  position: relative;
  width: 100%;
  max-width: 1830px;
  margin: auto; }
  .sliders-container .gallery-slider-container {
    width: 100%;
    padding: 0 155px;
    position: relative; }
    .sliders-container .gallery-slider-container .prev, .sliders-container .gallery-slider-container .next {
      position: absolute;
      width: 48px;
      height: 97px;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
      background: center center no-repeat #343745;
      border: 8px solid #303239;
      width: 60px;
      height: 60px; }
    .sliders-container .gallery-slider-container .prev {
      background-image: url("../images/arrow-icon.png");
      pointer-events: auto;
      background-repeat: no-repeat;
      left: 0;
      z-index: 100;
      cursor: pointer; }
    .sliders-container .gallery-slider-container .next {
      background-image: url("../images/arrow-icon.png");
      pointer-events: auto;
      background-repeat: no-repeat;
      right: 0;
      z-index: 100;
      cursor: pointer; }
    .sliders-container .gallery-slider-container .gallery-slider {
      width: 100%;
      overflow: hidden;
      position: relative; }
      .sliders-container .gallery-slider-container .gallery-slider__item {
        /*  width: 70% !important;
          display: block !important;*/
        overflow: hidden;
        display: block;
        border-radius: 10px;
        margin: 0 auto;
        padding: 0 10px;
        /*   @media (max-width: 1440px) {
             max-height: 800px;
           }

           @media (max-width: 768px) {
             width: 100% !important;
           }*/ }
        .sliders-container .gallery-slider-container .gallery-slider__item img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 auto; }
        .sliders-container .gallery-slider-container .gallery-slider__item iframe {
          width: 100%;
          height: 100%;
          z-index: 3; }
  .sliders-container .gallery-slider-nav {
    margin-top: 30px;
    padding: 0 155px;
    overflow: hidden; }
    @media (max-width: 900px) {
      .sliders-container .gallery-slider-nav {
        display: none; } }
    .sliders-container .gallery-slider-nav .slick-list {
      margin-right: -1.5rem; }
    .sliders-container .gallery-slider-nav__item {
      border-radius: 10px;
      overflow: hidden;
      display: block;
      width: 100%;
      max-height: 100px; }
      .sliders-container .gallery-slider-nav__item img, .sliders-container .gallery-slider-nav__item iframe {
        display: block;
        width: 100%;
        height: auto; }
    .sliders-container .gallery-slider-nav .slick-slide {
      margin-right: 1.5rem; }
      .sliders-container .gallery-slider-nav .slick-slide img {
        width: 100%;
        height: auto;
        border-radius: 5px; }
      .sliders-container .gallery-slider-nav .slick-slide.slick-current {
        position: relative;
        padding-bottom: 10px; }
        .sliders-container .gallery-slider-nav .slick-slide.slick-current::after {
          content: '';
          width: 100%;
          height: 2px;
          background: #41afd3;
          position: absolute;
          bottom: 0;
          left: 0; }

@media (max-width: 1200px) {
  .inst-slider-section .sliders-container .gallery-slider-container {
    padding: 0 45px; }
    .inst-slider-section .sliders-container .gallery-slider-container .prev, .inst-slider-section .sliders-container .gallery-slider-container .next {
      width: 40px;
      height: 40px;
      background-size: contain; }
  .inst-slider-section .sliders-container .gallery-slider-nav {
    padding: 0 45px; } }

@media (max-width: 820px) {
  .inst-slider-section .sliders-container .gallery-slider-nav .slick-list {
    margin-right: -10px; }
  .inst-slider-section .sliders-container .gallery-slider-nav .slick-slide {
    margin-right: 10px; } }

@media (max-width: 640px) {
  .inst-slider-section .sliders-container .gallery-slider-container {
    padding: 0 5px; }
    .inst-slider-section .sliders-container .gallery-slider-container .gallery-slider__item {
      padding-left: 0; }
    .inst-slider-section .sliders-container .gallery-slider-container .prev {
      left: 15px;
      width: 30px;
      height: 30px; }
    .inst-slider-section .sliders-container .gallery-slider-container .next {
      right: 15px;
      width: 30px;
      height: 30px; }
  .inst-slider-section .sliders-container .gallery-slider-nav {
    padding: 0 5px; } }

.events {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 1200px) {
    .events {
      flex-direction: column; } }
  .events-page {
    display: flex;
    flex-basis: 74%;
    justify-content: space-between;
    flex-wrap: wrap;
    background: none;
    box-sizing: border-box;
    text-align: left;
    margin-bottom: 60px;
    font-weight: 400;
    font-size: 1.5rem;
    color: #474C66;
    position: relative; }
    @media (max-width: 1440px) {
      .events-page {
        flex-basis: 80%; } }
    @media (max-width: 1200px) {
      .events-page {
        flex-basis: 100%; } }
    @media (max-width: 990px) {
      .events-page {
        justify-content: center;
        width: 100%; } }
    .events-page__item {
      width: 49%;
      max-height: 445px;
      margin-bottom: 20px;
      display: inline-flex !important;
      align-items: stretch;
      justify-content: flex-start;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15); }
      @media (max-width: 1200px) {
        .events-page__item {
          height: auto; } }
      @media (max-width: 990px) {
        .events-page__item {
          width: 70%; } }
      @media (max-width: 768px) {
        .events-page__item {
          width: 100%; } }
      .events-page__item .img {
        width: 43%;
        position: relative;
        background-position: center center;
        background-size: cover; }
        @media (max-width: 1440px) {
          .events-page__item .img {
            width: 40%; } }
        @media (max-width: 450px) {
          .events-page__item .img {
            display: none; } }
        .events-page__item .img-logo {
          position: absolute;
          width: 30%;
          top: 45px;
          left: 30px; }
          .events-page__item .img-logo img {
            width: 100%;
            display: block; }
      .events-page__item .info {
        width: 57%;
        padding: 20px;
        background-color: #fff; }
        @media (max-width: 1440px) {
          .events-page__item .info {
            width: 60%; } }
        @media (max-width: 450px) {
          .events-page__item .info {
            width: 100%; } }
        .events-page__item .info-top-description {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          .events-page__item .info-top-description__date {
            display: flex;
            align-items: center; }
            .events-page__item .info-top-description__date .info-date-img {
              width: 13px;
              height: 10px;
              margin-right: 10px; }
              .events-page__item .info-top-description__date .info-date-img img {
                width: 100%;
                height: auto;
                display: block; }
          .events-page__item .info-top-description__section .title {
            font-size: 1rem;
            letter-spacing: .2em;
            color: #aaaac1;
            text-transform: uppercase;
            position: relative; }
            .events-page__item .info-top-description__section .title:hover {
              text-decoration: none; }
            .events-page__item .info-top-description__section .title:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background-color: #aaaac1;
              bottom: -6px;
              left: 0;
              transition: all 0.2s ease-in-out 0s; }
        .events-page__item .info-body {
          width: 100%;
          margin-top: 20px; }
          .events-page__item .info-body .title-link {
            display: block;
            color: #474C66; }
          .events-page__item .info-body h3 {
            font-weight: 800;
            font-size: 1.8rem;
            letter-spacing: .1rem;
            color: #474C66;
            line-height: 21px;
            max-height: 42px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px; }
          .events-page__item .info-body p {
            font-weight: 400;
            font-size: 1.5rem;
            text-overflow: ellipsis; }
            @media (max-width: 1480px) {
              .events-page__item .info-body p {
                font-size: 1.4rem; } }
        .events-page__item .info-more {
          display: flex;
          align-items: center;
          margin-top: 40px;
          color: #474C66;
          letter-spacing: .2em;
          text-transform: uppercase;
          font-size: 1.2rem;
          font-weight: 800; }
          @media (max-width: 1440px) {
            .events-page__item .info-more {
              margin-top: 10px;
              height: auto; } }
          .events-page__item .info-more:after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            margin-left: 8px;
            width: 14px;
            height: 11px;
            background-image: url("../images/news-arrow.png");
            background-size: contain;
            background-repeat: no-repeat; }
    .events-page__navigation {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px; }
      .events-page__navigation .dots {
        display: flex;
        flex-direction: row; }
        .events-page__navigation .dots li a {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #303239;
          font-weight: 800;
          width: 56px;
          height: 56px;
          transition: all 0.3s ease-in;
          border: 2px solid transparent; }
          @media (max-width: 540px) {
            .events-page__navigation .dots li a {
              width: 30px;
              height: 30px; } }
          .events-page__navigation .dots li a:hover {
            text-decoration: none;
            background-color: #aeb0ba; }
        .events-page__navigation .dots li .active {
          background: #303239;
          color: white; }
  .events-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: none; }
    .events-preloader::after {
      content: '';
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../images/spinning-circles.svg);
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat; }
  .events-sidebar {
    flex-basis: 24%;
    margin-bottom: 40px; }
    @media (max-width: 1440px) {
      .events-sidebar {
        flex-basis: 19%; } }
    @media (max-width: 1200px) {
      .events-sidebar {
        flex-basis: 100%;
        width: 100%; } }
    @media (max-width: 450px) {
      .events-sidebar {
        margin-bottom: 0; } }
    .events-sidebar .title {
      position: relative;
      text-transform: uppercase;
      color: #747495; }
      .events-sidebar .title font {
        width: 800;
        size: 1.3rem; }
      .events-sidebar .title:hover {
        text-decoration: none; }
    .events-sidebar .filter-news-block {
      padding-left: 40px; }
      .events-sidebar .filter-news-block .title {
        width: 100%; }
        @media (max-width: 580px) {
          .events-sidebar .filter-news-block .title {
            text-align: center; } }
      @media (max-width: 1200px) {
        .events-sidebar .filter-news-block {
          padding-left: 0; } }
      @media (max-width: 580px) {
        .events-sidebar .filter-news-block {
          display: flex;
          justify-content: center;
          flex-wrap: wrap; } }
      .events-sidebar .filter-news-block__links {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-top: 24px; }
        @media (max-width: 1200px) {
          .events-sidebar .filter-news-block__links {
            flex-direction: row;
            margin-top: 10px; } }
        @media (max-width: 768px) {
          .events-sidebar .filter-news-block__links {
            flex-wrap: wrap; } }
        @media (max-width: 500px) {
          .events-sidebar .filter-news-block__links {
            width: 100%;
            justify-content: space-between; } }
        .events-sidebar .filter-news-block__links li {
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
          margin-top: 20px; }
          @media (max-width: 1200px) {
            .events-sidebar .filter-news-block__links li {
              width: auto;
              margin-right: 20px;
              margin-top: 0; } }
          @media (max-width: 450px) {
            .events-sidebar .filter-news-block__links li {
              margin-right: 10px; }
              .events-sidebar .filter-news-block__links li:first-child {
                margin-bottom: 10px; } }
          .events-sidebar .filter-news-block__links li:first-child {
            margin-top: 0; }
          .events-sidebar .filter-news-block__links li .filter-label {
            position: relative;
            padding-left: 40px;
            line-height: 24px;
            letter-spacing: .2em;
            text-transform: uppercase;
            color: #474C66;
            font-weight: 800;
            font-size: 1.2rem;
            cursor: pointer; }
            @media (max-width: 450px) {
              .events-sidebar .filter-news-block__links li .filter-label {
                padding-left: 30px;
                font-size: 1.1rem; } }
            .events-sidebar .filter-news-block__links li .filter-label:before {
              content: "";
              display: inline-block;
              height: 24px;
              width: 24px;
              top: -2px;
              left: 0;
              border: 2px solid black;
              border-radius: 50%; }
              @media (max-width: 450px) {
                .events-sidebar .filter-news-block__links li .filter-label:before {
                  height: 20px;
                  width: 20px; } }
            .events-sidebar .filter-news-block__links li .filter-label:after {
              content: "";
              display: inline-block;
              height: 6px;
              width: 9px;
              border-left: 2px solid white;
              border-bottom: 2px solid white;
              left: 9px;
              top: calc(50% - 5px);
              transform: rotate(-45deg); }
              @media (max-width: 450px) {
                .events-sidebar .filter-news-block__links li .filter-label:after {
                  top: calc(50% - 7px);
                  left: 7px; } }
            .events-sidebar .filter-news-block__links li .filter-label:before, .events-sidebar .filter-news-block__links li .filter-label:after {
              position: absolute; }
          .events-sidebar .filter-news-block__links li .filter-checkbox {
            opacity: 0;
            position: absolute;
            z-index: 111;
            width: 30px;
            height: 30px; }
            .events-sidebar .filter-news-block__links li .filter-checkbox + .filter-label:after {
              content: none; }
            .events-sidebar .filter-news-block__links li .filter-checkbox:checked + .filter-label:after {
              content: ''; }
            .events-sidebar .filter-news-block__links li .filter-checkbox:checked + .filter-label:before {
              background-color: black; }
    .events-sidebar .filter-restorans-block {
      padding: 40px;
      margin-top: 45px;
      background-color: white;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15); }
      .events-sidebar .filter-restorans-block-control {
        width: 100%;
        margin-top: 20px;
        text-align: left;
        padding: 8px 24px 8px 8px;
        background: #474C66;
        border: 0;
        color: #ffffff;
        font-weight: 600;
        position: relative;
        display: none; }
        @media (max-width: 820px) {
          .events-sidebar .filter-restorans-block-control {
            display: block; } }
        .events-sidebar .filter-restorans-block-control.active::after {
          transform: translateY(-5px) rotate(225deg); }
        .events-sidebar .filter-restorans-block-control::after {
          content: '';
          width: 12px;
          height: 12px;
          border-bottom: 2px solid #ffffff;
          border-right: 2px solid #ffffff;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-10px) rotate(45deg);
          transition: .2s; }
      @media (max-width: 1200px) {
        .events-sidebar .filter-restorans-block .title {
          display: none; } }
      @media (max-width: 1200px) {
        .events-sidebar .filter-restorans-block {
          margin-top: 0;
          padding: 0;
          background: none;
          box-shadow: none; } }
      .events-sidebar .filter-restorans-block__links {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        height: auto;
        margin-top: 20px; }
        @media (max-width: 1200px) {
          .events-sidebar .filter-restorans-block__links {
            flex-direction: row;
            margin-top: 0;
            flex-wrap: wrap;
            padding: 10px 0; } }
        @media (max-width: 820px) {
          .events-sidebar .filter-restorans-block__links {
            height: 0;
            overflow: hidden;
            transition: .4s; } }
        .events-sidebar .filter-restorans-block__links li {
          width: auto;
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: 1px solid #aaaac1; }
          .events-sidebar .filter-restorans-block__links li:last-child {
            border: none; }
          @media (max-width: 1200px) {
            .events-sidebar .filter-restorans-block__links li {
              width: auto;
              margin-right: 20px; }
              .events-sidebar .filter-restorans-block__links li:last-child {
                border-bottom: 1px solid #aaaac1; } }
          .events-sidebar .filter-restorans-block__links li a {
            line-height: 24px;
            letter-spacing: .2em;
            text-transform: uppercase;
            color: #474C66;
            font-weight: 800;
            font-size: 1.2rem;
            width: 100%;
            height: 100%;
            padding: 15px 0;
            transition: all 0.2s ease-in; }
            @media (max-width: 1200px) {
              .events-sidebar .filter-restorans-block__links li a {
                padding: 10px 5px; } }
            @media (max-width: 450px) {
              .events-sidebar .filter-restorans-block__links li a {
                font-size: 1.1rem; } }
            .events-sidebar .filter-restorans-block__links li a:hover {
              color: #fff;
              background-color: #303239;
              text-decoration: none;
              padding-left: 20px; }
              @media (max-width: 1200px) {
                .events-sidebar .filter-restorans-block__links li a:hover {
                  padding: 10px 5px; } }
            .events-sidebar .filter-restorans-block__links li a.active {
              color: #fff;
              background-color: #303239;
              text-decoration: none;
              padding-left: 20px; }
              @media (max-width: 1200px) {
                .events-sidebar .filter-restorans-block__links li a.active {
                  padding: 10px 5px; } }

.page-404 {
  background-image: url("../images/backgroung-404.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  width: 100%;
  margin-top: -20px;
  display: flex;
  justify-content: center; }
  .page-404 .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin-top: 50px;
    margin-bottom: 150px; }
    @media (max-width: 1280px) {
      .page-404 .content {
        background: rgba(255, 255, 255, 0.6);
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        margin-bottom: 50px; } }
    @media (max-width: 768px) {
      .page-404 .content {
        padding: 15px; } }
  .page-404 .logo-container {
    display: flex;
    justify-content: center;
    padding-top: 120px; }
    @media (max-width: 1280px) {
      .page-404 .logo-container {
        transform: scale(0.9); } }
    @media (max-width: 768px) {
      .page-404 .logo-container {
        transform: scale(0.8);
        padding-top: 60px; } }
    @media (max-width: 580px) {
      .page-404 .logo-container {
        padding-top: 0; } }
    .page-404 .logo-container img {
      width: 100%;
      height: 100%;
      display: block; }
  .page-404 .page-404-title {
    text-align: center;
    font-size: 4.8rem;
    font-weight: 100;
    color: #303239; }
    @media (max-width: 768px) {
      .page-404 .page-404-title {
        font-size: 3.6rem; } }
    @media (max-width: 480px) {
      .page-404 .page-404-title {
        font-size: 2.6rem; } }
  .page-404 .text-description {
    width: 60%;
    padding-top: 14px;
    display: flex;
    justify-content: center; }
    @media (max-width: 1280px) {
      .page-404 .text-description {
        width: 70%; } }
    @media (max-width: 768px) {
      .page-404 .text-description {
        width: 100%; } }
    .page-404 .text-description p {
      font-family: "M PLUS 1p", sans-serif;
      font-size: 1.5rem;
      color: #474C66;
      text-align: center; }

.contacts-page {
  margin-bottom: 2rem; }
  .contacts-page .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }

@media screen and (max-width: 1024px) {
  .contacts-page .wrapper {
    flex-wrap: wrap; } }
  .contacts-page .feedback-col {
    width: calc(25% - 20px); }

@media screen and (max-width: 1250px) {
  .contacts-page .feedback-col {
    width: calc(35% - 20px); } }

@media screen and (max-width: 1024px) {
  .contacts-page .feedback-col {
    width: 100%;
    order: 2;
    margin-top: 1rem; } }
    .contacts-page .feedback-col .form-container {
      width: 100%;
      background: #ffffff;
      padding: 20px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15); }

@media screen and (max-width: 480px) {
  .contacts-page .feedback-col .form-container {
    padding: 20px 10px; } }

@media screen and (max-width: 1024px) {
  .contacts-page .feedback-col .form-container .modal__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media screen and (max-width: 768px) {
  .contacts-page .feedback-col .form-container .modal__form {
    margin-top: 25px; } }
      .contacts-page .feedback-col .form-container .on.select__gap:after {
        top: calc(50% - 4px); }
      .contacts-page .feedback-col .form-container .select__gap {
        font-size: 15px;
        line-height: 23px; }
        @media (max-width: 1400px) {
          .contacts-page .feedback-col .form-container .select__gap {
            font-size: 14px; } }
        .contacts-page .feedback-col .form-container .select__gap:after {
          top: calc(50% - 8px); }
        @media (max-width: 900px) {
          .contacts-page .feedback-col .form-container .select__gap {
            padding: 5px 10px; } }
      .contacts-page .feedback-col .form-container .select__item {
        font-size: 15px; }
        @media (max-width: 1400px) {
          .contacts-page .feedback-col .form-container .select__item {
            font-size: 14px; } }
        .contacts-page .feedback-col .form-container .select__item span {
          padding: 10px 22px;
          line-height: 23px; }
          @media (max-width: 900px) {
            .contacts-page .feedback-col .form-container .select__item span {
              padding: 5px 10px; } }

@media screen and (max-width: 1024px) {
  .contacts-page .feedback-col .form-container input {
    width: 32%;
    margin-top: 0;
    display: flex;
    align-items: stretch; } }

@media screen and (max-width: 900px) {
  .contacts-page .feedback-col .form-container input {
    padding: 5px 10px; } }

@media screen and (max-width: 680px) {
  .contacts-page .feedback-col .form-container input {
    width: 49%; }
    .contacts-page .feedback-col .form-container input[type="tel"] {
      margin-top: 10px; } }

@media screen and (max-width: 480px) {
  .contacts-page .feedback-col .form-container input {
    width: 100%;
    margin-top: 1rem; } }

@media screen and (max-width: 1024px) {
  .contacts-page .feedback-col .form-container .modal__terms {
    order: 5; } }
      @media (max-width: 480px) {
        .contacts-page .feedback-col .form-container .modal__terms label {
          line-height: normal; } }
      .contacts-page .feedback-col .form-container textarea {
        resize: none;
        font-size: 14px; }

@media screen and (max-width: 1024px) {
  .contacts-page .feedback-col .form-container textarea {
    margin-bottom: 10px; } }

@media screen and (max-width: 900px) {
  .contacts-page .feedback-col .form-container textarea {
    padding: 5px 10px; } }

@media screen and (max-width: 680px) {
  .contacts-page .feedback-col .form-container textarea {
    margin-bottom: 0; } }

@media screen and (max-width: 1000px) {
  .contacts-page .feedback-col .form-container {
    line-height: normal; } }

@media screen and (max-width: 600px) {
  .contacts-page .feedback-col .form-container {
    font-size: 1.2rem;
    line-height: 2rem; } }
      .contacts-page .feedback-col .form-container .btn {
        all: unset;
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        margin-top: 23px;
        padding: 5px 8px;
        text-align: center; }

@media screen and (max-width: 1024px) {
  .contacts-page .feedback-col .form-container .btn {
    width: 32%;
    margin-top: 0; } }

@media screen and (max-width: 680px) {
  .contacts-page .feedback-col .form-container .btn {
    width: 49%;
    margin-top: 1rem; } }

@media screen and (max-width: 480px) {
  .contacts-page .feedback-col .form-container .btn {
    width: 100%; } }

@media screen and (max-width: 900px) {
  .contacts-page .feedback-col .form-container .btn span {
    font-size: 1.2rem;
    letter-spacing: 1px;
    padding: 0; } }
  .contacts-page .socials {
    padding: 40px; }
    .contacts-page .socials .modal__header {
      margin-bottom: 5.8rem;
      width: 100%; }
    .contacts-page .socials .icons {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap; }
    .contacts-page .socials a {
      width: 48px;
      height: 48px;
      border: 2px solid #AAAAC1;
      box-sizing: border-box;
      transition: all 0.2s ease-in-out 0s;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px; }
      .contacts-page .socials a:hover {
        border-color: #2fa5cb; }
        .contacts-page .socials a:hover path {
          fill: #2fa5cb; }
      .contacts-page .socials a:active {
        border-color: #2a94b7; }
        .contacts-page .socials a:active path {
          fill: #2a94b7; }
      .contacts-page .socials a:last-child {
        margin-right: 0; }
      .contacts-page .socials a path {
        transition: all 0.2s ease-in-out 0s; }

.contacts-col {
  width: 50%; }

@media screen and (max-width: 1250px) {
  .contacts-col {
    width: 65%; } }

@media screen and (max-width: 1024px) {
  .contacts-col {
    width: 100%; } }
  .contacts-col .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 7rem;
    padding-top: 5rem; }

@media screen and (max-width: 1250px) {
  .contacts-col .top {
    padding-top: 0; } }

@media screen and (max-width: 680px) {
  .contacts-col .top {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem; } }
    .contacts-col .top .phones {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start; }
      @media (max-width: 680px) {
        .contacts-col .top .phones {
          margin-top: 2rem; } }
      .contacts-col .top .phones .icon {
        width: 22px;
        height: 22px;
        background-image: url(../images/sprite.png);
        background-position: -37px 0px;
        width: 22px;
        height: 22px;
        margin-right: 20px;
        margin-top: 10px; }

@media screen and (max-width: 1440px) {
  .contacts-col .top .phones .icon {
    margin-right: 10px; } }
      .contacts-col .top .phones .text a {
        display: block;
        color: #474C66;
        font-size: 1.8rem;
        font-weight: 800;
        margin-bottom: 3px;
        letter-spacing: 0.1em;
        transition: all 0.2s ease-in-out 0s; }
        .contacts-col .top .phones .text a:hover {
          color: #2fa5cb;
          text-decoration: none; }
        .contacts-col .top .phones .text a:active {
          color: #2a94b7; }
        .contacts-col .top .phones .text a:last-child {
          margin-bottom: 0; }

@media screen and (max-width: 1800px) {
  .contacts-col .top .phones .text a {
    font-size: 1.4rem; } }

@media screen and (max-width: 680px) {
  .contacts-col .top .phones .text a {
    font-size: 1.8rem;
    line-height: normal; } }
    .contacts-col .top .email {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start; }
      @media (max-width: 680px) {
        .contacts-col .top .email {
          margin-top: 2rem; } }
      .contacts-col .top .email .icon {
        width: 24px;
        height: 24px;
        margin-right: 20px;
        margin-top: 10px;
        background-image: url(../images/sprite.png);
        background-position: -22px -62px;
        width: 24px;
        height: 16px; }

@media screen and (max-width: 1440px) {
  .contacts-col .top .email .icon {
    margin-right: 10px; } }
      .contacts-col .top .email .content {
        display: flex;
        flex-direction: column;
        padding-top: 4px; }
        .contacts-col .top .email .content .email-link {
          font-size: 1.2rem;
          letter-spacing: 0.2em;
          font-weight: 800;
          color: #aaaac1;
          text-decoration: none;
          margin-bottom: 10px;
          transition: all 0.2s ease-in-out 0s; }
          .contacts-col .top .email .content .email-link:hover {
            color: #2fa5cb; }
          .contacts-col .top .email .content .email-link:active {
            color: #2a94b7; }

@media screen and (max-width: 680px) {
  .contacts-col .top .email .content .email-link {
    font-size: 1.8rem; } }
        .contacts-col .top .email .content .btn {
          height: 24px;
          line-height: 22px;
          padding: 0 20px;
          transition: all 0.2s ease-in-out 0s; }
          .contacts-col .top .email .content .btn:hover {
            color: #2fa5cb;
            border-color: #2fa5cb; }
          .contacts-col .top .email .content .btn:active {
            color: #2a94b7;
            border-color: #2a94b7; }

@media screen and (max-width: 900px) {
  .contacts-col .top .email .content .btn {
    height: auto;
    padding: 5px 10px; } }

@media screen and (max-width: 680px) {
  .contacts-col .top .email .content .btn {
    font-size: 1.8rem;
    line-height: normal;
    height: auto; } }
    .contacts-col .top .address {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 4px; }
      @media (max-width: 680px) {
        .contacts-col .top .address {
          margin-top: 2rem;
          padding-top: 0; } }
      .contacts-col .top .address .icon {
        width: 18px;
        height: 24px;
        background-image: url(../images/sprite.png);
        background-position: -64px -25px;
        width: 18px;
        height: 24px;
        margin-right: 20px; }

@media screen and (max-width: 1440px) {
  .contacts-col .top .address .icon {
    margin-right: 10px; } }
      .contacts-col .top .address address {
        font-size: 1.5rem;
        color: #474C66; }

@media screen and (max-width: 680px) {
  .contacts-col .top .address address {
    font-size: 1.8rem; } }
  .contacts-col .contacts-map {
    width: 100%;
    height: 520px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15); }

@media screen and (max-width: 768px) {
  .contacts-col .contacts-map {
    height: 420px; } }

@media screen and (max-width: 480px) {
  .contacts-col .contacts-map {
    height: 300px; } }

.img-col {
  width: calc(25% - 20px);
  min-height: 680px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  display: flex;
  background-image: url("../images/contacts-img.jpg");
  background-size: cover;
  background-repeat: no-repeat; }

@media screen and (max-width: 1250px) {
  .img-col {
    display: none; } }

.cookie-policy {
  padding: 41px 60px;
  display: none;
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border: 1px solid #aaaac1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1111; }
  @media (max-width: 1280px) {
    .cookie-policy {
      padding: 20px 30px; } }
  @media (max-width: 768px) {
    .cookie-policy {
      padding: 15px 0; } }
  .cookie-policy noindex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 768px) {
      .cookie-policy noindex {
        flex-direction: column;
        align-items: flex-start; } }
  .cookie-policy .cookies-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%; }
    @media (max-width: 768px) {
      .cookie-policy .cookies-desc {
        width: 100%; } }
  .cookie-policy .terms {
    padding-top: 25px; }
    @media (max-width: 1280px) {
      .cookie-policy .terms {
        padding-top: 15px; } }
    @media (max-width: 768px) {
      .cookie-policy .terms {
        padding-top: 5px; } }
    @media (max-width: 480px) {
      .cookie-policy .terms {
        padding: 3px 0; } }
  .cookie-policy p {
    color: #474C66;
    font-size: 1.4rem;
    font-family: "M PLUS 1p", sans-serif;
    padding-bottom: 8px; }
    .cookie-policy p a {
      text-transform: uppercase; }
    @media (max-width: 700px) {
      .cookie-policy p {
        display: inline-block; } }
    @media (max-width: 580px) {
      .cookie-policy p {
        font-size: 12px; } }
    @media (max-width: 480px) {
      .cookie-policy p {
        padding-bottom: 0; } }
  .cookie-policy a {
    font-family: "M PLUS 1p", sans-serif;
    color: #474C66;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: .2em;
    font-size: 1.2rem;
    text-transform: uppercase; }
    @media (max-width: 480px) {
      .cookie-policy a {
        font-size: 1.1rem; } }
    .cookie-policy a:hover {
      text-decoration: underline; }
  .cookie-policy .btn {
    background-color: #fff;
    display: flex;
    justify-self: flex-end;
    padding: 0 75px; }
    .cookie-policy .btn:hover {
      background-color: #aaaac1; }
    @media (max-width: 480px) {
      .cookie-policy .btn {
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: center; } }
  @media print {
    .cookie-policy .cookie-policy {
      display: none !important; } }

.field input, .field textarea {
  width: 100%;
  padding: 15px 21px; }
  .field input::placeholder, .field textarea::placeholder {
    color: #474C66;
    font-family: "M PLUS 1p", sans-serif;
    font-size: 1.5rem; }
  .field input:required, .field textarea:required {
    background-image: url(../images/required-icon.svg);
    background-position: calc(100% - 4px) 4px;
    background-repeat: no-repeat;
    background-size: 10px; }
  @media (max-width: 480px) {
    .field input, .field textarea {
      padding: 10px 15px; } }
  @media (max-width: 380px) {
    .field input, .field textarea {
      padding: 4px 15px;
      margin-top: 7px; } }

.field textarea {
  resize: none;
  height: 125px; }

.field select:required ~ .select__gap {
  background-image: url(../images/required-icon.svg);
  background-position: calc(100% - 4px) 4px;
  background-repeat: no-repeat;
  background-size: 10px; }

.modal, .contacts-form {
  width: 100%;
  max-width: 455px;
  position: relative;
  margin: 0 auto;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15); }
  @media (max-width: 480px) {
    .modal, .contacts-form {
      padding: 30px 15px; } }
  .modal__header, .contacts-form__header {
    position: relative;
    text-transform: uppercase;
    font-weight: 800;
    color: #474C66;
    letter-spacing: .2em;
    font-size: 1.2rem; }
    .modal__header:before, .contacts-form__header:before {
      content: '';
      position: absolute;
      bottom: -16px;
      left: 0;
      width: 65px;
      height: 1px;
      background: #141416; }
  .modal__form, .contacts-form__form {
    margin-top: 57px; }
    .modal__form input, .modal__form textarea, .contacts-form__form input, .contacts-form__form textarea {
      padding: 15px 21px;
      margin-top: 10px;
      width: 100%; }
      .modal__form input::placeholder, .modal__form textarea::placeholder, .contacts-form__form input::placeholder, .contacts-form__form textarea::placeholder {
        color: #474C66;
        font-family: "M PLUS 1p", sans-serif;
        font-size: 1.5rem; }
      @media (max-width: 480px) {
        .modal__form input, .modal__form textarea, .contacts-form__form input, .contacts-form__form textarea {
          padding: 10px 15px; } }
      @media (max-width: 380px) {
        .modal__form input, .modal__form textarea, .contacts-form__form input, .contacts-form__form textarea {
          padding: 4px 15px;
          margin-top: 7px; } }
    .modal__form input:first-child, .contacts-form__form input:first-child {
      margin-top: 0; }
  .modal__terms, .contacts-form__terms {
    position: relative;
    margin-top: 13px;
    display: flex; }
    .modal__terms .modal-label span, .contacts-form__terms .modal-label span {
      position: relative;
      padding-left: 35px;
      color: #4a4a79;
      font-size: 1.1rem;
      cursor: pointer;
      display: block; }
      @media (max-width: 768px) {
        .modal__terms .modal-label span, .contacts-form__terms .modal-label span {
          font-size: 1.2rem; } }
      @media (max-width: 380px) {
        .modal__terms .modal-label span, .contacts-form__terms .modal-label span {
          font-size: 1.1rem; } }
      .modal__terms .modal-label span:before, .contacts-form__terms .modal-label span:before {
        content: "";
        display: inline-block;
        height: 24px;
        width: 24px;
        top: -3px;
        left: 0;
        background-color: #b9bed0;
        border-radius: 50%; }
      .modal__terms .modal-label span:after, .contacts-form__terms .modal-label span:after {
        content: "";
        display: inline-block;
        height: 6px;
        width: 9px;
        border-left: 2px solid #f7f7ff;
        border-bottom: 2px solid #f7f7ff;
        left: 6px;
        top: 3px;
        transform: rotate(-45deg);
        /*@media (max-width: 450px) {
            top: calc(50% - 7px);
            left: 7px;
          }*/ }
      .modal__terms .modal-label span:before, .modal__terms .modal-label span:after, .contacts-form__terms .modal-label span:before, .contacts-form__terms .modal-label span:after {
        position: absolute; }
      .modal__terms .modal-label span a, .contacts-form__terms .modal-label span a {
        transition: all 0.2s ease-in-out 0s;
        display: inline;
        color: #4a4a79;
        font-size: 1.1rem;
        text-decoration: underline; }
    .modal__terms .modal-checkbox, .contacts-form__terms .modal-checkbox {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 22px;
      height: 22px; }
      .modal__terms .modal-checkbox + span:after, .contacts-form__terms .modal-checkbox + span:after {
        content: none; }
      .modal__terms .modal-checkbox:checked + span:after, .contacts-form__terms .modal-checkbox:checked + span:after {
        content: ''; }
  .modal .btn, .contacts-form .btn {
    all: unset;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin-top: 23px;
    padding: 5px 8px;
    text-align: center;
    /*@media (max-width: 480px) {
      padding: 0
    }*/ }
    .modal .btn span, .contacts-form .btn span {
      font-family: "M PLUS 1p", sans-serif;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: .2em; }
  .modal .mfp-close, .contacts-form .mfp-close {
    background-color: black;
    opacity: 1;
    font-family: "M PLUS 1p", sans-serif;
    font-size: 2.8rem;
    color: #fff !important;
    font-weight: 100;
    /* &:hover {
       background-color: white;
       border: 2px solid black;

       color: black!important;

       transition: all 0.3s ease-in;
     }*/ }
    .modal .mfp-close:active, .contacts-form .mfp-close:active {
      top: 0; }

.map-modal {
  min-height: 500px;
  width: 70%;
  margin: 0 auto; }

@media screen and (max-width: 1280px) {
  .map-modal {
    width: 90%;
    min-height: 60vh; } }

@media screen and (max-width: 768px) {
  .map-modal {
    width: 95%;
    min-height: 40vh; } }
  .map-modal .mfp-close {
    color: white !important;
    font-size: 3.6rem;
    right: 10%; }

@media screen and (max-width: 1280px) {
  .map-modal .mfp-close {
    right: 1%; } }

@media screen and (max-width: 768px) {
  .map-modal .mfp-close {
    right: 0;
    top: -15px; } }

.dadata-fieldset {
  position: relative; }

.dadata-list-container {
  position: absolute;
  transition: opacity 0.2s ease-in-out 0s, visibility 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #aaaac1;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  scrollbar-width: thin;
  /* "auto" or "thin" */
  scrollbar-color: #474C66 #E5E5EC;
  /* scroll thumb and track */ }
  .dadata-list-container::-webkit-scrollbar {
    width: 1px;
    /* width of the entire scrollbar */ }
  .dadata-list-container::-webkit-scrollbar-track {
    background: #E5E5EC;
    /* color of the tracking area */ }
  .dadata-list-container::-webkit-scrollbar-thumb {
    background-color: #474C66;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 1px solid #474C66;
    /* creates padding around scroll thumb */ }
  .dadata-list-container:not(.active) {
    opacity: 0;
    visibility: hidden; }

.dadata__item {
  padding: 15px 21px;
  font-size: 16px;
  transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s; }
  .dadata__item:not(.non-interactive) {
    cursor: pointer; }
    .dadata__item:not(.non-interactive):hover {
      background-color: #303239;
      color: #ffffff; }

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline !important; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #999 !important;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  table {
    border-collapse: collapse !important; }
    table td,
    table th {
      background-color: #fff !important; } }
