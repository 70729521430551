.dadata {

  &-fieldset {
    position: relative;
  }


  
  &-list-container {
    position: absolute;
    transition: opacity 0.2s ease-in-out 0s,
      visibility 0.2s ease-in-out 0s,
      height 0.2s ease-in-out 0s;
    width: 100%;
    background-color: $white-color;
    border: 1px solid $gray;
    max-height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 100;

    scrollbar-width: thin;
    /* "auto" or "thin" */
    scrollbar-color: $dark-gray $border-color;
    /* scroll thumb and track */

    &::-webkit-scrollbar {
      width: 1px;
      /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: $border-color;
      /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-gray;
      /* color of the scroll thumb */
      border-radius: 20px;
      /* roundness of the scroll thumb */
      border: 1px solid $dark-gray;
      /* creates padding around scroll thumb */
    }

    &:not(.active) {
      opacity: 0;
      visibility: hidden;

    }
  }

  &__list {}

  &__item {
    padding: 15px 21px;
    font-size: 16px;
    // line-height: ;

    transition: color 0.2s ease-in-out 0s,
      background-color 0.2s ease-in-out 0s;

    &:not(.non-interactive) {
      cursor: pointer;

      &:hover {
        background-color: $base-color;
        color: $white-color;
      }
    }
  }
}