// Семантические цвета

$base-color:                  #303239;
$purple:                      #212075;
$gray:                        #aaaac1;
$dark-gray:                   #474C66;
$delivery-bg:                 #F6F7FB;
$delivery-secondary:          #747495;
$delivery-btn-color:          #343745;
$border-color:                #E5E5EC;

$function-error-color:        #EB5757;
$function-success-color:      #27AE60;

$body-bg-color: #f7f8fc;

$link-color:                  #41afd3;
$link-color--hover:           darken($link-color, 5%);
$link-color--active:          darken($link-color, 10%);

$white-color:                 #ffffff;


// Базовая типографика

$font-size--root:             10px;

$font-size:                   16px; // rem(16px)
$font-size--small:            80%;

$font-family:                 'M PLUS 1p', sans-serif;
//$font-family--monospace:      SFMono-Regular, 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', monospace;

$font-weight--100:            100;
$font-weight--200:            100;
$font-weight--300:            300;
$font-weight--400:            400;
$font-weight--500:            500;
$font-weight--600:            600;
$font-weight--700:            700;
$font-weight--800:            800;
$font-weight--900:            900;

$font-weight:                 $font-weight--400;

$line-height:                 normal;


// Ширины

$wrapper: (
  'max-width': 1920px,
  'min-width': 280px,
  'padding': 0 20px
);
